<template>
	<v-select
		v-model="selectedLists"
		:items="items"
		:label="label"
		:multiple="multiple"
	>
		<template v-slot:prepend-item v-if="isSelectAll">
			<v-list-item ripple @click="toggle">
				<v-list-item-action>
					<v-icon>{{ icon }}</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>
						{{ $t('common.word.selectAll') }}
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-divider class="mt-2" />
		</template>
	</v-select>
</template>

<script>
export default {
	name: 'ASelect',
	props: {
		items: {
			type: Array,
			required: true
		},
		label: {
			type: String,
			default: ''
		},
		multiple: {
			type: Boolean,
			default: false
		},
		isSelectAll: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		selectedLists: []
	}),
	computed: {
		selectedAll() {
			return this.selectedLists.length === this.items.length
		},
		selected() {
			return this.selectedLists.length > 0 && !this.selectedAll
		},
		icon() {
			if (this.selectedAll) return 'mdi-close-box'
			if (this.selected) return 'mdi-minus-box'
			return 'mdi-checkbox-blank-outline'
		}
	},
	methods: {
		toggle() {
			this.$nextTick(() => {
				this.selectedLists = this.selectedAll ? [] : this.items.slice()
			})
		}
	}
}
</script>
