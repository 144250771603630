import api from '@/apis/config'
const { get, post, request } = api

// import log from '@/utils/log'
export const requestPhotoUpload = async (params, data) => {
	const response = await request(
		'POST',
		'/v1/01/10',
		{
			'Content-Type': 'multipart/form-data'
		},
		params,
		data,
		false
	)
	return response
}

/**
 * 공휴일 정보 조회
 * @param {string} userKey: 사용자키
 */
export const fetchHoliday = async params => {
	const { year, month } = { ...params }
	const response = await get(`v1/01/01/${year}/${month}`)
	return response
}

/**
 * 고객 인증정보 입력
 * 고객 인증정보를 입력하고, 고객에게 url 이 포함된 문자를 전송합니다.
 * @param {string} name: 이름
 * @param {string} phoneNumber: 전화번호
 */
export const sendLmsLinkToCustForVerification = async user => {
	const response = await post('v1/01/02', {
		...user
	})
	return response
}

/**
 * 고객 인증정보 조회
 * 사용자 키를 이용해 고객 인증정보를 조회합니다.
 * @param {string} userKey: 사용자키
 */
export const fetchCustVerifyInfoByUserKey = async userKey => {
	const response = await get(`v1/01/03/${userKey}`)
	return response
}

/**
 * 본인 인증 사전 작업
 * 본인인증 요청을 위한 암호화된  데이터 조회합니다.
 * @param {*} params
 */
export const fetchEncrypedKeyForCustVerification = async serviceNo => {
	const response = await get(`v1/01/04/${serviceNo}`)
	return response
}

export const fetchEncrypedKeyForCustVerification2 = async (srvNo, retUrl) => {
	const response = await post(`v1/01/04`, { srvNo, retUrl })
	return response
}

/**
 * 본인 인증 결과 정보 조회
 * 암호화 데이터를 보내서 복호화하여 본인인증 결과 정보를 조회합니다.
 * @param {string} retInfo: 암호화된 결과데이터
 */
export const fetchDecrypedPassVerifiedCust = async params => {
	const { retInfo: custVerifiedKey, orderId: id } = { ...params }
	let url = 'v1/01/05'
	if (custVerifiedKey) {
		url = `${url}/${custVerifiedKey}/${id}`
	}
	const response = await get(url, {
		...params
	})
	return response
}

/**
 * 고객정보 신규생성 및 업데이트
 * @param {*} params
 */
export const postCustCreateAndUpdate = async params => {
	const response = await post('v1/01/07', { ...params })
	return response
}

/**직접입력용
 * 기존고객 여부, 미납/연체 금액, SafeKey 발급가능 여부 확인, 경제능력 취약계층 여부, 개인사업자 정보
 * @param {*} params
 */
export const fetchCustCheckSelf = async params => {
	const response = await post('v1/01/06', { ...params })
	return response
}

/**
 * 코디 대행 인증 (카톡 발송) 알림톡
 * @param {*} params
 */
export const postCustAlimTalk = async params => {
	const response = await post('v1/01/08', { ...params })
	return response
}

/**
 * 기존고객 여부, 미납/연체 금액, SafeKey 발급가능 여부 확인, 경제능력 취약계층 여부, 개인사업자 정보
 * @param {*} params
 */
export const fetchCustCheck = async params => {
	const response = await post('v1/01/09', { ...params })
	return response
}

/**
 * PASS 인증전, 본인인증 정보 조회 - 고객 직접인증 PASS 72시간 체크
 * @param {*} params
 */
export const fetchCustPass72Check = async params => {
	const response = await post('v1/01/11', { ...params })
	return response
}

/**
 * 결제정보, 고객직접입력 추가정보 저장 및 푸시메시지 발송
 * @param {*} params
 */
export const custInfoSave = async (webOrderNo, params) => {
	const response = await post(`v1/01/12/${webOrderNo}`, { ...params })
	return response
}

/**
 * 주소정보, 고객직접입력 추가정보 조회
 * @param {*} webOrderNo
 */
export const fetchCustSetup = async webOrderNo => {
	const response = await get(`v1/01/13/${webOrderNo}`)
	return response
}

/**
 * 판매인 제한체크
 * @param {*} params
 * @description
 */
export const fetchPernrLimitCheck = async params => {
	const response = await post('v1/01/15', { ...params })
	return response
}

/**
 * 사업자 인증 화면 데이터 쿠키 생성
 * @param {*} params
 * @description
 */
export const postBizCreateCookie = async (gubun, params) => {
	const response = await post(`v1/01/16/${gubun}`, { ...params }, false, true)
	return response
}
