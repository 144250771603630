import { sleep } from '@/utils/sleep'
const state = {
	user: {
		accessToken: null,
		expiresAt: null,
		codyNo: null, // (코디등록번호)
		codyNoEnc: null, //
		codyPhoneNo: null, // (코디전화번호)
		deptCd: '', // 지국
		fromSales: false, // 영업정보시스템에서 접속,
		type: '', // 앱 타입(접근앱)
		app: '', // 앱 구분 코드
		tabCode: '' // 주문현황 탭 구분 값 C: 사전계약, else 일반
	},
	loading: false,
	snackbar: { status: false, msg: '', color: 'error' },
	// 가이드 화면 출력 내용
	guideData: {
		title: null,
		msg: null,
		start: null,
		end: null
	},
	// 팬매인 결제수단 제한 체크
	paymentLimit: '',
	// DS앱 헤더 출력 값
	dsHeaderData: {
		title: '',
		physicalBack: false
	}
}

const getters = {
	accessToken: state => state.user.accessToken,
	showSnackbar: state => state.snackbar,
	codyNo: state => state.user.codyNo || sessionStorage.getItem('codyNo'),
	codyPhoneNo: state =>
		state.user.codyPhoneNo || sessionStorage.getItem('codyPhoneNo'),
	deptCd: state => state.user.deptCd || sessionStorage.getItem('deptCd'),
	type: state => state.user.type || sessionStorage.getItem('type'),
	app: state => state.user.app || sessionStorage.getItem('app'),
	codyNoEnc: state =>
		state.user.codyNoEnc || sessionStorage.getItem('codyNoEnc'),
	getGuideData: state => state.guideData,
	dsHeaderData: state => state.dsHeaderData,
	getDsHeaderDataTitle: state => state.dsHeaderData.title,
	showPhysicalBack: state => state.dsHeaderData.physicalBack,
	tabCode: state => state.user.tabCode || sessionStorage.getItem('tabCode')
}

const actions = {
	async setToken({ commit }, value) {
		commit('setToken', value)
	},
	async showSnackbar({ commit }, params) {
		const payload = Object.assign({ color: 'error' }, params)
		commit('setSnackBar', payload)
		await sleep(2500)
		commit('closeSnackbar')
	},
	setUserInfo({ commit }, params) {
		commit('setUserInfo', params)
	},
	setGuideData({ commit }, params) {
		commit('setGuideData', params)
	},
	setPaymentLimit({ commit }, value) {
		commit('setPaymentLimit', value)
	},
	setDsHeaderData({ commit }, params) {
		commit('setDsHeaderData', params)
	}
}

const mutations = {
	setLoading(state, value) {
		state.loading = value
	},
	setToken(state, value) {
		state.user.accessToken = value
	},
	setSnackBar(state, payload) {
		const { msg, color } = payload
		state.snackbar.msg = msg
		state.snackbar.color = color
		state.snackbar.status = true
	},
	closeSnackbar(state) {
		state.snackbar.status = false
	},
	setUserInfo(state, payload) {
		if (payload.codyNo) {
			state.user.codyNo = payload.codyNo
			sessionStorage.setItem('codyNo', payload.codyNo)
		}

		if (payload.user_no) {
			state.user.codyNo = payload.user_no
			sessionStorage.setItem('codyNo', payload.user_no)
		}

		if (payload.codyPhoneNo) {
			state.user.codyPhoneNo = payload.codyPhoneNo
			sessionStorage.setItem('codyPhoneNo', payload.codyPhoneNo)
		}

		if (payload.deptCd) {
			state.user.deptCd = payload.deptCd
			sessionStorage.setItem('deptCd', payload.deptCd)
		}

		if (payload.type) {
			state.user.type = payload.type
			sessionStorage.setItem('type', payload.type)
		}

		if (payload.app) {
			state.user.app = payload.app
			sessionStorage.setItem('app', payload.app)
		}

		if (payload.codyNoEnc) {
			state.user.codyNoEnc = payload.codyNoEnc
			sessionStorage.setItem('codyNoEnc', payload.codyNoEnc)
		}

		if (payload.tabCode) {
			state.user.tabCode = payload.tabCode
			sessionStorage.setItem('tabCode', payload.tabCode)
		}
	},
	setGuideData(state, params) {
		if (params.title && params.title !== '') {
			state.guideData.title = params.title
		}
		if (params.msg && params.msg !== '') {
			state.guideData.msg = params.msg
		}
		if (params.start && params.start !== '') {
			state.guideData.start = params.start
		}
		if (params.end && params.end !== '') {
			state.guideData.end = params.end
		}
	},
	setPaymentLimit(state, value) {
		state.paymentLimit = value
	},
	setDsHeaderData(state, params) {
		if (params.title && params.title !== '') {
			state.dsHeaderData.title = params.title
		}
		if (params.physicalBack && params.physicalBack !== '') {
			state.dsHeaderData.physicalBack = params.physicalBack
		}
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
