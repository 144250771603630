<template>
	<div>
		<v-bottom-sheet v-model="seen" scrollable :persistent="persistent">
			<template v-slot:activator="{ attrs }" v-if="!hideButton">
				<v-btn
					class="btn_select_type"
					height="48px"
					depressed
					outlined
					@click="open"
					v-bind="attrs"
				>
					<slot name="default" />
				</v-btn>
			</template>
			<h2 class="tit">{{ title }}</h2>
			<div class="scr_area">
				<v-btn-toggle class="btn_bottom_sheet" v-model="selected">
					<v-btn v-for="(item, i) in items" :key="i" @click="onClick(i)">
						<template v-if="i === 0">
							<div class="infor_type_2">
								<h3 class="tal">{{ item.name }}</h3>
							</div>
						</template>
						<template v-else>
							<div class=" infor_type_2">
								<h3 class="tal mb-2">
									{{ item.name }}
									<span class="text_orange2">
										매월 +{{ item.amt | comma }}원
									</span>
								</h3>
								<span class="text_gray tal fll">{{ item.desc }} </span>
							</div>
						</template>
					</v-btn>
					<!-- <v-btn @click="onClick(2)">
						<div class=" infor_type_2">
							<h3 class="tal mb-2">
								1개월 (플러스서비스2)
								<span class="text_orange2">
									매월 +4,900원
								</span>
							</h3>
							<span class="text_gray tal fll"
								>하트서비스주기 1개월, 부품교환주기 2개월
							</span>
						</div>
					</v-btn> -->
				</v-btn-toggle>
			</div>
		</v-bottom-sheet>
	</div>
</template>

<script>
import filters from '@/mixins/filters'
export default {
	mixins: [filters],
	props: {
		value: [String, Number],
		text: String,
		title: String,
		persistent: Boolean,
		hideButton: Boolean,
		adsvcs: Array
	},
	computed: {
		displayText() {
			return this.text
		},
		selected: {
			get() {
				return this.items.findIndex(x => x.code === this.value)
			},
			set(value) {
				if (value !== undefined) {
					this.$emit('input', this.items[value].code)
				}
			}
		},
		mandatory() {
			return this.items.some(x => x.code === this.value)
		}
	},
	data() {
		return {
			seen: false,
			items: []
		}
	},
	methods: {
		open() {
			this.$log('adsvcs:', this.adsvcs)
			const svcs = this.adsvcs.map(svc => {
				return {
					code: svc.ADSVC,
					text: svc.ADSVC_TXT,
					desc: '',
					name: svc.ADSVC_TXT,
					amt: +svc.SVAMT
				}
			})
			this.items = [
				{ code: '', text: '선택없음', desc: '', name: '선택없음', amt: 0 },
				...svcs
			]
			this.seen = true
		},
		onClick(clickedValue) {
			this.close(clickedValue)
		},
		close(value) {
			if (value !== undefined) {
				const selectedIndex = value
				const selectedItem = this.items[selectedIndex]
				this.$emit('item-selected', selectedItem)
			}
			this.seen = false
		}
	}
}
</script>
