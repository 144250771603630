<template>
	<div>
		<v-bottom-sheet v-model="seen" scrollable :persistent="persistent">
			<template v-slot:activator="{ attrs }" v-if="!hideButton">
				<v-btn
					class="btn_select_type"
					height="48px"
					depressed
					outlined
					@click="open"
					v-bind="attrs"
					:disabled="disabled"
				>
					<slot name="default" />
				</v-btn>
			</template>
			<h2 class="tit">{{ title }}</h2>
			<div class="scr_area">
				<v-btn-toggle
					class="btn_bottom_sheet"
					v-model="selected"
					:mandatory="mandatory"
					@change="onChange"
				>
					<v-btn
						:ripple="false"
						v-for="(item, i) in items"
						:key="`item-${i}`"
						@click="onClick(i)"
					>
						{{ item.text }}
					</v-btn>
				</v-btn-toggle>
			</div>
		</v-bottom-sheet>
	</div>
</template>

<script>
export default {
	props: {
		value: [String, Number],
		items: {
			type: Array,
			default: () => {
				return []
			}
		},
		text: String,
		title: String,
		persistent: Boolean,
		hideButton: Boolean,
		disabled: { type: Boolean, default: false }
	},
	computed: {
		displayText() {
			return this.text
		},
		selected: {
			get() {
				return this.items.findIndex(x => x.code === this.value)
			},
			set(value) {
				if (value !== undefined) {
					this.$emit('input', this.items[value].code)
				}
			}
		},
		mandatory() {
			return this.items.some(x => x.code === this.value)
		}
	},
	data() {
		return {
			seen: false
		}
	},
	methods: {
		open() {
			this.seen = true
		},
		onChange(e) {
			const selectedItem = this.items[e]
			this.$emit('item-selected', selectedItem)
			this.seen = false
		},
		onClick(e) {
			if (e === this.selected) {
				//동일항목선택
				this.onChange(e)
			}
		}
	}
}
</script>
