import api from '@/apis/config'
const { post, get } = api

// import log from '@/utils/log'
/**
 * 기존 설치처 목록
 * @param {*} params
 */
export const fetchAddrList = async params => {
	const response = await post('/v1/05/01', {
		...params
	})
	return response
}

/**
 * 최근 설치메모 목록
 * @param {*} params
 */
export const fetchMemoList = async params => {
	const response = await post('/v1/05/03', {
		...params
	})
	return response
}

/**
 * 매트리스 멤버십 목록 조회
 * @param {*} params
 */
export const fetchMembershipList = async params => {
	const response = await post('/v1/05/05', {
		...params
	})
	return response
}

/**
 * 주문접수
 * @param {*} params
 */
export const postOrder = async params => {
	const response = await post('/v1/05/06', {
		...params
	})
	return response
}

/**
 * 영업일 조회
 * @param {String} daysLater: 몇일 후 시작
 * @param {String} fewDays: 몇일 동안
 */
export const fetchBusinessDays = async (daysLater, fewDays, isSaturDay) => {
	const response = await get(`v1/05/07/${daysLater}/${fewDays}/${isSaturDay}`)
	return response
}

/**
 * 가상계좌 상태 조회
 * @param {*} params
 */
export const postVirtualAccount = async params => {
	const response = await post('/v1/05/08', {
		...params
	})
	return response
}

/**
 * 정제주소 조회
 * @param {*} params
 */
export const postAddressChk = async params => {
	const response = await post('/v1/05/09', {
		...params
	})
	return response
}

/**
 * 폐기대상 매트리스 조회
 * @param {*} params
 */
export const disusePrd = async (webOrderNo, params) => {
	const response = await post(`/v1/05/10/${webOrderNo}`, {
		...params
	})
	return response
}

/**
 * 기존멤버십 해지리스트 조회
 * @param {*} params
 */
export const preMem = async (webOrderNo, params) => {
	const response = await post(`/v1/05/11/${webOrderNo}`, {
		...params
	})
	return response
}

/**
 * 기존주문 사전예약 상태 조회
 * @param {*} params
 */
export const preOrderSubStatus = async preOrderNo => {
	const response = await get(`v1/05/12/${preOrderNo}`)
	return response
}

/**
 * 우편일련번호 기준 지역별 특이사항 문구 조회
 * @param {Number} zipSeq: I_ZIP_SEQ 우편일련번호 숫자 8자리
 */
export const fetchRegionalSpcText = async zipSeq => {
	const response = await get(`v1/05/13/${zipSeq}`)
	return response
}

/**
 * 물마크/CA인증필증 중복 검사 조회
 * @param {String} gubun: Y: 물마크, C: 인증필증
 * @param {String} otherRtnNo: 인증필증/물마크 번호
 */
export const fetchCheckOtherRtnNum = async params => {
	const response = await post('/v1/05/14', {
		...params
	})
	return response
}

/**
 * 추천판매인 검색
 * @param {String} I_SALES_MOB: 판매인 휴대전화번호
 */
export const fetchRecommendSalesList = async params => {
	const response = await post('/v1/05/15', {
		...params
	})
	return response
}

/**
 * 추천 판매인 가져오기
 * @param {String} reservmblno: 고객 휴대전화번호
 */
export const getRecommendSalesInfo = async params => {
	const response = await post('/v1/05/16', {
		...params
	})
	return response
}

/**
 * 추천 판매인 가져오기
 * @param {*} params
 */
export const getOrderChkSmart = async params => {
	const response = await post('/v1/05/17', {
		...params
	})
	return response
}
