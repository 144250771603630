/**
 * 타사보상 정수기, 공기청정기 데이터
 * INDEDX는 I + SCD_GRP_CD
 * ex > I6100
 * @description 타사보상 제품에 대한 정보 선택 화면에 출력할 데이터
 *              SAP에서 제공한 데이터를 기준으로 생성
 */
export default {
	// 이동, 추가 서비스 그룹
	I9910: [
		{
			code: '',
			text: '이용하지 않기'
		},
		{
			code: '001',
			text: '매트리스'
		},
		{
			code: '002',
			text: '프레임/파운데이션'
		},
		{
			code: '003',
			text: '안마의자/베드'
		},
		{
			code: '004',
			text: '리클라이너'
		},
		{
			code: '005',
			text: '소파'
		},
		{
			code: '006',
			text: '서랍장'
		},
		{
			code: '007',
			text: '기타'
		}
	],
	// 이동, 추가 서비스 상세
	I9900: [
		{
			code: '1101',
			text: '일반 매트리스',
			upperCode: '001'
		},
		{
			code: '1102',
			text: '탑퍼',
			upperCode: '001'
		},
		{
			code: '1103',
			text: '스마트 매트리스',
			upperCode: '001'
		},
		{
			code: '1104',
			text: '원바디 매트리스',
			upperCode: '001'
		},
		{
			code: '1105',
			text: '파운데이션',
			upperCode: '002'
		},
		{
			code: '1106',
			text: '투매트리스 프레임',
			upperCode: '002'
		},
		{
			code: '1107',
			text: '일반 프레임',
			upperCode: '002'
		},
		{
			code: '1108',
			text: '모션 프레임',
			upperCode: '002'
		},
		{
			code: '1109',
			text: '특수 프레임(원목/철재/벙커/2층)',
			upperCode: '002'
		},
		// {
		// 	code: '1110',
		// 	text: '자사헤드체결(파운데이션)'
		// },
		{
			code: '1201',
			text: '안마의자',
			upperCode: '003'
		},
		{
			code: '1204',
			text: '마인',
			upperCode: '003'
		},
		{
			code: '1205',
			text: '안마베드',
			upperCode: '003'
		},
		{
			code: '1206',
			text: '리클라이너(1인용)',
			upperCode: '004'
		},
		{
			code: '1207',
			text: '리클라이너(2인용)',
			upperCode: '004'
		},
		{
			code: '1208',
			text: '리클라이너(3~4인용)',
			upperCode: '004'
		},
		{
			code: '1301',
			text: '소파(1인용)',
			upperCode: '005'
		},
		{
			code: '1302',
			text: '소파(2인용)',
			upperCode: '005'
		},
		{
			code: '1303',
			text: '소파(3~4인용)',
			upperCode: '005'
		},
		{
			code: '1304',
			text: '서랍장(1~2단)',
			upperCode: '006'
		},
		{
			code: '1305',
			text: '서랍장(3~4단)',
			upperCode: '006'
		},
		{
			code: '1306',
			text: '서랍장(5단)',
			upperCode: '006'
		},
		{
			code: '1202',
			text: '의류청정기',
			upperCode: '007'
		},
		{
			code: '1203',
			text: '반신욕기',
			upperCode: '007'
		},
		{
			code: '1307',
			text: '거실장/책상',
			upperCode: '007'
		}
	],
	// 타사정보_정수기_제조사
	I6100: [
		{
			code: '1',
			text: 'SK',
			CHG_YMD: '2021-08-03',
			CD: '1',
			CD_GRP_CD: '6100',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_정수기_제조사',
			CD_NM: 'SK',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:25:23',
			REG_TM: '21:25:23'
		},
		{
			code: '2',
			text: 'LG',
			CHG_YMD: '2021-08-03',
			CD: '2',
			CD_GRP_CD: '6100',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_정수기_제조사',
			CD_NM: 'LG',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:25:37',
			REG_TM: '21:25:37'
		},
		{
			code: '3',
			text: '쿠쿠',
			CD: '3',
			CD_GRP_CD: '6100',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_정수기_제조사',
			CD_NM: '쿠쿠',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:25:51',
			REG_TM: '21:25:51'
		},
		{
			code: '4',
			text: '청호',
			CHG_YMD: '2021-08-03',
			CD: '4',
			CD_GRP_CD: '6100',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_정수기_제조사',
			CD_NM: '청호',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:26:02',
			REG_TM: '21:26:02'
		},
		{
			code: '5',
			text: '교원',
			CHG_YMD: '2021-08-03',
			CD: '5',
			CD_GRP_CD: '6100',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_정수기_제조사',
			CD_NM: '교원',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:26:17',
			REG_TM: '21:26:17'
		},
		{
			code: '6',
			text: '기타',
			CHG_YMD: '2021-08-03',
			CD: '6',
			CD_GRP_CD: '6100',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_정수기_제조사',
			CD_NM: '기타',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:26:29',
			REG_TM: '21:26:29'
		}
	],
	// 타사정보_정수기_제품형태
	I6102: [
		{
			code: '1',
			text: '데스크탑',
			CHG_YMD: '2021-08-03',
			CD: '1',
			CD_GRP_CD: '6102',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_정수기_제품형태',
			CD_NM: '데스크탑',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:28:35',
			REG_TM: '21:28:35'
		},
		{
			code: '2',
			text: '스탠드',
			CHG_YMD: '2021-08-03',
			CD: '2',
			CD_GRP_CD: '6102',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_정수기_제품형태',
			CD_NM: '스탠드',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:28:49',
			REG_TM: '21:28:49'
		},
		{
			code: '3',
			text: '빌트인',
			CHG_YMD: '2021-08-03',
			CD: '3',
			CD_GRP_CD: '6102',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_정수기_제품형태',
			CD_NM: '빌트인',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:29:02',
			REG_TM: '21:29:02'
		},
		{
			code: '4',
			text: '이온수기',
			CHG_YMD: '2021-08-03',
			CD: '4',
			CD_GRP_CD: '6102',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_정수기_제품형태',
			CD_NM: '이온수기',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:29:15',
			REG_TM: '21:29:15'
		}
	],
	// 타사정보_정수기_제품종류
	I6103: [
		{
			code: '1',
			text: '얼음정수기',
			CHG_YMD: '2021-08-03',
			CD: '1',
			CD_GRP_CD: '6103',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_정수기_제품종류',
			CD_NM: '얼음정수기',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:30:55',
			REG_TM: '21:30:55'
		},
		{
			code: '2',
			text: '온수/냉수/정수',
			CHG_YMD: '2021-08-03',
			CD: '2',
			CD_GRP_CD: '6103',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_정수기_제품종류',
			CD_NM: '온수/냉수/정수',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:31:11',
			REG_TM: '21:31:11'
		},
		{
			code: '3',
			text: '냉수/정수',
			CHG_YMD: '2021-08-03',
			CD: '3',
			CD_GRP_CD: '6103',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_정수기_제품종류',
			CD_NM: '냉수/정수',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:31:26',
			REG_TM: '21:31:26'
		},
		{
			code: '4',
			text: '정수',
			CHG_YMD: '2021-08-03',
			CD: '4',
			CD_GRP_CD: '6103',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_정수기_제품종류',
			CD_NM: '정수',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:31:36',
			REG_TM: '21:31:36'
		}
	],
	// 타사정보_정수기_설치형태
	I6104: [
		{
			code: '1',
			text: '직수형',
			CHG_YMD: '2021-08-03',
			CD: '1',
			CD_GRP_CD: '6104',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_정수기_설치형태',
			CD_NM: '직수형',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:33:15',
			REG_TM: '21:33:15'
		},
		{
			code: '2',
			text: '수조형',
			CHG_YMD: '2021-08-03',
			CD: '2',
			CD_GRP_CD: '6104',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_정수기_설치형태',
			CD_NM: '수조형',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:33:25',
			REG_TM: '21:33:25'
		}
	],
	// 타사정보_청정기_제조사
	I6201: [
		{
			code: '1',
			text: '삼성',
			CHG_YMD: '2021-08-03',
			CD: '1',
			CD_GRP_CD: '6201',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_청정기_제조사',
			CD_NM: '삼성',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:35:03',
			REG_TM: '21:35:03'
		},
		{
			code: '2',
			text: 'LG',
			CHG_YMD: '2021-08-03',
			CD: '2',
			CD_GRP_CD: '6201',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_청정기_제조사',
			CD_NM: 'LG',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:35:13',
			REG_TM: '21:35:13'
		},
		{
			code: '3',
			text: '위닉스',
			CHG_YMD: '2024-01-16',
			CD: '3',
			CD_GRP_CD: '6201',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_청정기_제조사',
			CD_NM: '위닉스',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2024-01-16',
			CHGER_NO: '90000227',
			CHG_TM: '21:35:13',
			REG_TM: '21:35:13'
		}
	],
	// 타사정보_청정기_제품형태
	I6202: [
		{
			code: '1',
			text: '10평형',
			CHG_YMD: '2021-08-03',
			CD: '1',
			CD_GRP_CD: '6202',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_청정기_제품형태',
			CD_NM: '10평형',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:38:00',
			REG_TM: '21:38:00'
		},
		{
			code: '2',
			text: '20평형',
			CHG_YMD: '2021-08-03',
			CD: '2',
			CD_GRP_CD: '6202',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_청정기_제품형태',
			CD_NM: '20평형',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:38:14',
			REG_TM: '21:38:14'
		},
		{
			code: '3',
			text: '30평형',
			CHG_YMD: '2021-08-03',
			CD: '3',
			CD_GRP_CD: '6202',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_청정기_제품형태',
			CD_NM: '30평형',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:38:35',
			REG_TM: '21:38:35'
		}
	],
	// 타사정보_청정기_제품종류
	I6203: [
		{
			code: '1',
			text: '일반',
			CHG_YMD: '2021-08-03',
			CD: '1',
			CD_GRP_CD: '6203',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_청정기_제품종류',
			CD_NM: '일반',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:40:20',
			REG_TM: '21:40:20'
		},
		{
			code: '2',
			text: '가습',
			CHG_YMD: '2021-08-03',
			CD: '2',
			CD_GRP_CD: '6203',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_청정기_제품종류',
			CD_NM: '가습',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:40:30',
			REG_TM: '21:40:30'
		},
		{
			code: '3',
			text: '제습',
			CHG_YMD: '2021-08-03',
			CD: '2',
			CD_GRP_CD: '6203',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_청정기_제품종류',
			CD_NM: '제습',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:40:30',
			REG_TM: '21:40:30'
		}
	],
	// 타사정보_청정기_설치형태
	I6204: [
		{
			code: '1',
			text: '스탠드',
			CHG_YMD: '2021-08-03',
			CD: '1',
			CD_GRP_CD: '6204',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_청정기_설치형태',
			CD_NM: '스탠드',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:40:20',
			REG_TM: '21:40:20'
		},
		{
			code: '2',
			text: '벽걸이',
			CHG_YMD: '2021-08-03',
			CD: '2',
			CD_GRP_CD: '6204',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_청정기_설치형태',
			CD_NM: '벽걸이',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:40:30',
			REG_TM: '21:40:30'
		}
	],
	// 타사정보_비데_제조사
	I6301: [
		{
			code: '1',
			text: '노비타',
			CHG_YMD: '2021-08-03',
			CD: '1',
			CD_GRP_CD: '6301',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_청정기_제조사',
			CD_NM: '노비타',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:35:03',
			REG_TM: '21:35:03'
		},
		{
			code: '2',
			text: 'SK',
			CHG_YMD: '2021-08-03',
			CD: '2',
			CD_GRP_CD: '6301',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_청정기_제조사',
			CD_NM: 'SK',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:35:13',
			REG_TM: '21:35:13'
		},
		{
			code: '3',
			text: '블루밍',
			CHG_YMD: '2021-08-03',
			CD: '3',
			CD_GRP_CD: '6301',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_청정기_제조사',
			CD_NM: '블루밍',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:35:13',
			REG_TM: '21:35:13'
		},
		{
			code: '4',
			text: '대림',
			CHG_YMD: '2021-08-03',
			CD: '4',
			CD_GRP_CD: '6301',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_청정기_제조사',
			CD_NM: '대림',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:35:13',
			REG_TM: '21:35:13'
		},
		{
			code: '6',
			text: '더이누스',
			CHG_YMD: '2021-08-03',
			CD: '6',
			CD_GRP_CD: '6301',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_청정기_제조사',
			CD_NM: '더이누스',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:35:13',
			REG_TM: '21:35:13'
		}
	],
	// 타사정보_비데_제품종류
	I6302: [
		{
			code: '2',
			text: '온수/온변좌형',
			CHG_YMD: '2021-08-03',
			CD: '2',
			CD_GRP_CD: '6302',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_비데_제품종류',
			CD_NM: '온수/온변좌형',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:40:30',
			REG_TM: '21:40:30'
		},
		{
			code: '3',
			text: '도기일체형',
			CHG_YMD: '2021-08-03',
			CD: '3',
			CD_GRP_CD: '6302',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_비데_제품종류',
			CD_NM: '도기일체형',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:40:20',
			REG_TM: '21:40:20'
		}
	],
	I6303: [
		{
			code: '1',
			text: '세라젬',
			CHG_YMD: '2021-08-03',
			CD: '1',
			CD_GRP_CD: '6303',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_안마의자_제조사',
			CD_NM: '세라젬',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:25:23',
			REG_TM: '21:25:23'
		},
		{
			code: '2',
			text: '코지마',
			CHG_YMD: '2021-08-03',
			CD: '2',
			CD_GRP_CD: '6303',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_안마의자_제조사',
			CD_NM: '코지마',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:25:37',
			REG_TM: '21:25:37'
		},
		{
			code: '3',
			text: '휴테크',
			CHG_YMD: '2021-08-03',
			CD: '3',
			CD_GRP_CD: '6303',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_안마의자_제조사',
			CD_NM: '휴테크',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:25:37',
			REG_TM: '21:25:37'
		},
		{
			code: '4',
			text: '바디프렌드',
			CHG_YMD: '2021-08-03',
			CD: '4',
			CD_GRP_CD: '6303',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_안마의자_제조사',
			CD_NM: '바디프렌드',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:25:37',
			REG_TM: '21:25:37'
		}
	],
	// 타사정보_안마의자_제품종류
	I6304: [
		{
			code: '1',
			text: '체어형',
			CHG_YMD: '2021-08-03',
			CD: '1',
			CD_GRP_CD: '6304',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_안마의자_제품종류',
			CD_NM: '체어형',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:40:20',
			REG_TM: '21:40:20'
		},
		{
			code: '2',
			text: '베드형',
			CHG_YMD: '2021-08-03',
			CD: '2',
			CD_GRP_CD: '6304',
			CD_DESC: '',
			CD_GRP_NM: '타사정보_안마의자_제품종류',
			CD_NM: '베드형',
			OTH_CD_NM: '',
			USE_YN: 'Y',
			OTH_CD_CHAR2: '',
			REGER_NO: '90000227',
			REG_YMD: '2021-08-03',
			CHGER_NO: '90000227',
			CHG_TM: '21:40:30',
			REG_TM: '21:40:30'
		}
	],
	// 타사정보_매트리스_제조사
	I6401: [
		{
			code: '1',
			text: '에이스'
		},
		{
			code: '2',
			text: '시몬스'
		},
		{
			code: '3',
			text: '이케아'
		},
		{
			code: '4',
			text: '한샘'
		},
		{
			code: '5',
			text: '리바트'
		},
		{
			code: '6',
			text: '까사미아'
		},
		{
			code: '7',
			text: '일룸'
		},
		{
			code: '8',
			text: '기타'
		}
	]
}
