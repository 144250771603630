<template>
	<v-menu
		v-model="menu"
		ref="menu"
		min-width="100px"
		transition="scale-transition"
		:close-on-content-click="contentClose"
		offset-y
	>
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				v-model="currentDate"
				prepend-icon="mdi-calendar"
				readonly
				v-on="on"
				v-bind="attrs"
			/>
		</template>
		<v-date-picker
			v-model="currentDate"
			:day-format="date => new Date(date).getDate()"
			:min="min"
			:max="max"
			@click="$refs.menu.save(currentDate)"
			no-title
			scrollable
			:allowed-dates="allowedDates"
		/>
	</v-menu>
</template>

<script>
export default {
	name: 'ADatePicker',
	props: {
		value: {
			type: [String, Date]
		},
		contentClose: {
			type: Boolean,
			default: true
		},
		min: String,
		max: String,
		businessDays: Array
	},
	data() {
		return {
			currentDate: this.date,
			menu: false
		}
	},
	watch: {
		currentDate(date) {
			this.$emit('date', date)
		}
	},
	created() {
		this.currentDate = this.value
	},
	methods: {
		allowedDates(val) {
			return this.businessDays.includes(val)
		}
	}
}
</script>
