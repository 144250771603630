<template>
	<div>
		<v-toolbar v-if="visible" flat dense short max-height="48px">
			<!-- <v-btn icon @click="goBack()">
				<v-icon v-if="type !== 'main'">mdi-arrow-left</v-icon>
			</v-btn> -->
			<v-spacer />
			<v-toolbar-title class="text-center">{{ title }}</v-toolbar-title>
			<v-spacer />
			<!-- <v-app-bar-nav-icon
				v-if="type !== 'popup'"
				@click.stop="drawer = !drawer"
			/> -->
		</v-toolbar>
		<!-- <v-navigation-drawer v-model="drawer" absolute right temporary>
			<v-list nav dense>
				<v-list-item-group
					v-model="group"
					active-class="deep-purple--text text--accent-4"
				>
					<v-list-item v-for="(link, i) in links" :key="i" :href="link.url">
						<v-list-item-title>{{ link.text }}</v-list-item-title>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer> -->
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	data() {
		return {
			type: '',
			visible: false,
			drawer: false,
			group: null,
			links: [{ text: '결제테스트', url: '/order/pay' }]
		}
	},
	computed: {
		...mapState(['route']),
		title() {
			return this.$t(`title.${this.route.name}`)
		}
	},
	watch: {
		group() {
			this.drawer = false
		},
		route(value) {
			this.visible = !value.meta.hideAppBar
			this.type = value.meta.type
		}
	},
	methods: {
		goBack() {
			this.$router.go(-1)
		}
	}
}
</script>
