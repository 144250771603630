<template>
	<div>
		<!-- 1뎁스 바텀 시트 -->
		<v-bottom-sheet scrollable v-model="seen" :persistent="persistent">
			<template v-slot:activator="{ attrs }">
				<v-btn
					outlined
					depressed
					@click="open"
					v-bind="attrs"
					class="btn_select_type"
					:disabled="disabled"
				>
					<slot name="default" />
				</v-btn>
			</template>
			<h2 class="tit">{{ title }} 선택</h2>
			<div class="scr_area">
				<v-btn-toggle class="toggle_row expand" v-model="upperToggle">
					<div class="flex_row" v-for="(item, i) in items" :key="item + i">
						<v-btn
							:ripple="false"
							class="btn_white mb-2"
							@click="onClick(item)"
						>
							{{ item.text }}
						</v-btn>
					</div>
				</v-btn-toggle>

				<!-- 2뎁스 세부 선택 바텀 시트 -->
				<v-bottom-sheet scrollable v-model="seen2" :persistent="persistent">
					<h2 class="tit">{{ detailTitle }}</h2>
					<div class="scr_area">
						<v-btn-toggle class="toggle_row expand" v-model="detailToggle">
							<div
								class="flex_row"
								v-for="(detail, i) in detailItems"
								:key="detail + i"
							>
								<v-btn
									:ripple="false"
									class="btn_white mb-2"
									@click="onClick2(detail)"
								>
									{{ detail.text }}
								</v-btn>
							</div>
						</v-btn-toggle>
					</div>
				</v-bottom-sheet>
				<!-- //2뎁스 세부 선택 바텀 시트 -->
			</div>
		</v-bottom-sheet>
	</div>
</template>

<script>
import { getIndemnifyData } from '@/utils/common'
export default {
	props: {
		items: {
			type: Array,
			default: () => {
				return []
			}
		},
		title: String,
		persistent: Boolean,
		detailItemCode: [String, Number],
		disabled: { type: Boolean, default: false },
		selectedItem: {
			type: Object,
			default: () => {
				return {}
			}
		}
	},
	data() {
		return {
			seen: false,
			seen2: false,
			detailItems: [],
			detailTitle: '',
			upperToggle: undefined,
			detailToggle: undefined
		}
	},
	methods: {
		getIndemnifyData,
		open() {
			this.getDepthToggleModel(1)
			this.detailItems = []
			this.seen = true
		},
		onClick(item) {
			if (this.detailItemCode) {
				this.detailItems = this.getIndemnifyData(this.detailItemCode).filter(
					v => v.upperCode === item.code
				)

				this.getDepthToggleModel(2)
			}
			this.detailTitle = ''.concat(item.text, ' 세부 선택')
			this.seen = false
			if (item && item.code !== '') {
				this.seen2 = true
			} else {
				this.$emit('item-selected', item)
			}
		},
		onClick2(detail) {
			this.$emit('item-selected', detail)
			this.seen2 = false
		},
		getDepthToggleModel(depth) {
			if (depth === 1) {
				const checkCode =
					this.selectedItem.code === ''
						? this.selectedItem.code
						: this.selectedItem.upperCode
				this.upperToggle = this.items.findIndex(v => v.code === checkCode)
			} else if (depth === 2) {
				if (this.selectedItem.code !== '') {
					this.detailToggle = this.detailItems.findIndex(
						v => v.code === this.selectedItem.code
					)
				}
			}
		}
	}
}
</script>
