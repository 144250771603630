export default [
	{
		path: 'ec',
		name: 'order-ec',
		meta: {
			isPublic: true,
			type: 'main',
			title: '전자계약서 발송'
		},
		component: () => import('@/views/ec/EcPage.vue')
	},
	{
		path: 'ec/auth',
		name: 'ec-auth',
		meta: {
			isPublic: true,
			type: 'main'
		},
		component: () => import('@/views/ec/EcAuth.vue')
	},
	{
		path: 'ec/confirm',
		name: 'ec-confirm',
		meta: {
			isPublic: true,
			type: 'main'
		},
		component: () => import('@/views/ec/EcConfirm.vue')
	},
	{
		path: 'ec/signature',
		name: 'ec-signature',
		meta: {
			isPublic: true,
			type: 'main'
		},
		component: () => import('@/views/ec/EcSignature.vue')
	},
	{
		path: 'ec/cust/signature',
		name: 'ec-cust-signature',
		meta: {
			isPublic: true,
			type: 'main',
			hideAppBar: true,
			showWebHeader: true
		},
		component: () => import('@/views/ec/cust/CustEcSignature.vue')
	},
	{
		path: 'ec/cust/pass',
		name: 'ec-cust-pass',
		meta: {
			isPublic: true,
			type: 'main',
			hideAppBar: true,
			showWebHeader: true
		},
		component: () => import('@/views/ec/cust/CustEcPass.vue')
	},
	{
		path: 'ec/cust/confirm',
		name: 'ec-cust-confirm',
		meta: {
			isPublic: true,
			type: 'main',
			hideAppBar: true,
			showWebHeader: true
		},
		component: () => import('@/views/ec/cust/CustEcConfirm.vue')
	},
	{
		path: 'ec/cust/complete',
		name: 'ec-cust-complete',
		meta: {
			isPublic: true,
			type: 'main',
			hideAppBar: true,
			showWebHeader: true
		},
		component: () => import('@/views/ec/cust/CustEcComplete.vue')
	},
	{
		path: 'ec/cust/complete/homepage',
		name: 'ec-cust-complete-homepage',
		meta: {
			isPublic: true,
			type: 'main',
			title: '코웨이닷컴',
			hideAppBar: true,
			showWebHeader: true
		},
		component: () => import('@/views/ec/cust/CustEcCompleteHomePage.vue')
	},
	{
		path: 'ec/cust/complete/homepage2',
		name: 'ec-cust-complete-homepage2',
		meta: {
			isPublic: true,
			type: 'main',
			hideAppBar: true,
			showWebHeader: true
		},
		component: () => import('@/views/ec/cust/CustEcCompleteHomePage2.vue')
	},
	{
		path: 'ec/cust/uncomplete',
		name: 'ec-cust-uncomplete',
		meta: {
			isPublic: true,
			type: 'main',
			hideAppBar: true,
			showWebHeader: true
		},
		component: () => import('@/views/ec/cust/CustEcUnComplete.vue')
	},
	{
		path: 'ec/cust/expired',
		name: 'ec-cust-expired',
		meta: {
			isPublic: true,
			type: 'main',
			hideAppBar: true,
			showWebHeader: true
		},
		component: () => import('@/views/ec/cust/CustEcExpired.vue')
	},
	{
		path: 'ec/cust/signaturecancel',
		name: 'ec-cust-signaturecancel',
		meta: {
			isPublic: true,
			type: 'main',
			hideAppBar: true,
			showWebHeader: true
		},
		component: () => import('@/views/ec/cust/CustEcSignatureCancel.vue')
	},
	{
		path: 'ec/cust',
		name: 'ec-cust',
		meta: {
			isPublic: true,
			type: 'main'
		},
		component: () => import('@/views/ec/cust/CustEcPage.vue')
	}
]
