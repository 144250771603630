<template>
	<div>
		<v-bottom-sheet
			scrollable
			v-model="seen"
			:persistent="persistent"
			:content-class="'promotion'"
		>
			<template v-slot:activator="{ attrs }" v-if="!hideButton">
				<v-btn
					outlined
					depressed
					@click="open"
					v-bind="attrs"
					:disabled="disabled"
					class="btn_select_type"
				>
					<slot name="default" />
				</v-btn>
			</template>

			<h2 class="tit">{{ title }}</h2>

			<div class="u-select-order">
				<v-btn-toggle
					class="toggle_row"
					v-model="selected"
					:mandatory="mandatory"
				>
					<v-btn
						class="btn_field"
						v-for="(item, i) in items"
						:key="i"
						@click="onClick(i)"
						:ripple="false"
						:disabled="
							preChk(i) ||
								(disableMemList.length > 0 ? disableMemList[i].disable : false)
						"
					>
						<div class="box">
							<article class="desc">
								<div class="info">
									<dl>
										<dt>제품주문번호</dt>
										<dd>{{ item.ORDER_NO }}</dd>
									</dl>
									<dl>
										<dt>가입일자</dt>
										<dd>{{ item.MS_DT }}</dd>
									</dl>
									<dl>
										<dt>렌탈</dt>
										<dd>{{ item.MAKTX }}</dd>
									</dl>
								</div>
							</article>
							<v-checkbox></v-checkbox>
						</div>
					</v-btn>
				</v-btn-toggle>
			</div>
		</v-bottom-sheet>
	</div>
</template>

<script>
export default {
	props: {
		value: [String, Number],
		preSet: { type: Number, default: -1 },
		items: {
			type: Array,
			default: () => {
				return []
			}
		},
		preMemList: Array,
		text: String,
		title: String,
		persistent: Boolean,
		hideButton: Boolean,
		disabled: { type: Boolean, default: false },
		returnOtherPromotionCode: { type: Boolean, default: false },
		otherPreMem: { type: Object, default: undefined }
	},
	computed: {
		displayText() {
			return this.text
		},
		selected: {
			get() {
				return this.value
			},
			set(value) {
				if (value !== undefined) {
					this.$emit('input', value)
				}
			}
		},
		mandatory() {
			return this.preSet === -1 ? false : true
		}
	},
	data() {
		return {
			seen: false,
			orderNumberChecked: 0,
			disableMemList: []
		}
	},
	methods: {
		open() {
			this.seen = true

			this.disableMemList = []
			this.items.forEach((x, i) => {
				let tmpDisable = false
				if (this.returnOtherPromotionCode) {
					tmpDisable =
						x.STYPE === 'Y' && parseInt(x.USE_MONTH || 0) > 6 ? false : true
				}

				this.disableMemList.push({ ...x, disable: tmpDisable })

				if (this.preMemList.length !== 0) {
					this.preMemList.forEach(y => {
						if (x.ORDER_NO === y.ORDER_NO) {
							this.disableMemList[i].disable = true
						}
					})
				}

				if (this.otherPreMem) {
					if (x.ORDER_NO !== this.otherPreMem.ORDER_NO) {
						this.disableMemList[i].disable = true
					}
				}
			})
		},
		onClick(idx) {
			this.$emit('item-selected', idx)
			this.seen = false
		},
		preChk(v) {
			if (this.preSet !== -1) {
				if (v === this.preSet) {
					return false
				} else {
					return true
				}
			} else {
				return false
			}
		}
	}
}
</script>
