<template>
	<v-dialog v-model="dialog" fullscreen :persistent="true">
		<div class="full_dialog_header">
			<h2 class="tit" v-text="titleText" />
			<v-btn depressed icon plain class="btn_right" @click="close"
				><v-icon dark>
					mdi-window-close
				</v-icon></v-btn
			>
		</div>

		<div class="dialog_all_page pd-00">
			<PayMethodTab
				v-if="paymentLimit !== null"
				:tabs="tabs"
				:paymentLimit="paymentLimit"
				:paymentType="paymentType"
			>
				<template v-slot:CardPayMethodPage>
					<CardPayMethod
						:saveable="saveable"
						:once="once"
						:paymentType="paymentType"
						:payInfo="payInfo"
						:addBtnText="addBtnText"
						:guideText="guideText"
						@paymethod-completed="handleCardPayMethodCompleted"
						ref="card"
					/>
				</template>
				<template v-slot:BankPayMethodPage>
					<BankPayMethod
						@paymethod-completed="handleBankPayMethodCompleted"
						:saveable="saveable"
						:payDd="payDd"
						:once="once"
						:paymentType="paymentType"
						:paymentGroup="paymentGroup"
						:payInfo="payInfo"
						:addBtnText="addBtnText"
						:guideText="guideText"
						ref="bank"
					/>
				</template>
			</PayMethodTab>
		</div>
	</v-dialog>
</template>

<script>
/**
 * 결제수단 추가 화면이다.
 * @description 카드이체, 은행이체 추가 화면은 별도의 컴포넌트로 되어있다.
 */
export default {
	name: 'PayMethodPage',
	props: {
		saveable: { Boolean, default: false },
		once: { Boolean, default: false },
		payDd: Array,
		paymentLimit: { String, default: '' },
		paymentType: { String, default: '' },
		paymentGroup: { Number, default: -1 } // 개별, 공통 그룹
	},
	data() {
		return {
			tabs: [],
			tmpTabs: [], // 초기 상태 저장 용도
			dialog: false,
			resolve: null,
			reject: null,
			selectedTab: 0,
			payInfo: {},
			titleText: '결제수단 추가',
			addBtnText: '추가하기',
			guideText: undefined
		}
	},
	mounted() {
		// 탭메뉴 생성 - 카드
		if (this.paymentType === 'cust' && this.paymentLimit === 'C') {
			this.tabs.push({
				id: 'CardPayMethodPage',
				name: '카드이체',
				icon: ''
			})
		}

		if (this.paymentType === 'cust' && this.paymentLimit === 'B') {
			this.tabs.push({
				id: 'BankPayMethodPage',
				name: '은행이체',
				icon: ''
			})
		}

		if (
			(this.paymentType === 'cust' && this.paymentLimit === '') ||
			(this.paymentType === '' && this.paymentLimit === '') ||
			this.paymentType === 'recp'
		) {
			this.tabs.push(
				{
					id: 'CardPayMethodPage',
					name: '카드이체',
					icon: ''
				},
				{
					id: 'BankPayMethodPage',
					name: '은행이체',
					icon: ''
				}
			)
		}

		this.tmpTabs = this.tabs
	},
	methods: {
		open(info) {
			this.payInfo = info
			if (Object.keys(info).length > 0) {
				const bankMthd = info.AF_PAY_MTHD === '11'
				this.titleText = bankMthd ? '이체일 선택' : '결제일 선택'
				this.addBtnText = '저장하기'
				this.guideText = bankMthd
					? '※ 이체일 선택 후 저장해 주세요.'
					: '※ 결제일 선택 후 저장해 주세요.'
				const tabId = bankMthd ? 'BankPayMethodPage' : 'CardPayMethodPage'
				const tabNm = bankMthd ? '은행이체' : '카드이체'
				this.tabs = [
					{
						id: tabId,
						name: tabNm,
						icon: ''
					}
				]
			} else {
				this.titleText = '결제수단 추가'
				this.addBtnText = '추가하기'
				this.tabs = this.tmpTabs
				this.guideText = undefined
			}

			this.dialog = true
			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},

		handleCardPayMethodCompleted(args) {
			this.$emit('accountInfo', args) // 고입확에서 접근시 사용
			if (args) {
				this.resolve(args)
				this.dialog = false
			} else {
				this.close()
			}
		},

		handleBankPayMethodCompleted(args) {
			this.$emit('accountInfo', args) // 고입확에서 접근시 사용
			if (args) {
				this.resolve(args)
				this.dialog = false
			} else {
				this.close()
			}
		},

		close() {
			if (this.paymentLimit.card === false) {
				this.$refs.mainCard.$refs.card.validRest()
			}
			if (this.paymentLimit.card === false) {
				this.$refs.mainBank?.$refs.bank.validRest()
			}
			this.resolve(false)
			this.dialog = false
		}
	}
}
</script>
