<template>
	<v-container>
		<v-speed-dial
			v-model="fab"
			:top="top"
			:bottom="bottom"
			:right="right"
			:left="left"
			:direction="direction"
			:open-on-hover="hover"
			:transition="transition"
			absolute
		>
			<template v-slot:activator>
				<v-btn v-model="fab" color="blue darken-2" dark fab>
					<v-icon v-if="fab">mdi-close</v-icon>
					<v-icon v-else>mdi-plus</v-icon>
				</v-btn>
			</template>
			<v-btn fab small @click="clickForward">
				<v-icon>mdi-arrow-right</v-icon>
			</v-btn>
			<v-btn fab small @click="clickRewind">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>
		</v-speed-dial>
	</v-container>
</template>

<script>
export default {
	data: () => ({
		direction: 'top',
		fab: false,
		fling: false,
		hover: false,
		tabs: null,
		top: false,
		right: true,
		bottom: true,
		left: false,
		transition: 'slide-y-reverse-transition'
	}),
	methods: {
		clickForward() {},
		clickRewind() {}
	}
}
</script>
