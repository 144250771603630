/* eslint-disable no-unused-vars */
import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import AAlert from '@/components/atoms/AAlert'
import i18n from '@/plugins/i18n'

const PromiseAlert = Vue.extend(AAlert)

function renderAlert(options) {
	const { message, title, okText, cancelText, persistent } = options
	return new Promise((resolve, reject) => {
		const myAlert = new PromiseAlert({
			methods: {
				closeHandler(fn, arg) {
					return function() {
						fn(arg)
						myAlert.$destroy()
						myAlert.$el.remove()
					}
				}
			},
			render(h) {
				return h(AAlert, {
					props: {
						dialogTitle: title,
						dialogText: message,
						buttonText: okText,
						subButtonText: cancelText,
						persistent
					},
					on: {
						confirm: this.closeHandler(() => resolve(true)),
						cancel: this.closeHandler(() => resolve(false))
					}
				})
			},
			vuetify
		}).$mount()
		document.body.appendChild(myAlert.$el)
	})
}

const promiseDialog = {
	install(Vue) {
		// alert
		Vue.prototype.$alert = options => {
			const { message, persistent, title, okText } = options
			const defaultOptions = {
				...options,
				okText: okText ? okText : i18n.t('common.word.confirm'),
				persistent: persistent !== undefined ? persistent : true
			}
			return renderAlert(defaultOptions)
		}
		Vue.$alert = options => {
			const { message, persistent, title, okText } = options
			const defaultOptions = {
				...options,
				okText: okText ? okText : i18n.t('common.word.confirm'),
				persistent: persistent !== undefined ? persistent : true
			}
			return renderAlert(defaultOptions)
		}

		// confirm
		Vue.prototype.$confirm = options => {
			const { message, persistent, title, okText, cancelText } = options
			const defaultOptions = {
				...options,
				okText: okText ? okText : i18n.t('common.word.confirm'),
				cancelText: cancelText ? cancelText : i18n.t('common.word.close'),
				persistent: persistent !== undefined ? persistent : true
			}
			return renderAlert(defaultOptions)
		}
		Vue.$confirm = options => {
			const { message, persistent, title, okText, cancelText } = options
			const defaultOptions = {
				...options,
				okText: okText ? okText : i18n.t('common.word.confirm'),
				cancelText: cancelText ? cancelText : i18n.t('common.word.close'),
				persistent: persistent !== undefined ? persistent : true
			}
			return renderAlert(defaultOptions)
		}
	}
}

Vue.use(promiseDialog)
