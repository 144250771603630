<template>
	<v-snackbar
		v-model="snackbar.status"
		:timeout="3000"
		bottom
		:color="snackbar.color"
	>
		<div v-html="snackbar.msg"></div>
	</v-snackbar>
</template>

<script>
/**
 * Snack Bark
 * /src/store/common.store.js
 * @description 공통으로 사용하는 스낵바이다.
 *              snackbar: { status: false, msg: '', color: 'error' }
 *              스낵바의 컬러는 Vuetify (https://vuetifyjs.com/en/styles/colors/) 기준으로 설정한다.
 */
import { mapState } from 'vuex'

export default {
	name: 'SnackBar',
	computed: {
		...mapState({ snackbar: state => state.common.snackbar })
	}
}
</script>

<style></style>
