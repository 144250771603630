<template>
	<div>
		<v-bottom-sheet v-model="seen" scrollable :persistent="persistent">
			<template v-slot:activator="{ on, attrs }" v-if="!hideButton">
				<v-btn
					class="btn_select_type"
					height="48px"
					depressed
					outlined
					@click="open"
					v-bind="attrs"
					v-on="on"
				>
					<slot name="default" />
				</v-btn>
			</template>
			<h2 class="tit">{{ title }}</h2>
			<div class="scr_area">
				<v-btn-toggle class="btn_bottom_sheet" v-model="selected" multiple>
					<v-btn v-for="(item, i) in items" :key="`item-${i}`" :value="item">
						<div class=" infor_type_3">
							<h3 class="">{{ item.MAKTX }} <span class="tag">멤버십</span></h3>

							<dl>
								<dt class="text_gray">주문번호</dt>
								<dd>{{ item.ORDER_NO }}</dd>
							</dl>
							<dl>
								<dt class="text_gray">가입일자</dt>
								<dd>{{ item.MS_DT }}</dd>
							</dl>
						</div>
					</v-btn>
				</v-btn-toggle>
			</div>
			<v-row class="justify-center">
				<v-btn color="default" class="mr-3" large @click="seen = false">
					닫기
				</v-btn>
				<v-btn color="primary" large @click="close">적용</v-btn>
			</v-row>
		</v-bottom-sheet>
	</div>
</template>

<script>
// import { fetchMembershipList } from '@/apis/order.inst.api'

export default {
	props: {
		value: [String, Number],
		text: String,
		title: String,
		persistent: Boolean,
		hideButton: Boolean,
		multiple: {
			type: Boolean,
			default: false
		},
		items: Array
	},
	computed: {
		mandatory() {
			return !!this.selected
		}
	},
	data() {
		return {
			seen: false,
			selected: []
			// items: []
		}
	},
	async mounted() {
		// await this.getMembershipList()
	},
	methods: {
		// async getMembershipList() {
		// 	// let params = { kunnr: this.$store.getters['verify/custNo'] }
		// 	let params = { kunnr: this.$store.state['verify'].customer.custNo }
		// 	const res = await fetchMembershipList(params)
		// 	this.$log('fetchMembershipList /v1/05/05: ')
		// 	let items = res.resultObject.data.T_MEMBERSHIP_LIST

		// 	this.$store.dispatch('inst/setMembershipList', items)
		// 	this.items = items
		// },
		open() {
			this.seen = true
		},
		close() {
			this.$emit('item-selected', this.selected)
			this.seen = false
		}
	}
}
</script>
