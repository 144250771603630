const state = {
	accountInfo: { payType: 'R' },
	onceAccountInfo: { payType: 'O' },
	tempAccount: [],
	tempOnceAccount: {},
	tempSetup: {},
	retInfo: ''
}

const getters = {
	accountInfo: state => state.accountInfo,
	onceAccountInfo: state => state.onceAccountInfo,
	tempAccount: state => state.tempAccount,
	tempOnceAccount: state => state.tempOnceAccount,
	retInfo: state => state.retInfo,
	tempSetup: state => state.tempSetup
}

const actions = {
	async setAccountInfo({ commit }, value) {
		commit('setAccountInfo', value)
	},
	async setOnceAccountInfo({ commit }, value) {
		commit('setOnceAccountInfo', value)
	},
	async setTempAccount({ commit }, value) {
		commit('setTempAccount', value)
	},
	async setTempOnceAccount({ commit }, value) {
		commit('setTempOnceAccount', value)
	},
	async setRetInfo({ commit }, value) {
		commit('setRetInfo', value)
	},
	async setTempSetup({ commit }, value) {
		commit('setTempSetup', value)
	}
}

const mutations = {
	setAccountInfo(state, value) {
		state.accountInfo = { ...state.accountInfo, ...value }
	},
	setOnceAccountInfo(state, value) {
		state.onceAccountInfo = { ...state.onceAccountInfo, ...value }
	},
	setTempAccount(state, value) {
		state.tempAccount = [...value]
	},
	setTempOnceAccount(state, value) {
		state.tempOnceAccount = { ...value }
	},
	setRetInfo(state, value) {
		state.retInfo = value
	},
	setTempSetup(state, value) {
		state.tempSetup = value
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
