<template>
	<div class="header_wrap web">
		<header>
			<div class="header_area">
				<h1>coway</h1>
			</div>
		</header>
	</div>
</template>

<script>
/**
 * COWAY 헤더
 * @description /router/ 설정에 'showWebHeader : true'이면 출력한다.
 *{
				path: 'verify/test',
				name: 'verify-test',
				meta: {
					showWebHeader: true,
					title: '고객 인증'
				},
				component: () => import('@/views/test.vue')
			},
 */

export default {
	name: 'MobileWebHeader'
}
</script>
