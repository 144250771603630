const state = {
	prdList: [],
	recpAmt: 0, // 임시 수납금액
	prdChk: false, //선택한 상품갯수
	matterssChk: false // 매트리스 추가 안내
}

const getters = {
	prdList: state => state.prdList,
	recpAmt: state => state.recpAmt,
	prdChk: state => state.prdChk,
	matterssChk: state => state.matterssChk
}

const actions = {
	async addPrd({ commit }, value) {
		commit('addPrd', value)
	},
	async delPrd({ commit }, value) {
		commit('delPrd', value)
	},
	async delPrdOid({ commit }, value) {
		commit('delPrdOid', value)
	},
	async delAllPrd({ commit }) {
		commit('delAllPrd')
	},
	async setRecpAmt({ commit }, value) {
		commit('setRecpAmt', value)
	},
	async setPrdList({ commit }, value) {
		commit('setPrdList', value)
	},
	async setPrdChk({ commit }, value) {
		commit('setPrdChk', value)
	},
	async setMatterssChk({ commit }, value) {
		commit('setMatterssChk', value)
	}
}

const mutations = {
	addPrd(state, value) {
		state.prdList.push(value)
	},
	delPrd(state, payload) {
		// let i = state.prdList.indexOf(value)
		const { i } = payload
		state.prdList.splice(i, 1)
	},
	delPrdOid(state, payload) {
		state.prdList.forEach((v, i) => {
			if (v.oid === payload) {
				if (v.options.quantity > 1) {
					// 수량 변경
					const count = Number(v.options.quantity) - 1
					state.prdList[i].options.quantity = count
				} else {
					// 삭제
					state.prdList.splice(i, 1)
				}
			}
		})
	},
	delAllPrd(state) {
		state.prdList = []
	},
	setRecpAmt(state, value) {
		state.recpAmt = value
	},
	setPrdList(state, value) {
		state.prdList = value
	},
	setPrdChk(state, value) {
		state.prdChk = value
	},
	setMatterssChk(state, value) {
		state.matterssChk = value
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
