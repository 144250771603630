/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import Vue from 'vue'

const isProduction = process.env.VUE_APP_DEBUG_MODE === 'false'

const log = isProduction ? function() {} : console.log
// 일반 module에서 사용
export default log

// vue instance 또는 전역으로 사용
const Logger = {
	install(Vue, options) {
		Vue.prototype.$log = log
		Vue.$log = log
	}
}

Vue.use(Logger)
