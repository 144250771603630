<template>
	<v-dialog
		v-model="dialog"
		:max-width="options.width"
		@keydown.esc="cancel"
		scrollable
		:persistent="options.persistent"
	>
		<v-card class="aalert">
			<v-card-title>
				<p v-if="options.existing">
					<strong class="warn">기존 고객</strong>이 검색되었습니다.<br />
					해당 고객정보로 상품 선택을 시작합니다.
				</p>
				<p v-else>
					<strong class="warn">신규 고객</strong>입니다.<br />
					아래 고객정보로 신규고객 등록합니다.
				</p>
			</v-card-title>
			<v-card-text>
				<template>
					<ul class="info_list">
						<li>
							<dt>고 객 명 :</dt>
							<dd>{{ options.name }}</dd>
						</li>
						<li>
							<dt>성별 :</dt>
							<dd>{{ options.sex === '1' ? '남' : '여' }}</dd>
						</li>
						<li>
							<dt>생년월일 :</dt>
							<dd>{{ options.bahns }}</dd>
						</li>
						<li>
							<dt>휴대폰번호 :</dt>
							<dd>{{ $options.filters.phone(options.mob) }}</dd>
						</li>
						<li v-if="options.existing">
							<dt>주 소 :</dt>
							<dd>{{ options.address }}</dd>
						</li>
					</ul>
				</template>
			</v-card-text>
			<v-card-actions class="pt-0">
				<v-spacer></v-spacer>
				<v-btn class="btn_confirm btn_all" text @click.native="agree">
					상품 선택하기
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
import filters from '@/mixins/filters'
export default {
	mixins: [filters],
	computed: {
		// hasMessage() {
		// 	const obj = this.message || {}
		// 	return !(Object.keys(obj).length === 0)
		// },
		// existingCustomer() {
		// 	this.$log('existingCustomer:', this.options.existing)
		// 	return this.options.existing
		// }
	},
	data: () => ({
		dialog: false,
		resolve: null,
		reject: null,
		options: {
			persistent: true
		}
	}),
	methods: {
		open(options) {
			this.dialog = true
			this.options = Object.assign(this.options, options)
			this.$log(JSON.stringify(this.options))
			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},
		agree() {
			this.resolve(true)
			this.dialog = false
		},
		cancel() {
			this.resolve(false)
			this.dialog = false
		}
	}
}
</script>
