/**
 * 재렌탈 사전 계약 라우터
 */
import PreContractMain from '@/views/PreContractMain'

export default [
	{
		path: '/pre',
		component: PreContractMain,
		children: [
			{
				path: 'list',
				name: 'pre-contract-list',
				meta: {
					title: '재렌탈 사전계약'
				}
			}
		]
	}
]
