/**
 * GUIDE 화면에서 사용하는 안내 메세지
 * @description id : 주문유형(SALE_ID), name: 주문유형명, rerental: 재렌탈 여부
 */
export default {
	NotSeller: {
		title: '판매인 정보가 확인되지 않습니다.',
		msg:
			'안드로이드 웹뷰 업데이트 후 이용해주세요. 자세한 방법은 지국 행정매니저에게 문의주세요.'
	},
	notPilot: {
		title: '파일럿 운영 대상 조직이 아닙니다.',
		msg:
			'추후 전체 대상으로 오픈시에 사용 가능합니다. 자세한 문의는 각 담당 지원팀으로 하시기 바랍니다.'
	},
	siteNotice: {
		title: '서비스 개선 안내',
		msg:
			'고객님의 더 나은 주문 서비스 제공을 위해 <br />시스템 개선 작업이 진행됩니다.<br />작업 시간동안 이용 불가에 대해<br />양해의 말씀을 드립니다.'
	}
}
