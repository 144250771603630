const state = {
	instList: [],
	addrList: [],
	memoList: [],
	membershipList: [],
	setupInfos: [],
	orderInfoParams: {},
	orderInfoResult: [],
	orderNo: ''
}

const getters = {
	instList: state => state.instList,
	addrList: state => state.addrList,
	memoList: state => state.memoList,
	membershipList: state => state.membershipList,
	setupInfos: state => state.setupInfos,
	orderInfoParams: state => state.orderInfoParams,
	orderInfoResult: state => state.orderInfoResult,
	orderNo: state => state.orderNo
}

const actions = {
	async setInstList({ commit }, payload) {
		commit('setInstList', payload)
	},
	async setAddrList({ commit }, payload) {
		commit('setAddrList', payload)
	},
	async setMemoList({ commit }, payload) {
		commit('setMemoList', payload)
	},
	async setMembershipList({ commit }, payload) {
		commit('setMembershipList', payload)
	},
	async setSetupInfos({ commit }, payload) {
		commit('setSetupInfos', payload)
	},
	async setOrderInfoParams({ commit }, payload) {
		commit('setOrderInfoParams', payload)
	},
	async setOrderInfoResult({ commit }, payload) {
		commit('setOrderInfoResult', payload)
	},
	async setOrderNo({ commit }, value) {
		commit('setOrderNo', value)
	}
}

const mutations = {
	setInstList(state, payload) {
		state.instList = payload
	},
	setAddrList(state, payload) {
		state.addrList = payload
	},
	setMemoList(state, payload) {
		state.memoList = payload
	},
	setMembershipList(state, payload) {
		state.membershipList = payload
	},
	setSetupInfos(state, payload) {
		state.setupInfos = payload
	},
	setOrderInfoParams(state, payload) {
		state.orderInfoParams = { ...state.orderInfoParams, ...payload }
	},
	setOrderInfoResult(state, payload) {
		state.orderInfoResult = payload
	},
	setOrderNo(state, value) {
		state.orderNo = value
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
