<template>
	<div>
		<v-bottom-sheet
			v-model="seen"
			scrollable
			:persistent="persistent"
			:content-class="`u-payment-sheet`"
		>
			<template v-slot:activator="{ attrs }" v-if="!hideButton">
				<v-btn
					class="btn_select_type"
					height="48px"
					depressed
					outlined
					@click="open"
					v-bind="attrs"
					:disabled="disabled"
				>
					<slot name="default" />
				</v-btn>
			</template>
			<v-card class="u-payment-list">
				<v-card-title class="tit">
					{{ title }}
				</v-card-title>
				<v-card-text>
					<div class="scr_area">
						<v-btn-toggle
							class="toggle_row"
							:mandatory="mandatory"
							v-model="pay"
						>
							<div class="flex_row">
								<v-btn
									:ripple="false"
									v-for="(item, i) in items"
									:key="item + i"
									outlined
									:class="
										(paymentLimit === 'B' && item.AF_PAY_MTHD !== '11') ||
										(paymentLimit === 'C' && item.AF_PAY_MTHD !== '12')
											? 'btn-remove disabled mb-2'
											: 'btn-remove mb-2'
									"
								>
									<em class="val" @click="onClick(item)">{{ item.text }}</em>
									<div v-if="item.SAP_YN === false" @click="custPayDel(item)">
										<v-icon
											:class="
												(paymentLimit === 'B' && item.AF_PAY_MTHD !== '11') ||
												(paymentLimit === 'C' && item.AF_PAY_MTHD !== '12')
													? 'disabled'
													: ''
											"
											>mdi-close</v-icon
										>
									</div>
								</v-btn>
							</div>
							<p class="no-data" v-if="items.length === 0">
								등록된 결제 수단이 없습니다.<br />
								결제 수단을 추가해 주세요.
							</p>
						</v-btn-toggle>
					</div>
					<v-btn
						class="u-btn-payment"
						default
						large
						v-if="addButton"
						@click="payMethodPop()"
						>추가</v-btn
					>
				</v-card-text>
			</v-card>
		</v-bottom-sheet>

		<!-- 결제수단 추가 -->
		<PayMethodPage
			v-if="paymentLimit !== null"
			:saveable="true"
			@accountInfo="accountInfo"
			:payDd="payDd"
			:once="once"
			:paymentLimit="paymentLimit"
			:paymentType="paymentType"
			ref="payment-add-popup"
		/>
	</div>
</template>

<script>
/**
 * 결제정보 추가 바텁 시트
 * @description 결제정보 선택 화면의 결제 수단을 선택하거나 결제수단을 추가할때 사용한다.
 */
import { getPayDate } from '@/apis/order.recp.api'
import moment from 'moment'

export default {
	name: 'PopPaymentBottomSheet',
	props: {
		value: [String, Number],
		onceNo: String,
		items: {
			type: Array,
			default: () => {
				return []
			}
		},
		paymentLimit: { String, default: '' }, // 허용된 결제수단 타입
		paymentType: { String, default: '' }, // 진입 (cust:고객직접인증, recp:결제정보 선택)
		text: String,
		title: String,
		persistent: Boolean,
		hideButton: Boolean,
		disabled: { type: Boolean, default: false },
		addButton: {
			type: Boolean,
			default: false
		},
		once: { Boolean, default: false },
		array: { Number, default: -1 },
		index: { Number, default: -1 },

		paymentGroup: { Number, default: -1 } // 개별, 공통 그룹
	},

	computed: {
		displayText() {
			return this.text
		},

		mandatory() {
			return this.items.some(x => x.code === this.value)
		}
	},
	data() {
		return {
			seen: false,
			selectedItem: -1,
			pay: -1,
			selectNo: '', // 카드번호, 계좌번호
			code: '', // 카드코드, 은행코드
			payMthd: '', // 카드, 은행 구분
			payDd: [],
			isPreContract: this.$store.state['verify'].customer.orderType === 'C',
			deliveryInfos: this.$store.getters['schedule/deliveryInfos']
		}
	},
	methods: {
		// 추가한 결제 정보
		accountInfo(param) {
			this.selectNo = param.accountNo
			this.code = param.bankCd
			this.payMthd = param.payMethod
		},

		open() {
			this.seen = true

			if (this.value) {
				this.items.forEach((v, i) => {
					if (v.AF_CARD_NO === this.onceNo) {
						this.pay = i
						this.selectedItem = v
					}
				})
			}
		},

		/**
		 * 결제수단 선택
		 * @param item 결제수단 정보
		 * @description 설정 가능한 결제 수단이 아닌 경우 Alert
		 * 							설정 가능한 결제 수단의 경우 적용
		 */
		async onClick(item) {
			if (
				(this.paymentLimit === 'B' && item.AF_PAY_MTHD !== '11') ||
				(this.paymentLimit === 'C' && item.AF_PAY_MTHD !== '12')
			) {
				this.onPyamentLimitAlert(item)
				this.seen = true
			} else {
				if (!this.once && item.modifyRequired && item.ECODE === 'E001') {
					const msg =
						'선택한 결제정보는 현재 선택하실 수 없습니다. 다음 화면에서 정기결제일 오류 정보를 다른 정보로 선택해 주세요.'
					await this.$alert({ message: msg })
					this.payMethodPop(item)
					return
				}

				// 사전계약 정기결제 카드(12) 소유권 도래월 유효기간 체크
				if (this.isPreContract && !this.once && item.AF_PAY_MTHD === '12') {
					const msg =
						'선택한 카드의 유효기간이 소유권도래월 이전이라 선택 불가합니다. '
					if (
						this.deliveryInfos.length > 0 &&
						this.deliveryInfos[0].preOwnerYm &&
						item.AF_EXPIRE_YM !== ''
					) {
						const preDateValid = moment(item.AF_EXPIRE_YM.concat('01')).isAfter(
							moment(this.deliveryInfos[0].preOwnerYm.concat('02'))
						)

						if (!preDateValid) {
							await this.$alert({ message: msg })
							return
						}
					} else {
						await this.$alert({ message: msg })
						return
					}
				}

				this.selectedItem = item
				this.$emit('input', this.selectedItem.code)
				this.$emit('item-selected', this.selectedItem, this.array)

				this.seen = false
			}
		},

		/**
		 * 결제수단 삭제
		 * @param item 결제수단 정보
		 * @description 본인명의와 다른 카드를 삭제하도록(일시불 결제만 가능?)
		 * 							기존 삭제 로직을 그대로 사용중
		 */
		async custPayDel(item) {
			const res = await this.$alert({
				message: '등록된 결제 정보를 삭제하시겠습니까?',
				okText: '확인',
				cancelText: '취소'
			})

			if (res) {
				this.pay = -1
				this.selectedItem = -1

				const text = this.array === -1 ? '수납결제 삭제' : '정기결제 삭제'
				const params = {
					item: item.AF_CARD_NO,
					code: item.AF_BANK_CD,
					payMthd: item.AF_PAY_MTHD,
					type: 'DEL',
					index: this.array,
					text: text
				}

				this.$emit('getPaymentList', params)
				this.seen = false
			}
		},

		/**
		 * 결제수단 제한 Alert
		 * @param item 결제수단 정보
		 * @description paymentLimit의 값이 C(카드가능), B(은행가능)
		 * 							B는 실제 없는 구분값이며, 추후 확장을 고려하여 생성
		 */
		onPyamentLimitAlert(item) {
			if (this.paymentLimit !== '') {
				if (item.AF_PAY_MTHD === '11' && this.paymentLimit !== 'B') {
					this.$alert({
						message: '은행이체는 정기결제정보로 설정이 불가합니다.'
					})
				} else if (item.AF_PAY_MTHD === '12' && this.paymentLimit !== 'C') {
					this.$alert({
						message: '카드이체는 정기결제정보로 설정이 불가합니다.'
					})
				}
			}
		},
		async payDateCheck() {
			await getPayDate({
				kunnr: this.$store.getters['verify/custNo']
			}).then(res => {
				const days = res.resultObject.data.E_TAB_DAYS

				this.payDd = days
			})
		},

		/**
		 * 결제수단 추가
		 * @description
		 *  1. 결제수단 선택 버튼에서 '추가' 버튼을 클릭하면 결제수단 추가 화면이 보여진다.
		 *  2. 결제수단을 입력하고 '추가하기'를 클릭하여 결제수단을 추가하면 결체수단 체크 및 등록 API를 호출하고 화면을 닫는다.
		 *  3. 닫히면 오픈된 컴포넌트(payment_method_popup)의 응답이 발생하며, paymentListReload를 호출하여
		 *     결제정보 수단을 갱신한다.
		 */
		async payMethodPop(payInfo = {}) {
			//은행이체 결제일 체크
			await this.payDateCheck()
			const res = await this.$refs['payment-add-popup'].open({
				...payInfo
			})
			if (res) {
				const text = this.array === -1 ? '수납결제 추가' : '정기결제 추가'
				const params = {
					item: this.selectNo,
					code: this.code,
					payMthd: this.payMthd,
					type: 'ADD',
					index: this.array,
					text: text
				}

				this.$emit('getPaymentList', params) // 변경된 결제정보 재호출
				this.seen = false
			}
		}
	}
}
</script>
