import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/plugins/i18n'
import { isProduction } from '@/utils'

Vue.use(Vuetify)
Vuetify.config.silent = isProduction()

const currentLocale = process.env.VUE_APP_I18N_LOCALE || 'en'

export default new Vuetify({
	// 삭제 ...styleOptions,
	lang: {
		locales: { currentLocale },
		current: currentLocale,
		t: (key, ...params) => i18n.t(key, params)
	},
	// 210707 yachoi
	theme: {
		themes: {
			light: {
				primary: '#1b9ed8',
				secondary: '#2ea0fb',
				third: '#4286f4',
				backgroud: '#F5F5F5'
			},
			dark: {
				backgroud: '#424242'
			}
		}
	},
	icons: {
		icons: {
			iconfont: 'mdi' || 'mdiSvg' || 'fa'
		}
	}
})
