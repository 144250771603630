import log from '@/utils/log'
import router from '@/router'
import store from '@/store'

export const registerCallbackToApp = (
	{ title, cbPhysicalBack, isNotUseDsHeaderPhysicalBack = false },
	thisArg
) => {
	if (thisArg && cbPhysicalBack) {
		window[cbPhysicalBack] = thisArg[cbPhysicalBack]
	}

	const appTitle = title ? title : '모바일 주문'
	const appCallbackPysicalBack = cbPhysicalBack
		? cbPhysicalBack
		: 'closePopView'

	// DS APP 헤더(웹), onBackPressed 전역 변수는 DS앱 물리키
	if (store.getters['common/app'] === 'ds') {
		// 헤더 title 추후 통일 되게 변경 예정, 우선은 DS앱 헤더만 적용하여 타이틀 분기처리
		let dsAppTitle = title ? title : '주문접수'
		if (dsAppTitle === '모바일 주문 진행현황') {
			dsAppTitle = '주문현황'
		} else if (dsAppTitle === '모바일 재렌탈 사전예약') {
			dsAppTitle = '재렌탈 사전예약 신청'
		} else if (dsAppTitle === '모바일 재렌탈 사전예약 주문') {
			dsAppTitle = '재렌탈 사전예약 주문접수'
		}

		window.onBackPressed = window.dsCloseWebView
		if (!cbPhysicalBack || isNotUseDsHeaderPhysicalBack) {
			window.cbPhysicalBack = undefined
		} else {
			window.onBackPressed = window.cbPhysicalBack
		}

		const params = store.getters['common/dsHeaderData']
		params.title = dsAppTitle
		params.physicalBack = !!window.cbPhysicalBack
		store.commit('common/setDsHeaderData', params)
		return
	}

	const requestValue = {
		id: 'SET_APP',
		param: {
			titlebar: {
				image_name: '',
				visible: true,
				title: appTitle, // 화면별 타이틀
				left: [
					{
						control_id: 'MHBarButton',
						item_id: 13,
						button_text: '',
						image_name: 'common/images/btn_back.png',
						action_type: 'jscall',
						action: `${appCallbackPysicalBack}` // JS 콜백
					}
				]
			},
			android_hardware_backbutton: `${appCallbackPysicalBack}` // 물리키(BACK)
		}
	}

	if (store.getters['common/type'] === 'cody') {
		requestValue.param.titlebar.right = [
			{
				control_id: 'MHBarButton',
				item_id: 14,
				button_text: '',
				image_name: 'app/images/renewal/icon_menu01.png',
				action_type: 'jscall',
				action: 'showMenuView' // JS 콜백
			}
		]
	}

	// 인터페이스 실행
	if (
		window.webkit &&
		window.webkit.messageHandlers &&
		window.webkit.messageHandlers.BMManager
	) {
		window.webkit.messageHandlers.BMManager.postMessage(
			JSON.stringify(requestValue)
		)
	} else if (window.BMManager) {
		// eslint-disable-next-line no-undef
		BMManager.executeTaskJSB(JSON.stringify(requestValue))
	}
}

/**
 * 홈메뉴 생성 (최초 1회 반드시 실행 필요) : app 진입경로 화면에서 한번만 실행
 * @param
 */
export const requestCreateMenuToApp = () => {
	log('requestCreateMenuToApp Called.......')
	let requestValue = {
		id: 'CREATE_MENU_VIEW',
		param: {
			message: {},
			target_page: '01_01/html/01_01_07_menu2.html',
			target_view: 'right',
			callback: 'appcallOnLoad'
		}
	}
	// 인터페이스 실행
	if (
		window.webkit &&
		window.webkit.messageHandlers &&
		window.webkit.messageHandlers.BMManager
	) {
		window.webkit.messageHandlers.BMManager.postMessage(
			JSON.stringify(requestValue)
		)
	} else if (window.BMManager) {
		// eslint-disable-next-line no-undef
		BMManager.executeTaskJSB(JSON.stringify(requestValue))
	}
}

/**
 * 홈메뉴 열기 : 전역함수로 생성
 * @param
 */
export const showMenuView = () => {
	log('showMenuView Called.......')
	let requestValue = {
		id: 'SHOW_MENU_VIEW',
		param: {
			message: {},
			target_view: 'right'
		}
	}
	// 인터페이스 실행
	if (
		window.webkit &&
		window.webkit.messageHandlers &&
		window.webkit.messageHandlers.BMManager
	) {
		window.webkit.messageHandlers.BMManager.postMessage(
			JSON.stringify(requestValue)
		)
	} else if (window.BMManager) {
		// eslint-disable-next-line no-undef
		BMManager.executeTaskJSB(JSON.stringify(requestValue))
	}
}

/**
 * 화면 닫기 : 전역함수로 생성
 * @param
 */
export const closePopView = () => {
	let requestValue = {
		id: 'POP_VIEW',
		param: {}
	}
	// 인터페이스 실행
	if (
		window.webkit &&
		window.webkit.messageHandlers &&
		window.webkit.messageHandlers.BMManager
	) {
		window.webkit.messageHandlers.BMManager.postMessage(
			JSON.stringify(requestValue)
		)
	} else if (window.BMManager) {
		// eslint-disable-next-line no-undef
		BMManager.executeTaskJSB(JSON.stringify(requestValue))
	}
}

// 이전 페이지로 이동
export const onClickAndroidPrevMove = () => {
	log('onClickAndroidPrevMove Called.......')
	router.go(-1)
}

// ds close 함수 전역으로 설정
export const createDsCloseWebView = thisArg => {
	if (thisArg) {
		window.dsCloseWebView = thisArg.dsCloseWebView
	}
}

window.requestCreateMenuToApp = requestCreateMenuToApp
window.showMenuView = showMenuView
window.closePopView = closePopView
window.onClickAndroidPrevMove = onClickAndroidPrevMove
