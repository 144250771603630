export default {
	'sample-main': '메인',
	'sample-dialog': '예시 다이얼로그',
	'sample-form': '예시 입력',
	'sample-alert': '예시 알림창',
	'sample-scroll': '예시 목록(virtual-scroll)',
	'sample-table': '예시 목록(v-data-table)',
	'sample-test-id': '예시 params path',
	'sample-map': '지도',
	'sample-content': '예시'
}
