<template>
	<div>
		<p class="txt_warn">
			{{ guideText }}
		</p>
		<BankPayMethodForm
			ref="bank"
			:hidePaymentDate="once"
			:payDd="payDd"
			:div="once ? 'O' : 'R'"
			:paymentType="paymentType"
			:paymentGroup="paymentGroup"
			:payInfo="payInfo"
		></BankPayMethodForm>

		<div class="bottom_box flex_row">
			<v-btn class="mb-2 btn_grey" color="grey" large @click="onCancel()">
				취소
			</v-btn>
			<v-btn class="mb-2 btn_primary" color="primary" large @click="onAdd()">
				{{ addBtnText }}
			</v-btn>
		</div>
	</div>
</template>
<script>
/**
 * 결제수단 추가 - 은행이체 추가 화면
 */
import { bankChk } from '@/apis/order.recp.api'
import { mapActions } from 'vuex'
import moment from 'moment'

export default {
	name: 'BankPayMethod',
	data() {
		return {
			webOrderNo: this.$store.getters['verify/mobOrderNo']
		}
	},

	props: {
		saveable: {
			Boolean,
			default: false
		},
		once: {
			Boolean,
			default: false
		},
		payDd: Array,
		paymentType: { String, default: '' },
		paymentGroup: { Number, default: -1 }, // 개별, 공통 그룹
		payInfo: { Object, default: {} },
		addBtnText: { String, default: '추가하기' },
		guideText: { String, default: '※ 계약자 명의 계좌만 가능' }
	},
	watch: {
		payInfo: {
			handler() {
				this.$nextTick(async () => {
					this.$refs.bank.validRest()
				})
			}
		}
	},
	methods: {
		...mapActions({ showSnackbar: 'common/showSnackbar' }),
		async onAdd() {
			const formData = this.$refs.bank.onSubmit()
			const CURRENT_TIME = moment().format('HH:mm:ss')
			this.$log('CURRENT_TIME', CURRENT_TIME)
			const START_TIME = '23:30:00'
			const END_TIME = '00:30:00'

			if (!formData) return
			this.$log('formData:', formData)
			const {
				ssn: ssnNo,
				accountNo: cardNo,
				bankCd: cardcomCd,
				bankNm: cardcomNm,
				custName: ownerNm,
				dd: payDd,
				custType: katr2
			} = formData
			let bank = {
				ssnNo,
				cardNo,
				payType: this.once === true ? 'O' : 'R',
				cardcomCd,
				cardcomNm,
				ownerNm,
				payDd,
				katr2,
				saveable: this.saveable
			}
			if (CURRENT_TIME > START_TIME || CURRENT_TIME < END_TIME) {
				//은행점검 이용불가시간
				this.$alert({
					message:
						'해당시간 (23:30 ~ 00:30)동안 은행점검으로 이용불가 합니다. 점검 완료 후 이용해주십시오'
				})
			} else {
				if (!this.saveable && Object.keys(this.payInfo).length > 0) {
					this.$emit('paymethod-completed', {
						payMethod: '11',
						...formData,
						...bank
					})
					this.$refs.bank.validRest()
					return
				}

				bankChk(this.webOrderNo, bank)
					.then(res => {
						this.$log(res)
						const { data } = res.resultObject
						if (data && data.RETURN.TYPE === 'E') {
							const message = data.RETURN.MESSAGE
							this.$alert({
								message
							})
							return
						}
						this.showSnackbar({
							msg: '은행계좌 확인되었습니다.',
							color: '#222'
						})
						this.$emit('paymethod-completed', {
							payMethod: '11',
							...formData,
							...bank
						})
						this.$refs.bank.validRest()
					})
					.catch(err => {
						this.$log(err)
						this.$alert({ message: err })
					})
			}
		},

		onCancel() {
			this.$alert({
				message: '입력한 정보를 저장하지 않고 이전화면으로 이동하시겠습니까?',
				okText: '이동',
				cancelText: '유지'
			}).then(res => {
				if (res) {
					this.$emit('paymethod-completed', false)
					this.$refs.bank.validRest()
				}
			})
		},

		handleBankCompanySelected(item) {
			this.bankCompanyDisplayText = item.text
		}
	}
}
</script>
