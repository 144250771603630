<template>
	<div class="stepper_wrap_new" :class="'step_0' + getStep()">
		<ul>
			<li>
				1<span class="checked_icon"><v-icon>mdi-check</v-icon></span>
			</li>
			<li>
				2<span class="checked_icon"><v-icon>mdi-check</v-icon></span>
			</li>
			<li>
				3<span class="checked_icon"><v-icon>mdi-check</v-icon></span>
			</li>
			<li>
				4<span class="checked_icon"><v-icon>mdi-check</v-icon></span>
			</li>
			<li>
				5<span class="checked_icon"><v-icon>mdi-check</v-icon></span>
			</li>
			<li>
				6<span class="checked_icon"><v-icon>mdi-check</v-icon></span>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'stepper',
	props: {
		step: {
			type: Number,
			default: 1
		}
	},
	data() {
		return {
			flag: true
		}
	},
	methods: {
		getStep() {
			let no = 0
			let path = this.$route.path.split('/')
			switch (path[2]) {
				case 'verify':
					no = 1
					break
				case 'prd':
					no = 2
					break
				case 'pay':
					no = 3
					break
				case 'recp':
					no = 4
					break
				case 'inst':
					no = 5
					break
				case 'ec':
				case 'schedule':
					no = 6
					break
				default:
					no = 0
					break
			}
			if (no === 0) return this.step + ' stepper_wrap_hot'

			return no
		}
	}
}
</script>

<style lang="scss" scoped>
.stepper_wrap_hot {
	ul {
		li {
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(6) {
				display: none;
			}
		}
	}
}
</style>
