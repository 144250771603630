<template>
	<div>
		<v-bottom-sheet v-model="seen" scrollable :persistent="persistent">
			<template v-slot:activator="{ attrs }" v-if="!hideButton">
				<v-btn
					class="btn_select_type"
					height="48px"
					depressed
					outlined
					@click="open"
					v-bind="attrs"
					:disabled="disabled"
				>
					<slot name="default" />
				</v-btn>
			</template>
			<h2 class="tit">{{ title }}</h2>
			<div class="scr_area">
				<v-btn-toggle class="toggle_row" :mandatory="mandatory" v-model="pay">
					<div class="flex_row" v-for="(item, i) in items" :key="item + i">
						<v-btn :ripple="false" class="btn_white mb-2" @click="onClick(i)">
							{{ item.text }}
						</v-btn>
						<v-btn
							v-if="item.nameChk"
							icon
							class="btn_icon"
							x-small
							fab
							color="grey"
							@click="custPayDel(item)"
							><v-icon>mdi-close</v-icon></v-btn
						>
						<template v-if="i === items.length - 1">
							<v-btn
								class="u-btn-payment centered"
								default
								large
								v-if="addButton"
								@click="payMethodPop"
								>추가</v-btn
							>
						</template>
					</div>
					<template v-if="items.length === 0">
						<v-btn
							class="u-btn-payment centered"
							default
							large
							v-if="addButton"
							@click="payMethodPop"
							>추가</v-btn
						>
					</template>
				</v-btn-toggle>
			</div>
		</v-bottom-sheet>
		<PayMethodPage :saveable="true" :once="once" ref="pmp" />
	</div>
</template>

<script>
/**
 * 하단 버트
 * @description 공통 버튼으로 제작 되었지만 버튼별로 별도의 기능이 들어가면서 분리
 * - 할인선택 : 주문유형
 */
import { custPayDelete } from '@/apis/order.recp.api'
export default {
	props: {
		value: [String, Number],
		onceNo: String,
		items: {
			type: Array,
			default: () => {
				return []
			}
		},
		text: String,
		title: String,
		persistent: Boolean,
		hideButton: Boolean,
		disabled: { type: Boolean, default: false },
		addButton: {
			type: Boolean,
			default: false
		},
		once: { Boolean, default: false },
		array: { Boolean, default: false },
		index: { Number, default: -1 }
	},
	computed: {
		displayText() {
			return this.text
		},
		mandatory() {
			return this.items.some(x => x.code === this.value)
		}
	},
	data() {
		return {
			seen: false,
			selectedItem: -1,
			pay: 0
		}
	},
	methods: {
		open() {
			this.seen = true
			if (this.value) {
				this.items.forEach((v, i) => {
					if (v.code === this.value) {
						this.pay = i
						this.selectedItem = v
					}
				})
			}
		},
		onClick(e) {
			this.selectedItem = this.items[e]
			this.$emit('input', this.selectedItem.code)
			if (this.array !== false) {
				this.$emit('item-list-selected', this.selectedItem, this.array)
			} else {
				this.$emit('item-selected', this.selectedItem)
			}
			this.seen = false
		},
		async payMethodPop() {
			const res = await this.$refs.pmp.open()
			if (res) {
				this.$emit('reload')
				this.seen = false
			}
		},
		/**
		 * 본인명의와 다른 카드를 삭제하도록(일시불 결제만 가능)
		 */
		async custPayDel(item) {
			const res = await this.$alert({
				message: '등록된 결제 정보를 삭제하시겠습니까?',
				okText: '확인',
				cancelText: '취소'
			})
			if (res) {
				const webOrderNo = this.$store.getters['verify/mobOrderNo']
				await custPayDelete(webOrderNo, { ids: [item.PAYMENT_ID] })
				this.pay = -1
				this.selectedItem = -1
				this.$emit('reload', item.AF_CARD_NO)
				this.seen = false
			}
		}
	}
}
</script>
