<template>
	<v-app>
		<Noti
			v-if="['development', 'blue'].includes(isDebugLabel)"
			:mode="isDebugLabel"
			:app-version="appVersion"
			:app-name="appName"
			style="position: sticky"
		/>
		<MHeaderDs v-show="isHeaderDsActive" />
		<MHeaderWeb v-if="isHeaderWebActive" />
		<v-main :class="{ with_web_header: isHeaderWebActive }">
			<router-view :key="$route.fullPath" />
		</v-main>
		<MSpinner />
		<SnackBar />
	</v-app>
</template>

<script>
import MSpinner from '@/components/molecules/MSpinner'
import SnackBar from '@/components/modules/SnackBar'
import MHeaderWeb from './components/modules/MHeaderWeb'
import MHeaderDs from './components/modules/MHeaderDs'
import Noti from './components/modules/noti/Noti'

import { mapState } from 'vuex'
import store from '@/store'

export default {
	name: 'App',
	components: {
		MSpinner,
		SnackBar,
		MHeaderWeb,
		MHeaderDs,
		Noti
	},
	data() {
		return {
			appVersion: '',
			appName: '',
			isHeaderWebActive: false,
			isHeaderDsActive: false
		}
	},
	created() {
		this.appVersion = process.env.VUE_APP_VERSION
		this.appName = process.env.VUE_APP_NAME
	},
	computed: {
		...mapState(['route']),
		isDebugLabel() {
			return process.env.NODE_ENV
		}
	},
	watch: {
		route(value) {
			// showWebHeader의 값이 존재 한다면 고객용 페이지
			if (typeof value.meta.showWebHeader === 'boolean') {
				this.isHeaderDsActive = false
			} else {
				this.isHeaderDsActive = store.getters['common/app'] === 'ds'
			}
			if (value.meta.showWebHeader) {
				this.isHeaderWebActive = value.meta.showWebHeader
			}
		}
	}
}
</script>
