<template>
	<a-button
		color="whitesmoke lighten-4"
		class="mt-2"
		:block="true"
		@click="seen = true"
		:title="text"
		v-if="text"
		:icon="showDropDown ? 'mdi-menu-down' : ''"
		slotName="body"
	>
		<template v-slot:body>
			<v-bottom-sheet v-model="seen" scrollable>
				<h2 class="tit">{{ title }}</h2>
				<div class="scr_area">
					<v-btn-toggle
						class="btn_bottom_sheet col_3"
						v-model="selected"
						:mandatory="mandatory"
					>
						<v-btn
							v-for="(item, i) in rentalPrices"
							:key="`item-${i}`"
							:value="item"
							@click="onClick(item)"
						>
							{{ item.text }}
						</v-btn>
					</v-btn-toggle>
					<h3 class="tit_sub">일시불</h3>
					<div class="wrap_input row_col_2_btn">
						<v-text-field
							outlined
							:placeholder="('' + priceFilters.min) | comma"
							dense
							inputmode="numeric"
							v-model="priceMin"
						/>
						<span class="dash">-</span>
						<vuetify-money
							outlined
							:placeholder="('' + priceFilters.max) | comma"
							dense
							class="col-4 pa-0"
							inputmode="numeric"
							v-model="priceMax"
							:options="options"
						/>
						<v-btn class="col-3 ml-2" color="primary" @click="handleApply"
							>적용</v-btn
						>
					</div>
				</div>
			</v-bottom-sheet>
		</template>
	</a-button>
</template>

<script>
import filters from '@/mixins/filters'
export default {
	mixins: [filters],
	props: {
		// value: Boolean,
		items: {
			type: Array,
			default: () => {
				return []
			}
		},
		text: String,
		title: String,
		showDropDown: Boolean
	},
	computed: {
		mandatory() {
			return !!this.selected
		},
		rentalOptions() {
			const prices = this.items.map(x => {
				return { month_amt: x.month_amt, price: x.price }
			})

			return prices
		},
		rentalFilters() {
			const under20000 = this.rentalOptions.some(x => x.month_amt < 20000)
			const under30000 = this.rentalOptions.some(
				x => x.month_amt >= 20000 && x.month_amt < 30000
			)
			const under40000 = this.rentalOptions.some(
				x => x.month_amt >= 30000 && x.month_amt < 40000
			)
			const under50000 = this.rentalOptions.some(
				x => x.month_amt >= 40000 && x.month_amt < 50000
			)
			const under60000 = this.rentalOptions.some(
				x => x.month_amt >= 50000 && x.month_amt < 60000
			)
			const over60000 = this.rentalOptions.some(x => x.month_amt >= 60000)

			return [
				{ display: under20000, text: '2만원 미만' },
				{ display: under30000, text: '2만원대' },
				{ display: under40000, text: '3만원' },
				{ display: under50000, text: '4만원' },
				{ display: under60000, text: '5만원' },
				{ display: over60000, text: '6만원 이상' }
			]
		},
		priceFilters() {
			const defaultMinMax = { min: 0, max: 0 }
			const prices = this.rentalOptions.map(x => x.price)
			this.$log(prices)
			return {
				...defaultMinMax,
				min: Math.min(...prices),
				max: Math.max(...prices)
			}
		},
		rentalPrices() {
			return this.rentalFilters
				.filter(x => x.display)
				.map((x, index) => {
					return { index, text: x.text }
				})
		}
	},
	data() {
		return {
			seen: false,
			selected: null,
			priceMin: null,
			priceMax: null,
			options: { precision: 0, length: 7 }
		}
	},
	methods: {
		open() {
			this.seen = true
		},
		onChange(value) {
			this.close(value)
		},
		onClick(clickedValue) {
			this.$log('onClick', clickedValue, this.selected)
		},
		close(value) {
			this.$emit('item-selected', value ? value : this.selected)
			this.seen = false
		},
		handleApply() {
			const { index, text } = this.selected || {}
			const min = +this.priceMin
			const max = +this.priceMax
			this.$log(this.priceMin, this.priceMax)
			this.$emit('item-selected', {
				rentalPrice: { index, text },
				lumSumPrice: { min, max }
			})
			this.seen = false
		}
	}
}
</script>

<style></style>
