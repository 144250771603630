<template>
	<span class="number_updown">
		<v-icon @click="decrement" :color="minusDisabled">mdi-minus</v-icon>
		<span class="number" v-text="count" />
		<v-icon @click="increment" :color="plusDisabled">mdi-plus</v-icon>
	</span>
</template>
<script>
export default {
	props: {
		sellType: {
			type: String,
			default: ''
		},
		btnDisabled: {
			type: Boolean,
			default: false
		},
		value: {
			type: Number,
			default: 1
		},
		min: {
			type: Number,
			default: 1
		}
	},
	computed: {
		count: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			}
		},

		// 마이너스 버튼 활성/비활성 color 적용
		minusDisabled() {
			if (this.btnDisabled) {
				return '#ddd'
			} else {
				return this.count === 1 || this.chkSellType() ? '#ddd' : '#222'
			}
		},

		// 플러스 버튼 활성/비활성 color 적용
		plusDisabled() {
			if (this.btnDisabled) {
				return '#ddd'
			} else {
				return this.count === 20 || this.chkSellType() ? '#ddd' : '#222'
			}
		}
	},
	methods: {
		chkSellType() {
			// 재렌탈, 일시불보상판매는 수량 변경 불가
			let is = false
			if (this.sellType === 'Z002' || this.sellType === 'Z005') is = true
			return is
		},

		increment() {
			if (this.btnDisabled) {
				this.$alert({
					title:
						'<div align="left">아래와 같은 경우에 해당되어<br />수량변경이 불가합니다.</div>',
					message:
						'<p></p>' +
						'<ul>' +
						"<li>판매유형이 '재렌탈'인 경우</li>" +
						"<li>판매유형이 '일시불 보상판매'인 경우</li>" +
						'</ul>'
				})
			} else {
				// 상품당 쵀대 수량 20개
				if (this.count < 20) {
					if (!this.chkSellType()) this.count++
				}
			}
		},

		decrement() {
			if (this.btnDisabled) {
				this.$alert({
					title:
						'<div align="left">아래와 같은 경우에 해당되어<br />수량변경이 불가합니다.</div>',
					message:
						'<p></p>' +
						'<ul>' +
						"<li>판매유형이 '재렌탈'인 경우</li>" +
						"<li>판매유형이 '일시불 보상판매'인 경우</li>" +
						'</ul>'
				})
			} else {
				if (this.count > this.min && !this.chkSellType()) this.count--
			}
		}
	}
}
</script>
