<template>
	<v-btn class="select_multi_type" @click="show = true">
		<v-bottom-sheet v-model="show" scrollable>
			<template v-if="multiple">
				<v-list class="multi_select">
					<v-list-item-group
						v-model="selectedItems"
						:multiple="multiple"
						@change="handleItemSelected"
					>
						<template v-for="(item, i) in items">
							<v-divider v-if="!item" :key="`divider-${i}`"></v-divider>
							<v-list-item
								v-else
								:key="`item-${i}`"
								:value="item"
								active-class="primary--text text--accent-4"
							>
								<template v-slot:default="{ active }">
									<v-list-item-content>
										<v-list-item-title v-text="item.text"></v-list-item-title>
									</v-list-item-content>

									<v-list-item-action>
										<v-checkbox
											:input-value="active"
											color="primary accent-4"
										></v-checkbox>
									</v-list-item-action>
								</template>
							</v-list-item>
						</template>
					</v-list-item-group>
				</v-list>
			</template>

			<template v-else>
				<v-list class="single_select">
					<h2>{{ text }}</h2>
					<v-divider />
					<v-list-item-group
						v-model="selectedItem"
						@change="handleItemSelected"
					>
						<v-list-item
							v-for="(item, i) in items"
							:key="`item-${i}`"
							:value="item"
						>
							<v-list-item-content>
								<v-list-item-title>{{ item.text }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list-item-group>
				</v-list>
			</template>
		</v-bottom-sheet>
		<span>{{ computedText }}</span>
		<v-icon icon>mdi-menu-down</v-icon>
	</v-btn>
</template>
<script>
export default {
	props: {
		// value: Boolean,
		items: {
			type: Array,
			default: () => {
				return []
			}
		},
		multiple: {
			type: Boolean,
			default: false
		},
		text: String
	},
	computed: {
		// visible: {
		// 	get() {
		// 		return this.value
		// 	},
		// 	set(value) {
		// 		this.$emit('input', value)
		// 	}
		// }
		computedText() {
			return this.selectedText || this.text
		}
	},
	data() {
		return {
			show: false,
			selectedItems: [],
			selectedItem: '',
			selectedText: ''
		}
	},
	methods: {
		handleItemSelected(value) {
			this.$log(value)
			if (!this.multiple) {
				this.selectedText = value !== undefined ? value.text : ''
				this.show = false
			}
			this.$emit('item-selected', value)
			// this.$emit('input', value)
			// this.show = false
		}
	}
}
</script>
