import Vue from 'vue'
import Vuex from 'vuex'
import common from './common.store'
import verify from './verify.store'
import prd from './prd.store'
import pay from './pay.store'
import ec from './ec.store'
import inst from './inst.store'
import recp from './recp.store'
import custom from './custom.store'
import schedule from './schedule.store'

Vue.use(Vuex)
Vue.config.devtools = JSON.parse(process.env.VUE_APP_DEV_TOOLS) // VueJS 개발툴 활설/비활성

export default new Vuex.Store({
	modules: {
		common,
		verify,
		prd,
		pay,
		ec,
		inst,
		recp,
		custom,
		schedule
	}
})
