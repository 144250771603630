<template>
	<v-dialog class="u-alert-root" v-model="dialog" persistent scrollable>
		<!--contents-->
		<v-card class="u-alert-box">
			<v-card-title>
				고객님이 등록한 결제 및 설치처 정보와
				<br />동일하지 않은 주문이 있습니다.
			</v-card-title>

			<v-card-text>
				주문 상세 내역을 확인하여, 주문별 결제 및 설치처 정보가 고객님이 요청한
				사항과 동일한지 확인 후 전자서명을 진행해 주세요.
				<br /><br />
				요청한 정보와 다른 경우 담당 판매인에게 문의해 주세요.
			</v-card-text>

			<v-card-actions>
				<v-btn class="btn-confirm" @click="dialog = false">확인</v-btn>
			</v-card-actions>
		</v-card>
		<!--//contents-->
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			dialog: false
		}
	},
	methods: {
		open() {
			this.dialog = true
		}
	}
}
</script>
