<template>
	<v-dialog
		v-model="dialog"
		:max-width="options.width"
		@keydown.esc="cancel"
		scrollable
		:persistent="options.persistent"
	>
		<v-card class="aalert">
			<v-card-title>
				<h2 v-html="options.title" />
				<span class="txt_body1 mt-3" v-html="options.guide" />
			</v-card-title>
			<v-card-text>
				<template>
					<ul class="info_list" style="margin-bottom:0px !important">
						<li
							v-for="(value, key, index) in options.contents"
							:key="`${key}-${index}`"
						>
							<dt class="tal">{{ key }}</dt>
							<dd>{{ value }}</dd>
						</li>
					</ul>
				</template>
			</v-card-text>
			<v-card-actions class="pt-0">
				<v-spacer></v-spacer>
				<!-- 버튼 두개 -->
				<v-btn class="btn_sub " text @click="cancel" v-if="options.cancelText">
					{{ options.cancelText }}
				</v-btn>
				<v-btn
					class="btn_confirm"
					text
					@click="ok"
					:class="{ btn_all: !options.cancelText }"
				>
					{{ options.okText }}
				</v-btn>

				<!-- 버튼한개 -->
				<!-- <v-btn class="btn_confirm btn_all" text @click.native="agree">
					상품 선택하기
				</v-btn> -->
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
export default {
	computed: {
		// hasMessage() {
		// 	const obj = this.message || {}
		// 	return !(Object.keys(obj).length === 0)
		// },
		// existingCustomer() {
		// 	this.$log('existingCustomer:', this.options.existing)
		// 	return this.options.existing
		// }
	},
	data: () => ({
		dialog: false,
		resolve: null,
		reject: null,
		options: {
			persistent: true,
			title: '',
			guide: '',
			contents: {},
			okText: '',
			cancelText: ''
		}
	}),
	methods: {
		open(options) {
			this.dialog = true
			this.options = Object.assign(this.options, options)
			this.$log(JSON.stringify(this.options))
			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},
		ok() {
			this.resolve(true)
			this.dialog = false
		},
		cancel() {
			this.resolve(false)
			this.dialog = false
		}
	}
}
</script>
<style lang="scss" scoped>
body .v-application .info_list {
	border-bottom: 0;
}
</style>
