export default {
	filters: {
		comma: value => {
			if (!value) return value
			if (isNaN(value)) return value
			return parseFloat(value).toLocaleString()
		},
		toFixed: (value, decimalPoint) => {
			if (!value) return value
			if (isNaN(value)) return value
			return parseFloat(value).toFixed(decimalPoint)
		},
		// star: (value, start, end) => {
		// 	if (!value) return value
		// 	if (value.length < start) return value
		// 	if (!end) {
		// 		return `${value.substr(0, value.length - start)}${'*'.repeat(start)}`
		// 	}
		// 	return `${value.substr(0, start)}${'*'.repeat(
		// 		end - start + 1
		// 	)}${value.substr(end)}`
		// },
		star: (value, start) => {
			if (!value) return value
			if (value.length < start) return value
			return `${value.substr(0, start)}${'*'.repeat(value.length - start)}`
		},
		phone: value => {
			if (!value) return value

			return `${value.substr(0, 3)}-${'*'.repeat(4)}-${value.substr(-4)}`
		},
		shorten: (value, maxlength, indicator) => {
			if (!value) return ''
			if (value.length <= maxlength) indicator = ''
			const append = indicator ? indicator : ''
			return `${value.substring(0, maxlength)}${append}`
		},
		name: value => {
			const reg = new RegExp(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/)
			if (reg.test(value)) {
				//한글마스킹처리
				switch (value.length) {
					case 1:
						break
					case 2:
						value = `${value.substr(0, 1)}${'*'.repeat(1)}`
						break
					case 3:
						value = `${value.substr(0, 1)}${'*'.repeat(1)}${value.substr(2)}`
						break
					case 4:
						value = `${value.substr(0, 1)}${'*'.repeat(2)}${value.substr(3)}`
						break
					case 5:
						value = `${value.substr(0, 1)}${'*'.repeat(3)}${value.substr(4)}`
						break
				}
			} else {
				//영문마스킹처리
				if (value.length >= 5) {
					value = `${value.substr(0, 5)}${'*'.repeat(value.length - 5)}`
				} else {
					value = `${value.substr(0, value.length - 1)}${'*'.repeat(1)}`
				}
			}
			return value
		},
		/**
		 * 설치 주소 정보 마스킹
		 * @param addr1 기본주소
		 * @param addr2 상세주소
		 * @param type
		 * 			0: 기본주소 + 상세주소 * x 10개
		 * 		 	1: 기본주소 + 상세주소
		 * @returns {`${string} ${string}`}
		 */
		addr: (addr1, addr2, type) => {
			let result = ''
			if (type === 0) {
				result = `${addr1} **********`
			} else {
				result = `${addr1} ${addr2}`
			}

			return result
		},
		/**
		 * 앞 자리수 채우기
		 * @param value 숫자값
		 * @param width 채울 자리수
		 * @returns {string|string}
		 */
		numberPadding: (value, width) => {
			value = Number(value) + ''
			return new Array(width - value.length + 1).join('0') + value
		},
		/**
		 * 전화번호 마스킹
		 * @param value 전화번호
		 * @param type
		 * 				0 : 가운데 * (별) - (하이픈 있음),
		 * 				1: 일반 전화번호 포멧 - (하이픈 있음),
		 * 				2: 가운데 * (별) 하이픈 없음,
		 * 			    기타 : 숫자 번호만 하이픈 없음
		 * @returns {string|*}
		 */
		phoneFormat: (value, type) => {
			const regEx = /[^0-9]/g
			const strNum = value.replace(regEx, '')
			let result = ''

			try {
				if (strNum.length === 11) {
					if (type === 0) {
						result = strNum.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3')
					} else if (type === 1) {
						result = strNum.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
					} else if (type === 2) {
						result = strNum.replace(/(\d{3})(\d{4})(\d{4})/, '$1****$3')
					} else {
						result = strNum.replace(/(\d{3})(\d{4})(\d{4})/, '$1$2$3')
					}
				} else if (strNum.length === 10) {
					if (strNum.indexOf('02') === 0) {
						// 서울 가운데 4자리
						if (type === 0) {
							result = strNum.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3')
						} else if (type === 1) {
							result = strNum.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3')
						} else if (type === 2) {
							result = strNum.replace(/(\d{2})(\d{4})(\d{4})/, '$1****$3')
						} else {
							result = strNum.replace(/(\d{2})(\d{4})(\d{4})/, '$1$2$3')
						}
					} else {
						// 기타 가운데 4자리
						if (type === 0) {
							result = strNum.replace(/(\d{3})(\d{3})(\d{4})/, '$1-****-$3')
						} else if (type === 1) {
							result = strNum.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
						} else if (type === 2) {
							result = strNum.replace(/(\d{3})(\d{3})(\d{4})/, '$1****$3')
						} else {
							result = strNum.replace(/(\d{3})(\d{3})(\d{4})/, '$1$2$3')
						}
					}
				} else if (strNum.length === 9) {
					if (strNum.indexOf('02') === 0) {
						// 서울 가운데 3자리
						if (type === 0) {
							result = strNum.replace(/(\d{2})(\d{3})(\d{4})/, '$1-***-$3')
						} else if (type === 1) {
							result = strNum.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3')
						} else if (type === 2) {
							result = strNum.replace(/(\d{2})(\d{3})(\d{4})/, '$1***$3')
						} else {
							result = strNum.replace(/(\d{2})(\d{3})(\d{4})/, '$1$2$3')
						}
					} else {
						// 기타 가운데 3자리=?
						if (type === 0) {
							result = strNum.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3')
						} else if (type === 1) {
							result = strNum.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
						} else if (type === 2) {
							result = strNum.replace(/(\d{3})(\d{3})(\d{4})/, '$1***$3')
						} else {
							result = strNum.replace(/(\d{3})(\d{3})(\d{4})/, '$1$2$3')
						}
					}
				} else if (strNum.length === 8) {
					// 기타번호
					if (type === 1) {
						result = strNum.replace(/(\d{4})(\d{4})/, '$1-$2')
					} else {
						result = strNum.replace(/(\d{4})(\d{4})/, '$1$2')
					}
				} else {
					result = strNum
				}
			} catch (e) {
				result = strNum
				// eslint-disable-next-line no-console
				console.log(e)
			}
			return result
		}
	}
}
