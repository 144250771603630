<template>
	<v-menu
		v-model="menu"
		ref="menu"
		min-width="100px"
		transition="scale-transition"
		:close-on-content-click="contentClose"
		offset-y
	>
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				v-model="currentTime"
				prepend-icon="mdi-clock-time-four-outline"
				readonly
				v-on="on"
				v-bind="attrs"
			/>
		</template>
		<v-time-picker
			v-model="currentTime"
			@click:minute="$refs.menu.save(currentTime)"
			scrollable
		/>
	</v-menu>
</template>

<script>
import moment from 'moment'

export default {
	name: 'ATimePicker',
	props: {
		time: {
			type: [String, Date],
			default: moment(new Date()).format('HH:mm')
		},
		contentClose: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			currentTime: this.time,
			menu: false
		}
	},
	watch: {
		time(time) {
			this.currentTime = time
		},
		currentTime(time) {
			this.$emit('time', time)
		}
	}
}
</script>
