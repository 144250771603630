import api from '@/apis/config'
const { post, get } = api

/**
 * 코디번호 암호화
 * @param userNo
 * @returns {Promise<*>}
 */
export const userNoEnc = async codyNo => {
	const params = {
		aType: '01',
		codyNo: codyNo
	}
	const response = await post(`v1/00/256`, { ...params })
	return response
}

/**
 * 모바일주문 사용 가능 여부 조회
 * @param params
 * @returns {Promise<void>}
 */
export const serviceCheck = async params => {
	const response = await get(`v1/00/08/${params.userNo}/${params.type}`)
	return response
}

/**
 * 서비스 타입
 * @param params userKey, codyNo를 전달하여 판매인의 타입을 확인
 * @returns {Promise<*>}
 */
export const serviceType = async params => {
	const response = await post('v1/00/11', { ...params })
	return response
}
