export default [
	{
		path: 'common/popup',
		name: 'common-popup',
		meta: {
			isPublic: true,
			type: 'main'
		},
		component: () => import('@/views/common/PopupPage.vue')
	},
	{
		path: 'confirm',
		name: 'order-confirm',
		meta: {
			isPublic: true,
			type: 'main'
		},
		component: () => import('@/views/confirm/OrderConfirm.vue')
	},
	{
		path: 'confirm/info',
		name: 'order-confirm-info',
		meta: {
			isPublic: true,
			type: 'main'
		},
		component: () => import('@/views/confirm/OrderConfirmInfo.vue')
	},
	{
		path: 'cody/form',
		name: 'cody-form',
		meta: {
			isPublic: true,
			type: 'main',
			title: '주문진행현황 조회'
		},
		component: () => import('@/views/cody/CodyForm.vue')
	},
	// {
	// 	path: 'subscription/form',
	// 	name: 'subscription-form',
	// 	meta: {
	// 		isPublic: true,
	// 		type: 'main',
	// 		title: '재랜탈 사전예약 주문현황'
	// 	},
	// 	component: () => import('@/views/cody/SubscriptionForm.vue')
	// },
	{
		path: 'denied',
		name: 'error-denied',
		meta: {
			isPublic: true,
			type: 'main'
		},
		component: () => import('@/views/error/AccessDenied.vue')
	},
	{
		path: 'confirm/homepage',
		name: 'order-confirm-homepage',
		meta: {
			isPublic: true,
			type: 'main'
		},
		component: () => import('@/views/confirm/OrderConfirmForHomePage.vue')
	},
	{
		path: 'schedule',
		name: 'order-schedule',
		meta: {
			isPublic: true,
			type: 'main',
			title: '배정판오픈'
		},
		props: true,
		component: () => import('@/views/common/ScheduleBoard.vue')
	},
	{
		path: 'schedule/reprogress',
		name: 'order-schedule-reprogress',
		meta: {
			isPublic: true,
			type: 'main',
			title: '배정재진행'
		},
		props: true,
		component: () => import('@/views/common/ScheduleReprogress.vue')
	},
	{
		path: 'pre/form',
		name: 'pre-form',
		meta: {
			isPublic: true,
			type: 'main',
			title: '재랜탈 사전계약 주문현황'
		},
		component: () => import('@/views/cody/PreContractForm.vue')
	}
]
