<template>
	<v-form ref="bankForm" class="form px-6" @submit.prevent="onSubmit">
		<div class="wrap_input">
			<h3 class="label">은행</h3>
			<BankCompanyBottom
				class="btn_all"
				title="은행 선택"
				v-model="form.bankCd"
				@item-selected="handleBankCompanySelected"
				:instantTransfer="instantTransfer"
				:div="div"
			>
				<template>{{ form.bankNm }}<span class="btn_value"></span></template>
			</BankCompanyBottom>
		</div>
		<div class="wrap_input">
			<h3 class="label">계좌번호</h3>
			<v-text-field
				v-model="form.accountNo"
				outlined
				placeholder="- 없이 입력"
				dense
				:rules="rules.accountNo"
				inputmode="numeric"
				v-mask="['###########', '#########################']"
				maxlength="25"
			/>
		</div>

		<div class="wrap_input" v-if="!hidePaymentDate">
			<h3 class="label">이체일</h3>
			<v-btn-toggle v-model="form.dd" color="primary" class="toggle_btn_3_1">
				<v-btn :value="10" text> 10일 </v-btn>
				<v-btn :value="20" text> 20일 </v-btn>
				<v-btn :value="25" text> 25일 </v-btn>
			</v-btn-toggle>
		</div>
		<div class="wrap_input" v-if="!hideCustType">
			<h3 class="label">명의</h3>
			<v-btn-toggle
				v-model="form.custType"
				color="primary"
				class="toggle_btn justify-start"
			>
				<v-btn :value="1" text> 개인 </v-btn>
				<v-btn :value="2" text class="ml-2" v-if="custType === 'L'">
					개인사업자
				</v-btn>
			</v-btn-toggle>
		</div>
		<div class="wrap_input">
			<template v-if="hideCustType">
				<h3 class="label">예금주</h3>
			</template>
			<template v-else>
				<h3 class="label">명의자</h3>
			</template>
			<v-text-field
				:disabled="disabledCust"
				v-model="form.custName"
				outlined
				:placeholder="form.custName"
				dense
				:rules="rules.required"
				inputmode="text"
				:maxlength="$Constants.MAX_CUST_NAME_LENGTH"
			/>
		</div>

		<div class="wrap_input">
			<template v-if="custType === 'L'">
				<h3 class="label">법정생년월일/사업자번호</h3>
				<v-text-field
					:disabled="disabledCust"
					v-model="form.ssn"
					outlined
					placeholder=""
					dense
					:rules="rules.ssn"
					inputmode="numeric"
					maxlength="10"
					v-mask="['######', '##########']"
				/>
			</template>
			<template v-else>
				<h3 class="label">법정생년월일</h3>
				<v-text-field
					:disabled="true"
					v-model="form.ssn"
					outlined
					placeholder=""
					dense
					:rules="rules.ssn"
					inputmode="numeric"
					maxlength="6"
					v-mask="['######']"
				/>
			</template>
		</div>
	</v-form>
</template>
<script>
import { required, maxLength, minLength, numeric } from '@/utils/rules'
export default {
	props: {
		hidePaymentDate: Boolean,
		hideCustType: Boolean,
		hideBizNo: Boolean,
		instantTransfer: Boolean,
		div: String,
		disabledCustName: {
			type: Boolean,
			default: true
		}
	},
	watch: {
		'form.custType': {
			handler(val) {
				this.$nextTick(() => {
					if (val === 2) {
						this.$set(this.form, 'ssn', this.ssnBiz)
						this.$set(this.form, 'custName', this.custNameBiz)
						this.disabledCust = true
					} else {
						this.$set(this.form, 'ssn', this.ssn)
						this.$set(this.form, 'custName', this.custName)
						this.disabledCust = true
					}
				})
			}
		}
	},
	data() {
		return {
			disabledCust: false,
			form: {
				accountNo: '',
				dd: 10,
				custType: 1,
				custName: '고객명',
				dialog: false,
				bankCd: '',
				ssn: '999999',
				bankNm: '은행입력',
				regerNo: this.$store.getters['common/codyNo'] //코디번호 또는 고객번호
			},
			rules: {
				name: [v => required(v), v => minLength(v, 2), v => maxLength(v, 10)],
				ssn: [
					v => required(v),
					v => numeric(v),
					v => maxLength(v, 10),
					v => minLength(v, 6)
				],
				accountNo: [
					v => required(v),
					v => numeric(v),
					v => minLength(v, 10),
					v => maxLength(v, 25)
				],
				required: [v => required(v)]
			},
			custType: '',
			ssn: '',
			ssnBiz: '',
			custName: '',
			custNameBiz: ''
		}
	},
	mounted() {
		this.pageInit()
	},
	methods: {
		handleBankCompanySelected(item) {
			this.$log(item)
			this.form.bankNm = item.cdNm
		},
		onSubmit() {
			this.$log('onSubmit', this.form)
			if (!this.validate()) return
			this.$log('validated', this.form)
			return this.form
		},
		validRest() {
			this.form = {
				accountNo: '',
				dd: 10,
				custType: 1,
				custName: '고객명',
				dialog: false,
				bankCd: '',
				ssn: '999999',
				bankNm: '은행입력',
				regerNo: this.$store.getters['common/codyNo'] //코디번호 또는 고객번호
			}
			this.pageInit()
			this.$refs.bankForm.resetValidation()
		},
		validate() {
			if (!this.$refs.bankForm.validate()) return false
			if (!this.form.bankCd) {
				this.$alert({ message: '은행을 선택하세요' })
				return false
			}
			if (!this.form.dd) {
				this.$alert({ message: '이체일을 선택하세요' })
				return false
			}
			if (!this.form.custType) {
				this.$alert({ message: '명의를 선택하세요' })
				return false
			}
			return true
		},
		pageInit() {
			this.$nextTick(() => {
				this.disabledCust = this.disabledCustName
				let ssn = this.$store.getters['verify/ssn']
				this.custType = this.$store.getters['verify/customerType']
				this.$set(this.form, 'custType', this.custType === 'L' ? 2 : 1)
				if (ssn) {
					this.ssn = ssn.substr(2, ssn.length)
					this.custName = this.$store.getters['verify/custName']
					this.$set(this.form, 'ssn', this.ssn)
					this.$set(this.form, 'custName', this.custName)
					if (this.custType === 'L') {
						this.ssnBiz = this.$store.getters['verify/bizNo']
						this.custNameBiz = this.$store.getters['verify/bizName']
						this.$set(this.form, 'ssn', this.ssnBiz)
						this.$set(this.form, 'custName', this.custNameBiz)
					}
				}
			})
		}
	}
}
</script>
