<template>
	<v-form class="form px-6" ref="cardForm" @submit.prevent="onSubmit">
		<!-- 카드사 선택 -->
		<div class="wrap_input">
			<h3 class="label">카드사</h3>
			<CardCompanyBottom
				class="btn_all"
				title="카드사 선택"
				:disabledButton="disabledPayInfo"
				v-model="form.bankCd"
				@item-selected="handleCardCompanySelected"
			>
				<template>{{ form.bankNm }}<span class="btn_value"></span></template>
			</CardCompanyBottom>
		</div>
		<!--// 카드사 선택 -->

		<!-- 카드번호 -->
		<div class="wrap_input">
			<h3 class="label">카드번호</h3>
			<v-text-field
				:disabled="disabledPayInfo"
				v-model="form.accountNo"
				outlined
				placeholder="- 없이 입력"
				dense
				:rules="rules.accountNo"
				inputmode="numeric"
				v-mask="['################']"
			/>
		</div>
		<!--// 카드번호 -->

		<!-- 유효 기간 -->
		<div class="wrap_input">
			<h3 class="label">유효 기간</h3>
			<v-row class="wrap_field_3 align">
				<v-col cols="5">
					<v-text-field
						:disabled="disabledPayInfo"
						outlined
						placeholder="**"
						dense
						v-model="form.avDatMonth"
						:rules="rules.avDatMonth"
						inputmode="numeric"
						v-mask="['##']"
					/>
				</v-col>
				<v-col cols="1">
					<span class="dash">월</span>
				</v-col>
				<v-col cols="4">
					<v-text-field
						:disabled="disabledPayInfo"
						outlined
						placeholder="**"
						dense
						v-model="form.avDatYear"
						:rules="rules.avDatYear"
						inputmode="numeric"
						v-mask="['##']"
					/>
				</v-col>
				<v-col cols="1">
					<span class="dash">년</span>
				</v-col>
			</v-row>
		</div>
		<!-- 유효 기간 -->

		<!-- 결제일 -->
		<div class="wrap_input" v-if="!hidePaymentDate">
			<article class="u-select-block expand">
				<div class="type-button">
					<h3 class="label">결제일</h3>
					<v-btn-toggle
						v-model="form.dd"
						color="primary"
						tile
						class="flex-start"
					>
						<v-btn :value="10" text> 10일 </v-btn>
						<v-btn :value="20" class="ml-2" text> 20일 </v-btn>
					</v-btn-toggle>
				</div>
			</article>
		</div>
		<!--// 결제일 -->
		<!-- 명의 -->
		<div class="wrap_input">
			<article class="u-select-block expand">
				<div class="type-button">
					<h3 class="label">명의</h3>
					<v-btn-toggle
						v-model="form.custType"
						color="primary"
						tile
						class="flex-start"
					>
						<v-btn :disabled="disabledPayInfo" :value="1" text> 개인 </v-btn>
						<v-btn
							:disabled="disabledPayInfo"
							:value="2"
							text
							class="ml-2"
							v-if="custType === 'L'"
						>
							개인사업자
						</v-btn>
					</v-btn-toggle>
				</div>
			</article>
		</div>
		<!--// 명의 -->
		<!-- 명의자 -->
		<div class="wrap_input">
			<h3 class="label">명의자</h3>
			<v-text-field
				:disabled="disabledCust"
				v-model="form.custName"
				outlined
				:placeholder="form.custName"
				dense
				:rules="rules.name"
				inputmode="text"
				:maxlength="$Constants.MAX_CUST_NAME_LENGTH"
			/>
		</div>
		<!-- 명의자 -->
		<!-- 법정생년월일/사업자번호 -->
		<div class="wrap_input">
			<template v-if="custType === 'L'">
				<h3 class="label">법정생년월일/사업자번호</h3>
				<v-text-field
					:disabled="disabledCust"
					v-model="form.ssn"
					outlined
					placeholder=""
					dense
					:rules="rules.ssn"
					inputmode="numeric"
					maxlength="10"
					v-mask="['######', '##########']"
				/>
			</template>
			<template v-else>
				<h3 class="label">법정생년월일</h3>
				<v-text-field
					:disabled="disabledCust"
					v-model="form.ssn"
					outlined
					placeholder=""
					dense
					:rules="rules.ssn"
					inputmode="numeric"
					maxlength="6"
					v-mask="['######']"
				/>
			</template>
		</div>
		<!-- 법정생년월일/사업자번호 -->
	</v-form>
</template>
<script>
/**
 * 결제수단 추가 - 카드이체 추가
 */
import moment from 'moment'
import { required, maxLength, minLength, numeric } from '@/utils/rules'
import { mapState } from 'vuex'
import { getInsMonthOfCard } from '@/utils'

export default {
	name: 'CardPayMethodForm',
	props: {
		name: String,
		hidePaymentDate: Boolean,
		hideBizNo: Boolean,
		disabledCustName: {
			type: Boolean,
			default: true
		},
		recpYn: {
			type: Boolean,
			default: true
		},
		payInfo: { Object, default: {} }
	},
	computed: {
		...mapState('recp', ['canOtherCardPay']),
		insMonthItems() {
			let items = getInsMonthOfCard()
			this.$log('items:', items)
			return items
		}
	},
	data() {
		return {
			disabledCust: false,
			disabledPayInfo: false,
			form: {
				accountNo: '',
				avDatMonth: '',
				avDatYear: '',
				dd: 10,
				custType: 1,
				custName: '고객명',
				ssn: '999999',
				dialog: false,
				bankCd: '',
				bankNm: '카드사 입력',
				regerNo: this.$store.getters['common/codyNo'], //코디번호 또는 고객번호
				insMon: '00', //할부개월
				insMonNm: '일시불', //할부개월명
				payRel: '' //계약자와의 관계
			},
			rules: {
				name: [
					v => required(v),
					v => minLength(v, 2),
					v => maxLength(v, this.$Constants.MAX_CUST_NAME_LENGTH)
				],
				ssn: [
					v => required(v),
					v => numeric(v),
					v => maxLength(v, 10),
					v => minLength(v, 6)
				],
				accountNo: [v => required(v), v => numeric(v), v => minLength(v, 15)],
				required: [v => required(v)],
				avDatMonth: [
					v => required(v),
					v => numeric(v),
					v => minLength(v, 2),
					v => maxLength(v, 2),
					v => this.chkAvDat(v, 'MONTH')
				],
				avDatYear: [
					v => required(v),
					v => numeric(v),
					v => minLength(v, 2),
					v => maxLength(v, 2),
					v => !!v && this.chkAvDat(v, 'YEAR')
				]
			},
			custType: '',
			ssn: '',
			ssnBiz: '',
			custName: '',
			custNameBiz: '',
			isPreContract: this.$store.state['verify'].customer.orderType === 'C',
			deliveryInfos: this.$store.getters['schedule/deliveryInfos']
		}
	},
	async created() {
		await this.$store.dispatch('recp/setCanOtherCardPay')
		this.disabledCust = !this.recpYn || !this.canOtherCardPay
	},
	mounted() {
		this.pageInit()
	},
	watch: {
		'form.custType': {
			handler(val) {
				this.$nextTick(() => {
					if (val === 2) {
						this.$set(this.form, 'ssn', this.ssnBiz)
						this.$set(this.form, 'custName', this.custNameBiz)
						this.disabledCust = this.recpYn ? false : true
					} else {
						this.$set(this.form, 'ssn', this.ssn)
						this.$set(this.form, 'custName', this.custName)
						this.disabledCust = this.recpYn ? false : true
					}
				})
			}
		}
	},
	methods: {
		chkAvDat(v, datType) {
			let isValid = false

			if (datType === 'YEAR') {
				const minYear = moment().format('YY')
				const maxYear = moment()
					.add(10, 'years')
					.format('YY')
				this.$log('minYear, maxYear: ', minYear, maxYear)

				if (v < minYear || v > maxYear) {
					isValid = '카드 유효기간 연도를 확인해 주세요.'
				} else {
					isValid = true
				}
			} else {
				const minMonth = '01'
				const maxMonth = '12'

				this.$log('minMonth, maxMonth: ', minMonth, maxMonth)

				if (v < minMonth || v > maxMonth) {
					isValid = '카드 유효기간 월을 확인해 주세요.'
				} else {
					isValid = true
				}
			}

			return isValid
		},
		onSubmit() {
			this.$log('onSubmit', this.form)
			if (!this.validate()) return
			return {
				...this.form,
				avDat: `20${this.form.avDatYear}${this.form.avDatMonth}`
			}
		},
		validRest() {
			this.form = {
				accountNo: '',
				avDatMonth: '',
				avDatYear: '',
				dd: 10,
				custType: 1,
				custName: '고객명',
				ssn: '999999',
				dialog: false,
				bankCd: '',
				bankNm: '카드사 입력',
				regerNo: this.$store.getters['common/codyNo'], //코디번호 또는 고객번호
				insMon: '00', //할부개월
				insMonNm: '일시불', //할부개월명
				payRel: '' //계약자와의 관계
			}
			this.pageInit()
			this.$refs.cardForm.resetValidation()
		},
		validate() {
			if (!this.$refs.cardForm.validate()) return false
			if (!this.form.bankCd) {
				this.$alert({ message: '카드사를 선택하세요' })
				return false
			}
			if (!this.form.dd) {
				this.$alert({ message: '결제일을 선택하세요' })
				return false
			}
			if (!this.form.custType) {
				this.$alert({ message: '명의를 선택하세요' })
				return false
			}

			// 정기결제 사전계약 소유권 도래월 유효기간 체크
			if (!this.recpYn && this.isPreContract && this.deliveryInfos) {
				if (
					this.form.avDatMonth &&
					this.form.avDatYear &&
					this.deliveryInfos.length > 0 &&
					this.deliveryInfos[0].preOwnerYm
				) {
					const preDateValid = moment(
						`20${this.form.avDatYear}${this.form.avDatMonth}`.concat('01')
					).isAfter(moment(this.deliveryInfos[0].preOwnerYm.concat('02')))

					if (!preDateValid) {
						this.$alert({
							message:
								'기존계정 소유권도래월 이후의 유효기간으로 입력해 주세요.'
						})
						return false
					}
				}
			}
			return true
		},
		handleCardCompanySelected(item) {
			this.form.bankNm = item.cdNm
		},
		handleInsMonthSelected(item) {
			this.form.insMonNm = item.text
		},
		displayInsMonthNm(insMonth) {
			const item = this.insMonthItems.find(x => x.code === insMonth)
			if (item) return item.text
			return '할부개월 선택'
		},
		pageInit() {
			this.$nextTick(() => {
				// [BEGIN] 수정 시 초기화 안되어, nextTick안에 set으로 초기화
				// 참고 : validRest() 함수로도 초기화가 안됨.
				this.$set(this.form, 'bankCd', '')
				this.$set(this.form, 'accountNo', '')
				this.$set(this.form, 'bankNm', '카드사 입력')
				this.$set(this.form, 'avDatYear', '')
				this.$set(this.form, 'avDatMonth', '')
				this.$set(this.form, 'custType', 1)
				// [END] 수정 시 초기화 안되어, nextTick안에 set으로 초기화

				let ssn = this.$store.getters['verify/ssn']
				this.custType = this.$store.getters['verify/customerType']
				this.$set(this.form, 'custType', this.custType === 'L' ? 2 : 1)

				if (ssn) {
					this.ssn = ssn.substr(2, ssn.length)
					this.custName = this.$store.getters['verify/custName']
					this.$set(this.form, 'ssn', this.ssn)
					this.$set(this.form, 'custName', this.custName)
					if (Object.keys(this.payInfo).length > 0) {
						this.disabledPayInfo = true
						this.$set(this.form, 'bankCd', this.payInfo.AF_BANK_CD)
						this.$set(this.form, 'accountNo', this.payInfo.AF_CARD_NO)
						this.$set(this.form, 'bankNm', this.payInfo.AF_BANK_CD_NM)
						this.$set(
							this.form,
							'avDatYear',
							this.payInfo.AF_EXPIRE_YM.substr(2, 2)
						)
						this.$set(
							this.form,
							'avDatMonth',
							this.payInfo.AF_EXPIRE_YM.substr(4, 2)
						)
						this.$set(this.form, 'custType', this.payInfo.KATR2)
						this.custName = this.payInfo.AF_OWNER_NM
						this.ssn = this.payInfo.AF_SSN_NO
						this.$set(this.form, 'ssn', this.ssn)
						this.$set(this.form, 'custName', this.custName)
					} else {
						this.disabledPayInfo = false
					}

					if (this.custType === 'L') {
						this.ssnBiz = this.$store.getters['verify/bizNo']
						this.custNameBiz = this.$store.getters['verify/bizName']
						this.$set(this.form, 'ssn', this.ssnBiz)
						this.$set(this.form, 'custName', this.custNameBiz)
					}
				}
			})
		}
	}
}
</script>
