<template>
	<v-dialog v-model="dialog" max-width="312" scrollable>
		<!--contents-->
		<section class="u-confirm-wrap">
			<article class="contents no-title">
				<v-card-title>
					<h2 class="title">
						{{ content }}
					</h2>
				</v-card-title>
			</article>

			<article class="bottom">
				<v-card-actions>
					<div class="col">
						<v-btn class="btn-cancel" @click="cancel()">취소</v-btn>
					</div>
					<div class="col">
						<v-btn class="btn-confirm" @click="ok()">확인</v-btn>
					</div>
				</v-card-actions>
			</article>
		</section>
		<!--//contents-->
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			dialog: false,
			content: ''
		}
	},
	methods: {
		async open(titleContent) {
			this.content = titleContent

			this.dialog = true
			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},
		async cancel() {
			this.dialog = false
			this.resolve(false)
		},
		async ok() {
			this.dialog = false
			this.resolve(true)
		}
	}
}
</script>
