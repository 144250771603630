<template>
	<v-dialog fullscreen v-model="dialog">
		<!-- dialog -->
		<div class="u-dialog-wrap">
			<!--header-->
			<div class="u-dialog-header">
				<h2 class="heading">애플리케이션 테스트</h2>
				<v-btn icon plain depressed class="btn-close" @click="close">
					<v-icon dark>mdi-window-close</v-icon>
				</v-btn>
			</div>
			<!--//header-->

			<!--contents-->
			<div class="u-dialog-container">
				<div class="u-order-desc">
					<div class="notice">
						테스트를 위한 화면입니다.<br />
						개발서버, 검증서버에서 이용 가능합니다. <br /><br />
						※ 운영서버 접근이 필요한 경우, <br />
						URL을 규격에 맞게 작성하여 접근바랍니다.
					</div>

					<div class="box">
						<!-- 셀렉트박스 -->
						<div class="select">
							<!-- 앱구분 -->
							<PopButtonBottomSheet
								title="앱 구분"
								v-model="testInfo.appType"
								:items="appType"
								@item-selected="handleAppSelected"
								:value="testInfo.appType"
							>
								<template v-if="testInfo.appType === ''">
									앱 선택
									<span class="btn_value"></span>
								</template>
								<template v-else>
									{{ appDisplayText().text }}
									<span class="btn_value">
										<b class="primary--text ml-1">{{
											appDisplayText().type
										}}</b>
									</span>
								</template>
							</PopButtonBottomSheet>
							<!-- //앱 구분 -->

							<!-- 서버-->
							<PopButtonBottomSheet
								title="서버"
								v-model="testInfo.server"
								:items="serverList"
								@item-selected="handleServerSelected"
								:value="testInfo.server"
							>
								<template v-if="testInfo.server === ''">
									서버 선택
									<span class="btn_value"></span>
								</template>
								<template v-else>
									{{ serverDisplayText().text }}
									<span class="btn_value">
										<b class="primary--text ml-1">
											{{ serverDisplayText().protocol }}//
											{{ serverDisplayText().host }}
										</b>
									</span>
								</template>
							</PopButtonBottomSheet>
							<!-- //서버 -->

							<!-- 화면 구분 -->
							<PopButtonBottomSheet
								title="화면 구분"
								v-model="testInfo.service"
								:items="serviceList"
								@item-selected="handleServiceSelected"
								:value="testInfo.service"
							>
								<template v-if="testInfo.service === ''">
									화면 선택
									<span class="btn_value"></span>
								</template>
								<template v-else>
									{{ serviceDisplayText().text }}
									<span class="btn_value">
										<b class="primary--text ml-1">{{
											serviceDisplayText().path
										}}</b>
									</span>
								</template>
							</PopButtonBottomSheet>
							<!-- //화면 구분 -->

							<!-- 판매인등록번호 -->
							<div class="u-number-box">
								<h4 class="tit">판매인등록번호</h4>
								<v-text-field
									dense
									outlined
									hide-details
									v-model="testInfo.codyNo"
								/>
								<p class="msg" v-if="userNoEncode && userNoEncode !== ''">
									{{ userNoEncode }}
								</p>
								<p class="msg">
									암호화된 코드 입력
								</p>
								<!--                <p class="msg">
                암호화 하지 않은 판매인등록번호 입력
              </p>-->
							</div>
							<!-- //판매인등록번호 -->

							<!-- 조직코드 -->
							<div class="u-number-box">
								<h4 class="tit">조직코드</h4>
								<v-text-field
									dense
									outlined
									hide-details
									v-model="testInfo.depth"
								/>
							</div>
							<!-- //조직코드 -->
						</div>
						<!-- //셀렉트박스 -->
					</div>

					<div class="notice">
						입력한 URL Param<br />
						아래 정보는 애플리케이션에 접속한 정보입니다.
					</div>
					<div class="notice">
						O. 앱 구분 :
						<span style="font-weight: bold">{{ displayInfo.appType }}</span
						><br />
						O. 서버 :
						<span style="font-weight: bold"
							>{{ getLocation().protocol }}//{{ getLocation().host }}</span
						><br />
						O. 화면 :
						<span style="font-weight: bold">{{ getLocation().pathName }}</span
						><br />
						O. 조직코드 :
						<span style="font-weight: bold"> {{ displayInfo.depth }} </span
						><br />
						O. 판매인등록번호(복호화) :
						<span style="font-weight: bold"> {{ displayInfo.codyNo }} </span
						><br />
						O. 판매인등록번호(암호화) :
						<span style="font-weight: bold"> {{ displayInfo.codyNoEnc }} </span
						><br />
					</div>
				</div>
			</div>
			<!--//contents-->
		</div>
		<!-- //dialog -->

		<!--bottom-->
		<div class="cw-app-fixed">
			<div class="u-btns-group">
				<div class="column">
					<v-btn large depressed class="u-btn-text" @click="close">닫기</v-btn>
				</div>
				<div class="column">
					<v-btn large depressed class="u-btn-active" @click="start"
						>실행</v-btn
					>
				</div>
			</div>
		</div>
		<!--//bottom-->
	</v-dialog>
</template>

<script>
/**
 * 애플리케이션을 테스트 하기 위한 페이지
 */
const TEST_INFO = {
	server: '', // 서버 주소
	codyNo: '', // 판매인 등록번호
	codyNoEnc: '', // 판매인 등록번호
	appType: '', // 서비스앱 구분
	depth: '', // 조직코드
	service: '' // 화면 구분
}

// 서비스
const SERVICE_LIST = [
	{
		code: '00',
		text: '주문 접수',
		path: '/order/verify',
		type: 'normal'
	},
	{
		code: '01',
		text: '주문 현황',
		path: '/order/status',
		type: 'normal'
	},
	{
		code: '02',
		text: '판매인 주문 현황',
		path: '/order/cody/form',
		type: ''
	},
	{
		code: '03',
		text: '모바일 재렌탈 사전예약',
		path: '/sub/list',
		type: 'subscription'
	},
	{
		code: '04',
		text: '모바일 재렌탈 사전예약 주문',
		path: '/sub/rerental',
		type: 'subscription'
	},
	{
		code: '05',
		text: '[고객] 모바일 재렌탈 사전예약 신청',
		path: '/sub/apply',
		type: 'subscription'
	}
]

// 서버정보
const SERVER_LIST = [
	{
		code: '00',
		text: '개발',
		host: 'morder.coway.dev',
		protocol: 'https'
	},
	{
		code: '01',
		text: '검증',
		host: 'morder.coway.blue',
		protocol: 'https'
	},
	{
		code: '02',
		text: '개발(로컬)',
		host: 'localhost:8080',
		protocol: 'http'
	},
	{
		code: '03',
		text: '검증(로컬)',
		host: 'localhost:8081',
		protocol: 'http'
	}
]

// 서비스 앱구분
const APP_TYPE = [
	{
		code: '00',
		text: '코디앱',
		type: 'cody'
	},
	{
		code: '01',
		text: 'W:IN',
		type: 'win'
	},
	{
		code: '02',
		text: '블루버드',
		type: 'bb'
	},
	{
		code: '03',
		text: '홈케어닥터',
		type: 'home'
	}
]

//import { userNoEnc } from '@/apis/common.api'

export default {
	name: 'AppTester',
	data() {
		return {
			dialog: false,
			testInfo: TEST_INFO,
			displayInfo: {
				server: '', // 서버 주소
				codyNo: '', // 판매인 등록번호
				codyNoEnc: '', // 판매인 등록번호
				appType: '', // 서비스앱 구분
				depth: '', // 조직코드
				service: '' // 화면 구분
			},
			serverList: SERVER_LIST,
			appType: APP_TYPE,
			serviceList: SERVICE_LIST,
			userNoEncode: ''
		}
	},
	methods: {
		open() {
			this.dialog = true
			this.$log('route query', this.$route.query)
			this.$log('isNumner', isNaN(Number(this.$route.query.user_no)))

			// [디버깅용] 입력한 타입
			if (this.$route.query.type) {
				this.displayInfo.appType = this.$route.query.type
				this.appType.forEach(v => {
					if (this.$route.query.type === v.type) {
						this.testInfo.appType = v.code
					}
				})
			}

			// [디버깅용] 서버 선택
			const host = location.host
			this.serverList.forEach(v => {
				if (v.host === host) {
					this.testInfo.server = v.code
				}
			})

			// [디버깅용] 화면선택
			const path = location.pathname
			this.serviceList.forEach(v => {
				if (v.path === path) {
					this.testInfo.service = v.code
				}
			})

			// [디버깅용] 입력한 조직코드
			if (this.$route.query.dept_cd) {
				this.displayInfo.depth = this.$route.query.dept_cd
				this.testInfo.depth = this.$route.query.dept_cd
			}

			// [디버깅용] 입력한 판매인등록번호
			if (this.$route.query.user_no) {
				this.displayInfo.codyNo = this.$route.query.user_no
				this.testInfo.codyNo = this.$route.query.user_no
			}
		},

		// 닫기
		close() {
			this.dialog = false
		},

		// 접속 위치 정보
		getLocation() {
			const result = {
				host: location.host,
				pathName: location.pathname,
				protocol: location.protocol
			}
			return result
		},

		//판매인번호 암호화
		// async encodeing(value) {
		// 	const params = {
		// 		aType: '01',
		// 		codyNo: value
		// 	}
		// 	userNoEnc(params).then(res => {
		// 		this.$log('엔코딩', res)
		// 	})
		// },

		//판매인번호 복호화
		// async decodeing(value) {
		// 	await fetchCody(encodeURIComponent(value)).then(async res => {
		// 		this.$log('디코딩', res)
		// 	})
		// },

		// 실행
		async start() {
			this.$log('INFO ====>>>>', this.testInfo)

			// 타입
			const type = this.appType.filter(x => x.code === this.testInfo.appType)[0]
			if (!type) {
				this.$alert({ message: '앱을 선택해주세요.' })
				return
			}
			this.$log(type)

			// 화면선택
			const path = this.serviceList.filter(
				x => x.code === this.testInfo.service
			)[0]
			if (!path) {
				this.$alert({ message: '접속할 화면을 선택해주세요.' })
				return
			}
			this.$log(path)

			// 서버 선택
			const server = this.serverList.filter(
				x => x.code === this.testInfo.server
			)[0]
			if (!server) {
				this.$alert({ message: '연결할 서버를 선택해주세요.' })
				return
			}
			this.$log(server)

			window.open(
				`${server.protocol}://${server.host}${path.path}?user_no=${this.testInfo.codyNo}&dept_cd=${this.testInfo.depth}&type=${type.type}`,
				'_blank'
			)
		},

		// 서버 선택
		handleServerSelected(item) {
			this.testInfo.server = item.code
		},

		// 앱구분 선택
		handleAppSelected(item) {
			this.testInfo.appType = item.code
		},

		// 서비스 선택
		handleServiceSelected(item) {
			this.testInfo.service = item.code
		},

		// 서버 정보 출력
		serverDisplayText() {
			let result = ''
			this.serverList.forEach(v => {
				if (v.code === this.testInfo.server) {
					result = v
				}
			})
			return result
		},

		// 앱 정보 출력
		appDisplayText() {
			let result = ''
			this.appType.forEach(v => {
				if (v.code === this.testInfo.appType) {
					result = v
				}
			})
			return result
		},

		// 서비스 정보 출력
		serviceDisplayText() {
			let result = ''
			this.serviceList.forEach(v => {
				if (v.code === this.testInfo.service) {
					result = v
				}
			})
			return result
		}
	}
}
</script>

<style scoped lang="scss">
.u-order-desc {
	padding: 0 0 0 0;
}
</style>
