/**
 * STEP 1. 주문접수, 고객인증
 */
export default [
	/**
	 * 주문접수(개인, 개인사업자)
	 * 확인 : 2022-05-16
	 */
	{
		path: 'verify',
		name: 'order-verify',
		meta: {
			isPublic: true,
			type: 'main',
			title: '모바일 주문'
		},
		component: () => import('@/views/verify/VerifyPage.vue')
	},
	{
		path: 'verify/safekeyerror',
		name: 'verify-safekeyerror',
		meta: {
			isPublic: true,
			type: 'main'
		},
		component: () => import('@/views/verify/VerifySafeKeyError.vue')
	},
	/**
	 * 접수전 고객인증 (개인, 개인사업자)
	 * 확인 : 2021-05-16
	 */
	{
		path: 'verify/cust/self',
		name: 'verify-cust-self',
		meta: {
			isPublic: true,
			type: 'main',
			hideAppBar: true,
			showWebHeader: false,
			title: '고객 인증'
		},
		component: () => import('@/views/verify/cust/CustVerifySelf.vue')
	},
	{
		path: 'verify/cust/expired',
		name: 'verify-cust-expired',
		meta: {
			isPublic: true,
			type: 'main',
			hideAppBar: true,
			showWebHeader: true
		},
		component: () => import('@/views/verify/cust/CustVerifyExpired.vue')
	},
	{
		path: 'verify/cust/deleted',
		name: 'verify-cust-deleted',
		meta: {
			isPublic: true,
			type: 'main',
			hideAppBar: true,
			showWebHeader: true
		},
		component: () => import('@/views/verify/cust/CustVerifyDeleted.vue')
	},
	/**
	 * 미납,연체 확인(개인사업자)
	 * 확인 : 2021-05-16
	 */
	// {
	// 	path: 'verify/cust/complete',
	// 	name: 'verify-cust-complete',
	// 	meta: {
	// 		isPublic: true,
	// 		type: 'main',
	// 		hideAppBar: true,
	// 		showWebHeader: true
	// 	},
	// 	component: () => import('@/views/verify/cust/CustVerifyComplete.vue')
	// },
	{
		path: 'verify/cust/uncomplete',
		name: 'verify-cust-uncomplete',
		meta: {
			isPublic: true,
			type: 'main',
			hideAppBar: true,
			showWebHeader: true
		},
		component: () => import('@/views/verify/cust/CustVerifyUnComplete.vue')
	},
	// {
	// 	path: 'verify/cust/adddoc',
	// 	name: 'verify-cust-adddoc',
	// 	meta: {
	// 		isPublic: true,
	// 		type: 'main',
	// 		hideAppBar: true,
	// 		showWebHeader: true
	// 	},
	// 	component: () => import('@/views/verify/cust/CustVerifyAddDoc.vue')
	// },
	/**
	 * 고입확 대표 결제수단 추가
	 * 확인 : 2021-05-16
	 */
	{
		path: 'cust/payadd',
		name: 'cust-payadd',
		meta: {
			isPublic: true,
			type: 'main',
			showWebHeader: true,
			title: '결제방법 선택'
		},
		component: () => import('@/views/verify/cust/PayAdd.vue')
	},
	/**
	 * 고입확 대표 수납결제수단 추가
	 * 2021-05-16
	 */
	// {
	// 	path: 'cust/oncepayadd',
	// 	name: 'cust-oncepayadd',
	// 	meta: {
	// 		isPublic: true,
	// 		type: 'main',
	// 		showWebHeader: true,
	// 		title: '일회성 결제방법 선택'
	// 	},
	// 	component: () => import('@/views/verify/cust/OncePayAdd.vue')
	// },
	{
		path: 'cust/setupadd',
		name: 'cust-setupadd',
		meta: {
			isPublic: true,
			type: 'main',
			showWebHeader: true,
			title: '설치처 정보 선택'
		},
		component: () => import('@/views/verify/cust/SetupAdd.vue')
	},
	{
		path: 'cust/paycomplete',
		name: 'cust-paycomplete',
		meta: {
			isPublic: true,
			type: 'main',
			showWebHeader: true,
			title: '주문 정보 입력 완료'
		},
		component: () => import('@/views/verify/cust/PayComplete.vue')
	},
	{
		path: 'cust/payuncomplete',
		name: 'cust-payuncomplete',
		meta: {
			isPublic: true,
			type: 'main',
			showWebHeader: true
		},
		component: () => import('@/views/verify/cust/PayUnComplete.vue')
	}
]
