<template>
	<v-dialog
		v-model="value"
		:persistent="disableOutsideCilck"
		@click:outside="
			disableOutsideCilck
				? () => {}
				: $emit($listeners.modifyVisible ? 'modifyVisible' : 'input', false)
		"
	>
		<v-card>
			<v-card-title v-if="dialogTitle" v-html="dialogTitle" />
			<v-card-text v-if="dialogText" v-html="dialogText" />
			<slot name="subText" />
			<v-card-actions>
				<AButton
					v-if="buttonText"
					:text="true"
					:title="buttonText"
					@click="buttonClick"
				/>
				<AButton
					v-if="subButtonText"
					:text="true"
					:title="subButtonText"
					@click="subButtonClick"
				/>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import AButton from '@/components/atoms/AButton'

export default {
	name: 'ADialog',
	props: {
		value: Boolean,
		dialogTitle: String,
		dialogText: String,
		buttonText: String,
		subButtonText: String,
		disableOutsideCilck: {
			type: Boolean,
			default: false
		}
	},
	components: {
		AButton
	},
	methods: {
		buttonClick() {
			this.$emit('buttonFunction')
		},
		subButtonClick() {
			this.$emit('subButtonFunction')
		}
	}
}
</script>
