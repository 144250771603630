<template>
	<v-container>
		<v-card flat>
			<v-card-title v-if="title">{{ title }}</v-card-title>
			<v-data-table
				:headers="headers"
				:items="items"
				:loading="loading"
				:show-select="showSelect"
				:fixed-header="true"
				:mobile-breakpoint="mobileBreakpoint"
				:height="height"
				:hide-default-header="hideHeaders"
				:hide-default-footer="true"
				:calculate-widths="true"
				:dense="dense"
				class="elevation-1"
				@update:options="handleUpdateOptions"
				@item-selected="handleItemSelected"
			>
				<template
					v-for="headerSlot in Object.keys($scopedSlots).filter(
						item => item.indexOf('header') === 0
					)"
					v-slot:[headerSlot]="{ header }"
				>
					<slot :name="headerSlot" v-bind:header="header" />
				</template>

				<template
					v-for="bodySlot in Object.keys($scopedSlots).filter(
						item => item.indexOf('item') === 0
					)"
					v-slot:[bodySlot]="{ item }"
				>
					<slot :name="bodySlot" v-bind:item="item" />
				</template>
			</v-data-table>
			<v-card-actions>
				<v-pagination
					v-model="currentPage"
					:length="totalPages"
					next-icon="mdi-menu-right"
					prev-icon="mdi-menu-left"
					@input="handlePageChange"
					circle
				/>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
export default {
	props: {
		title: String,
		headers: {
			type: Array,
			required: true
		},
		items: {
			type: Array,
			required: true
		},
		loading: Boolean,
		showSelect: {
			type: Boolean,
			default: false
		},
		mobileBreakpoint: {
			type: Number,
			default: 200
		},
		height: Number,
		dense: Boolean,
		page: {
			type: Number,
			required: true
		},
		totalPages: {
			type: Number,
			required: true
		},
		hideHeaders: Boolean
	},
	data() {
		return {
			currentPage: 1
		}
	},
	methods: {
		handlePageChange() {
			this.$emit('changePage', this.currentPage)
		},
		handleUpdateOptions(payload) {
			this.$emit('updateOptions', payload)
		},
		handleItemSelected(value) {
			this.$emit('itemSelected', value)
		}
	}
}
</script>
