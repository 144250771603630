import saleData from './ui-sale-datas'
import guideData from './ui-guide-datas'
import indemnifData from './ui-indemnify-datas'
import chairMultiFacData from './ui-chair-multifac-datas'

/**
 * UI Data 호출
 */
export default {
	saleData,
	guideData,

	/**
	 * 주문 유형 데이터 호출
	 */
	getSaleData: id => {
		if (saleData[id] !== undefined) {
			return saleData[id]
		} else {
			return { id: id }
		}
	},

	/**
	 * Guide 출력 메세지
	 */
	getGuideData: id => {
		if (guideData[id] !== undefined) {
			return guideData[id]
		} else {
			return { id: id }
		}
	},

	/**
	 * 타사보상 정수기, 공기청정기
	 */
	getIndemnifyData: id => {
		if (indemnifData[id] !== undefined) {
			return indemnifData[id]
		} else {
			return { id: id }
		}
	},

	/**
	 * 안마의자 설치처 정보
	 */
	getChairMultiFacData: id => {
		if (chairMultiFacData[id] !== undefined) {
			return chairMultiFacData[id]
		} else {
			return []
		}
	}
}
