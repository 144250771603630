<template>
	<!-- UIDEV_220224 -->
	<v-dialog fullscreen v-if="dialog" v-model="dialog">
		<div class="u-modal-wrap">
			<div class="full_dialog_header">
				<h2 class="tit">우편번호찾기</h2>
				<v-btn depressed icon plain class="btn_right" @click="close">
					<v-icon dark>mdi-window-close</v-icon>
				</v-btn>
			</div>

			<vue-daum-postcode :q="q" @complete="onComplete" />
		</div>
	</v-dialog>
	<!-- //UIDEV_220224 -->
</template>
<script>
import { VueDaumPostcode } from 'vue-daum-postcode'

export default {
	components: {
		VueDaumPostcode
	},
	props: {
		q: { type: String, default: '' }
	},
	data() {
		return {
			dialog: false,
			resolve: null,
			reject: null
		}
	},
	methods: {
		open() {
			this.dialog = true
			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},
		close() {
			this.dialog = false
		},
		onComplete(args) {
			this.$log(args)
			if (args) {
				this.resolve(args)
				this.dialog = false
			} else {
				this.close()
			}
		}
	}
}
</script>
