<template>
	<div>
		<v-bottom-sheet v-model="seen" scrollable :persistent="persistent">
			<template v-slot:activator="{ on, attrs }" v-if="!hideButton">
				<v-btn
					class="btn_select_type"
					height="48px"
					depressed
					outlined
					@click="open"
					v-bind="attrs"
					v-on="on"
				>
					<slot name="default" />
				</v-btn>
			</template>
			<h2 class="tit">{{ title }}</h2>
			<div class="scr_area">
				<p class="txt">
					※ CRP 상품의 기존주문으로 선택된 주문은 약정기간이 1년 추가됨을
					고객님께 안내바랍니다.
				</p>
				<v-btn-toggle
					class="btn_row_long"
					v-model="selected"
					:mandatory="mandatory"
				>
					<v-btn
						v-for="(item, i) in items"
						:key="`item-${i}`"
						@click="onClick(i)"
					>
						<div class="list_box_type_small_01">
							<div class="box">
								<h3 class="impact">
									{{ item.GOODS_NM }}
									<span class="tag">렌탈</span>
								</h3>
								<dl>
									<dt>주문번호</dt>
									<dd>{{ item.ORDER_NO }}</dd>
								</dl>
								<dl>
									<dt>설치일자</dt>
									<dd>{{ item.FIRST_INST_DT }}</dd>
								</dl>
								<dl>
									<dt>설치주소</dt>
									<dd v-html="item.INST_ADDRESS">
										04578 <br />
										서울 강남구 삼성동 롯데캐슬아파트 102동
										<br />137-2번지
									</dd>
								</dl>
							</div>
						</div>
					</v-btn>
				</v-btn-toggle>
			</div>
		</v-bottom-sheet>
	</div>
</template>

<script>
export default {
	props: {
		value: [String, Number],
		text: String,
		title: String,
		persistent: Boolean,
		hideButton: Boolean
	},
	computed: {
		displayText() {
			return this.text
		},
		selected: {
			get() {
				return this.items.findIndex(x => x.ORDER_NO === this.value)
			},
			set(value) {
				if (value !== undefined) {
					this.$emit('input', this.items[value].ORDER_NO)
				}
			}
		},
		mandatory() {
			return this.items.some(x => x.ORDER_NO === this.value)
		}
	},
	data() {
		return {
			seen: false,
			items: [
				{
					ORDER_NO: '20ZZZ2013944',
					FIRST_INST_DT: '2020.10.24',
					GOODS_NM: 'CPI-7510L',
					INST_ADDRESS: `04578 <br />
										서울 강남구 삼성동 롯데캐슬아파트 102동
										<br />137-2번지`
				},
				{
					ORDER_NO: '20ZZZ2011812',
					FIRST_INST_DT: '2020.09.26',
					GOODS_NM: 'CHP-7210N',
					INST_ADDRESS: `04578 <br />
										서울 강남구 삼성동 롯데캐슬아파트 102동
										<br />137-2번지`
				},
				{
					ORDER_NO: '20ZZZ2011800',
					FIRST_INST_DT: '2020.03.01',
					GOODS_NM: 'CP-6201N',
					INST_ADDRESS: `04578 <br />
										서울 강남구 삼성동 롯데캐슬아파트 102동
										<br />137-2번지`
				}
			]
		}
	},
	methods: {
		open() {
			this.seen = true
		},
		onClick(clickedValue) {
			this.close(clickedValue)
		},
		close(value) {
			if (value !== undefined) {
				const selectedIndex = value
				const selectedItem = this.items[selectedIndex]
				this.$emit('item-selected', selectedItem)
			}
			this.seen = false
		}
	}
}
</script>
