<template>
	<svg
		id="call"
		xmlns="http://www.w3.org/2000/svg"
		width="14.996"
		height="15"
		viewBox="0 0 14.996 15"
	>
		<g id="그룹_481" data-name="그룹 481">
			<path
				id="패스_1304"
				data-name="패스 1304"
				d="M11,9.39c-.214.038-.378.221-.817.744a6.261,6.261,0,0,1-.666.721,5.763,5.763,0,0,1-1.272-.471A7.874,7.874,0,0,1,4.613,6.749a5.763,5.763,0,0,1-.471-1.272,6.262,6.262,0,0,1,.721-.666c.523-.439.706-.6.744-.817.08-.457-1.66-3.2-2.051-3.67C3.392.13,3.243,0,3.056,0,2.5,0,0,3.092,0,3.492c0,.033.054,3.251,4.118,7.387C8.254,14.943,11.472,15,11.5,15c.4,0,3.492-2.5,3.492-3.053a.685.685,0,0,0-.325-.5l.005-.006C14.2,11.048,11.458,9.31,11,9.39Z"
				fill="#222"
			/>
		</g>
		<path
			id="패스_1305"
			data-name="패스 1305"
			d="M3.749,3.749A3.753,3.753,0,0,0,2.65,1.1,3.753,3.753,0,0,0,0,0V.937a2.815,2.815,0,0,1,1.988.824,2.815,2.815,0,0,1,.824,1.988h.937Z"
			transform="translate(8.436 2.812)"
			fill="#222"
		/>
		<path
			id="패스_1306"
			data-name="패스 1306"
			d="M6.092,6.093A6.1,6.1,0,0,0,4.306,1.787,6.1,6.1,0,0,0,0,0V.937A5.161,5.161,0,0,1,3.644,2.449,5.161,5.161,0,0,1,5.155,6.093h.937Z"
			transform="translate(8.904)"
			fill="#222"
		/>
	</svg>
</template>
<script>
export default {
	name: 'call-icon'
}
</script>
