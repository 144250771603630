<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16.905"
		height="12.463"
		viewBox="0 0 16.905 12.463"
	>
		<g id="그룹_482" data-name="그룹 482" transform="translate(-254 -142.933)">
			<g id="mail" transform="translate(254 142.933)">
				<g id="그룹_47" data-name="그룹 47" transform="translate(0 3.512)">
					<g id="그룹_46" data-name="그룹 46">
						<path
							id="패스_1309"
							data-name="패스 1309"
							d="M8.451,4.986a.982.982,0,0,1-.5-.137L0,0V6.458A2.485,2.485,0,0,0,2.493,8.951H14.412a2.485,2.485,0,0,0,2.493-2.493V0L8.951,4.849A.982.982,0,0,1,8.451,4.986Z"
							fill="#222"
						/>
					</g>
				</g>
				<g id="그룹_49" data-name="그룹 49" transform="translate(0.068)">
					<g id="그룹_48" data-name="그룹 48">
						<path
							id="패스_1310"
							data-name="패스 1310"
							d="M2.425,0A2.5,2.5,0,0,0,0,1.949L8.384,7.07l8.38-5.121A2.5,2.5,0,0,0,14.344,0H2.425Z"
							fill="#222"
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>
<script>
export default {
	name: 'mail-icon'
}
</script>
