<template>
	<keep-alive :max="MAX_COMPONENT_INSTANCES">
		<component
			:is="componentName"
			@replaceComponent="replaceComponent"
			:params="componentParams"
			:key="componentKey"
		/>
	</keep-alive>
</template>

<script>
import PreContractList from './preContract/PreContractList.vue'
import PreContractListDetail from './preContract/PreContractListDetail.vue'
import PreContractPost from './preContract/PreContractPost.vue'

export const MAX_COMPONENT_INSTANCES = 16

export default {
	name: 'PreContractMain',
	components: {
		PreContractList,
		PreContractListDetail,
		PreContractPost
	},

	data() {
		return {
			MAX_COMPONENT_INSTANCES,
			componentName: String,
			componentKey: String,
			componentParams: Object
		}
	},

	created() {
		this.$log(1)
		const name = this.$route.name.replace(/(^\w|-\w)/g, v =>
			v.replace(/-/, '').toUpperCase()
		)

		this.replaceComponent({ name: name })
	},

	methods: {
		replaceComponent({ name, params, id }) {
			this.componentName = name
			this.componentParams = params
			this.componentKey = this.componentName

			if (id) {
				this.componentKey += `@${id}`
			}

			window.scrollTo(0, 0)
		}
	}
}
</script>

<style scoped />
