<template>
	<v-checkbox
		v-model="currentValue"
		:disabled="disabled"
		hide-details
		:ripple="false"
		v-on="$listeners"
	>
		<template v-slot:label>
			<div v-html="label"></div>
		</template>
	</v-checkbox>
</template>

<script>
export default {
	name: 'ACheckbox',
	props: {
		label: {
			type: String,
			required: true
		},
		checked: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			currentValue: this.checked
		}
	},
	watch: {
		checked(checked) {
			this.currentValue = checked
		},
		currentValue(value) {
			this.$emit('checked', value)
		}
	},
	methods: {
		onChange(e) {
			this.$log('onChange:', e)
			this.$emit('change', e)
		}
	}
}
</script>
