<template>
	<div>
		<v-bottom-sheet v-model="seen" scrollable>
			<template v-slot:activator="{ attrs }" v-if="!hideButton">
				<v-btn
					class="btn_select_type"
					height="48px"
					depressed
					outlined
					@click="open"
					v-bind="attrs"
					><slot name="default"
				/></v-btn>
			</template>
			<h2 class="tit">프로모션 할인</h2>
			<div class="scr_area">
				<v-btn-toggle
					v-model="selected"
					class="btn_bottom_sheet under_bar"
					:mandatory="mandatory"
				>
					<v-btn v-for="(item, i) in items" :key="i">
						<template v-if="i === 0">
							<div class="wrap_result_sum fix_r_input">
								<dl class="result_sum">
									<dt class="tit_depth_1">
										{{ item.name }}<v-checkbox></v-checkbox>
									</dt>
								</dl>
							</div>
						</template>
						<template v-else>
							<div class="wrap_result_sum fix_r_input">
								<dl class="result_sum">
									<dt class="tit_depth_1">
										<template v-if="isAllatOnce">
											일시불 -{{ item.dcAmt | comma }}원
										</template>
										<template v-else>
											렌탈료 -{{ item.dcAmt | comma }}원
										</template>
										<v-checkbox></v-checkbox>
									</dt>
								</dl>
								<dl class="depth_2">
									<dt class="tit_depth_2">
										<span class="ico-bar_l"></span>
										{{ item.name }}
										<br />
										{{ item.desc }}
									</dt>
								</dl>
							</div>
						</template>
					</v-btn>
				</v-btn-toggle>
			</div>
			<v-row class="bottom_btn_col_2">
				<v-btn class="" depressed height="48" @click="close"> 닫기</v-btn>
				<v-btn
					class=""
					depressed
					color="primary"
					height="48"
					tile
					@click="handleApply"
					>프로모션 적용
				</v-btn>
			</v-row>
		</v-bottom-sheet>
	</div>
</template>

<script>
import filters from '@/mixins/filters'
export default {
	mixins: [filters],
	props: {
		value: [String, Number],
		text: String,
		title: String,
		persistent: Boolean,
		hideButton: Boolean,
		promotions: Array,
		isAllatOnce: Boolean
	},
	computed: {
		/*		selected: {
			get() {
				return this.items.findIndex(x => x.code === this.value)
			},
			set(value) {
				if (value !== undefined) {
					//this.$emit('input', this.items[value].code)
				}
			}
		},*/
		mandatory() {
			return this.items.some(x => x.code === this.value)
		}
	},
	data() {
		return {
			toggle_exclusive: 1,
			seen: false,
			selected: [],
			items: [
				{ code: 'pm1', regPriceDc: 100000, rentalDc: -2000 },
				{ code: 'pm2', regPriceDc: 0, rentalDc: -11600 },
				{ code: 'pm3', regPriceDc: 0, rentalDc: -1636 }
			],
			existingValue: ''
		}
	},
	methods: {
		open() {
			this.$log('promotion prop value:', this.value)
			this.$log('promotion prop:', this.promotions)
			this.$log('isAllatOnce prop:', this.isAllatOnce)
			this.existingValue = this.value
			if (this.value === 'A067') return // 미반납 재렌탈 프로모션 적용되었을 경우 팝업차단
			const pms = this.promotions.map(p => {
				return {
					code: p.PRM_CODE,
					text: p.PRMNM,
					desc: p.RMARK,
					name: p.PRMNM,
					dcAmt: this.isAllatOnce ? +p.LUMP_DC_AMT : +p.PRM_DC_AMT,
					...p
				}
			})
			this.items = [
				{
					code: '',
					text: '프로모션 선택 없음',
					desc: '',
					name: '프로모션 선택 없음'
				},
				...pms
			]

			// 적용되어있는 프로모션으로 선택
			this.items.forEach((v, i) => {
				if (v.code === this.value) {
					this.selected = i
				}
			})
			this.seen = true
		},

		close() {
			this.$emit('input', this.existingValue)
			this.seen = false
		},

		handleApply() {
			const selectedItem = this.items[this.selected === -1 ? 0 : this.selected]
			this.$log(
				'promotion bottom selected:',
				selectedItem,
				this.selected,
				selectedItem.code
			)
			this.$emit('item-selected', selectedItem)
			this.seen = false
		}
	}
}
</script>

<style></style>
