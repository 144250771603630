<template>
	<v-breadcrumbs v-if="seen" :items="breadcrumbs" class="py-2 px-4">
		<template v-slot:item="{ item }">
			<v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
				{{ item.text.toUpperCase() }}
			</v-breadcrumbs-item>
		</template>
	</v-breadcrumbs>
</template>

<script>
import { mapState } from 'vuex'

export default {
	data() {
		return {
			seen: {
				type: Boolean,
				default: true
			}
		}
	},
	computed: {
		...mapState(['route']),
		breadcrumbs() {
			// 메시지 키값으로 조회한 항목을 breadcrumbs에 셋팅
			const pathes = this.$t(`menu.${this.route.name}`)
			const newBreadcrumbs = []

			if (pathes !== '') {
				const pathArr = pathes.split('/').filter(item => {
					return item && item.length > 0
				})
				pathArr.forEach(myPath => {
					newBreadcrumbs.push({
						text: myPath
					})
				})
			}
			return newBreadcrumbs
		}
	},
	watch: {
		breadcrumbs(value) {
			if (value.length === 0) this.seen = false
		}
	}
}
</script>
