<template>
	<v-form ref="bankForm" class="form px-6" @submit.prevent="onSubmit">
		<div class="wrap_input">
			<h3 class="label">은행</h3>
			<BankCompanyBottom
				class="btn_all"
				title="은행 선택"
				v-model="form.bankCd"
				@item-selected="handleBankCompanySelected"
				:paymentType="paymentType"
				:paymentGroup="paymentGroup"
				:instantTransfer="instantTransfer"
				:div="div"
				:disabledButton="disabledPayInfo"
			>
				<template>{{ form.bankNm }}<span class="btn_value"></span></template>
			</BankCompanyBottom>
		</div>
		<div class="wrap_input">
			<h3 class="label">계좌번호</h3>
			<v-text-field
				:disabled="disabledPayInfo"
				v-model="form.accountNo"
				outlined
				placeholder="- 없이 입력"
				dense
				:rules="rules.accountNo"
				inputmode="numeric"
				v-mask="['###########', '#########################']"
				maxlength="25"
			/>
		</div>

		<div class="wrap_input" v-if="!hidePaymentDate">
			<article class="u-select-block expand">
				<div class="type-button">
					<h3 class="label">이체일</h3>
					<v-btn-toggle
						v-model="form.dd"
						color="primary"
						tile
						class="flex-start"
					>
						<v-btn
							:value="Number(day.CD)"
							v-for="(day, i) in payDd"
							:key="i"
							text
						>
							{{ day.CD_NM }}
						</v-btn>
					</v-btn-toggle>
				</div>
			</article>
		</div>

		<div class="wrap_input" v-if="!hideCustType">
			<article class="u-select-block expand">
				<div class="type-button">
					<h3 class="label">명의</h3>
					<v-btn-toggle
						v-model="form.custType"
						color="primary"
						tile
						class="flex-start"
					>
						<v-btn :disabled="disabledPayInfo" :value="1" text> 개인 </v-btn>
						<v-btn
							:disabled="disabledPayInfo"
							:value="2"
							text
							class="ml-2"
							v-if="custType === 'L'"
						>
							개인사업자
						</v-btn>
					</v-btn-toggle>
				</div>
			</article>
		</div>
		<div class="wrap_input">
			<template v-if="hideCustType">
				<h3 class="label">예금주</h3>
			</template>
			<template v-else>
				<h3 class="label">명의자</h3>
			</template>
			<v-text-field
				:disabled="disabledCust"
				v-model="form.custName"
				outlined
				:placeholder="form.custName"
				dense
				:rules="rules.required"
				inputmode="text"
				:maxlength="$Constants.MAX_CUST_NAME_LENGTH"
			/>
		</div>

		<div class="wrap_input">
			<template v-if="custType === 'L'">
				<h3 class="label">법정생년월일/사업자번호</h3>
				<v-text-field
					:disabled="disabledCust"
					v-model="form.ssn"
					outlined
					placeholder=""
					dense
					:rules="rules.ssn"
					inputmode="numeric"
					maxlength="10"
					v-mask="['######', '##########']"
				/>
			</template>
			<template v-else>
				<h3 class="label">법정생년월일</h3>
				<v-text-field
					:disabled="true"
					v-model="form.ssn"
					outlined
					placeholder=""
					dense
					:rules="rules.ssn"
					inputmode="numeric"
					maxlength="6"
					v-mask="['######']"
				/>
			</template>
		</div>
	</v-form>
</template>
<script>
/**
 * 결제수단 추가 - 은행이체 추가
 */
import { required, maxLength, minLength, numeric } from '@/utils/rules'

export default {
	name: 'BankPayMethodForm',
	props: {
		hidePaymentDate: Boolean,
		hideCustType: Boolean,
		hideBizNo: Boolean,
		instantTransfer: Boolean,
		div: String,
		disabledCustName: {
			type: Boolean,
			default: true
		},
		payDd: Array,
		paymentType: { String, default: '' },
		paymentGroup: { Number, default: -1 }, // 개별, 공통 그룹
		payInfo: { Object, default: {} }
	},
	watch: {
		'form.custType': {
			handler(val) {
				this.$nextTick(() => {
					if (val === 2) {
						this.$set(this.form, 'ssn', this.ssnBiz)
						this.$set(this.form, 'custName', this.custNameBiz)
						this.disabledCust = true
					} else {
						this.$set(this.form, 'ssn', this.ssn)
						this.$set(this.form, 'custName', this.custName)
						this.disabledCust = true
					}
				})
			}
		}
	},
	data() {
		return {
			disabledCust: false,
			disabledPayInfo: false,
			form: {
				accountNo: '',
				dd: Number(this.payDd[0].CD),
				custType: 1,
				custName: '고객명',
				dialog: false,
				bankCd: '',
				ssn: '999999',
				bankNm: '은행입력',
				regerNo: this.$store.getters['common/codyNo'] //코디번호 또는 고객번호
			},
			rules: {
				name: [v => required(v), v => minLength(v, 2), v => maxLength(v, 10)],
				ssn: [
					v => required(v),
					v => numeric(v),
					v => maxLength(v, 10),
					v => minLength(v, 6)
				],
				accountNo: [
					v => required(v),
					v => numeric(v),
					v => minLength(v, 10),
					v => maxLength(v, 25)
				],
				required: [v => required(v)]
			},
			custType: '',
			ssn: '',
			ssnBiz: '',
			custName: '',
			custNameBiz: ''
		}
	},
	mounted() {
		this.pageInit()
	},
	methods: {
		handleBankCompanySelected(item) {
			if (item.cd) {
				this.form.bankNm = item.cdNm
			} else {
				this.form.bankNm = item.cdNm
			}
		},
		onSubmit() {
			this.$log('onSubmit', this.form)
			if (!this.validate()) return
			this.$log('validated', this.form)
			return this.form
		},
		validRest() {
			this.form = {
				accountNo: '',
				dd: Number(this.payDd[0].CD),
				custType: 1,
				custName: '고객명',
				dialog: false,
				bankCd: '',
				ssn: '999999',
				bankNm: '은행입력',
				regerNo: this.$store.getters['common/codyNo'] //코디번호 또는 고객번호
			}
			this.pageInit()
			this.$refs.bankForm.resetValidation()
		},
		validate() {
			if (!this.$refs.bankForm.validate()) return false
			if (!this.form.bankCd) {
				this.$alert({ message: '은행을 선택하세요' })
				return false
			}
			if (!this.form.dd) {
				this.$alert({ message: '이체일을 선택하세요' })
				return false
			}
			if (!this.form.custType) {
				this.$alert({ message: '명의를 선택하세요' })
				return false
			}
			return true
		},
		pageInit() {
			this.$nextTick(() => {
				// [BEGIN] 수정 시 초기화 안되어, nextTick안에 set으로 초기화
				// 참고 : validRest() 함수로도 초기화가 안됨.
				this.$set(this.form, 'accountNo', '')
				this.$set(this.form, 'bankCd', '')
				this.$set(this.form, 'bankNm', '은행입력')
				this.$set(this.form, 'custType', 1)
				// [END] 수정 시 초기화 안되어, nextTick안에 set으로 초기화

				this.disabledCust = this.disabledCustName
				let ssn = this.$store.getters['verify/ssn']
				this.custType = this.$store.getters['verify/customerType']
				this.$set(this.form, 'custType', this.custType === 'L' ? 2 : 1)
				if (ssn) {
					this.ssn = ssn.substr(2, ssn.length)
					this.custName = this.$store.getters['verify/custName']
					this.$set(this.form, 'dd', Number(this.payDd[0].CD))
					this.$set(this.form, 'ssn', this.ssn)
					this.$set(this.form, 'custName', this.custName)
					if (Object.keys(this.payInfo).length > 0) {
						this.disabledPayInfo = true
						this.$set(this.form, 'accountNo', this.payInfo.AF_CARD_NO)
						this.$set(this.form, 'bankCd', this.payInfo.AF_BANK_CD)
						this.$set(this.form, 'bankNm', this.payInfo.AF_BANK_CD_NM)
						this.$set(this.form, 'custType', this.payInfo.KATR2)
						this.custName = this.payInfo.AF_OWNER_NM
						this.ssn = this.payInfo.AF_SSN_NO
						this.$set(this.form, 'ssn', this.ssn)
						this.$set(this.form, 'custName', this.custName)
					} else {
						this.disabledPayInfo = false
					}

					if (this.custType === 'L') {
						this.ssnBiz = this.$store.getters['verify/bizNo']
						this.custNameBiz = this.$store.getters['verify/bizName']
						this.$set(this.form, 'ssn', this.ssnBiz)
						this.$set(this.form, 'custName', this.custNameBiz)
					}
				}
			})
		}
	}
}
</script>
