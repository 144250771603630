<template>
	<div class="btn-full-cover">
		<v-bottom-sheet v-model="seen" scrollable :persistent="persistent">
			<template v-slot:activator="{ attrs }" v-if="!hideButton">
				<v-btn
					class="btn_select_type"
					height="48px"
					depressed
					outlined
					@click="open"
					v-bind="attrs"
					:disabled="disabled"
				>
					<slot name="default" />
				</v-btn>
			</template>
			<h2 class="tit">{{ title }}</h2>
			<div class="scr_area">
				<v-btn-toggle
					class="toggle_row expand"
					:mandatory="mandatory"
					v-model="pay"
				>
					<div class="flex_row" v-for="(item, i) in items" :key="item + i">
						<v-btn :ripple="false" class="btn_white mb-2" @click="onClick(i)">
							{{ item.text }}
						</v-btn>
					</div>
				</v-btn-toggle>
			</div>
		</v-bottom-sheet>
	</div>
</template>

<script>
export default {
	props: {
		value: [String, Number],
		items: {
			type: Array,
			default: () => {
				return []
			}
		},
		text: String,
		title: String,
		persistent: Boolean,
		hideButton: Boolean,
		disabled: { type: Boolean, default: false },
		array: { Boolean, default: false },
		index: { Number, default: -1 },
		chairMultiFacSelected: Object
	},
	computed: {
		displayText() {
			return this.text
		},
		mandatory() {
			return this.items.some(x => x.code === this.value)
		}
	},
	data() {
		return {
			seen: false,
			selectedItem: -1,
			pay: -1
		}
	},
	methods: {
		open() {
			this.pay = -1
			this.seen = true
			if (this.chairMultiFacSelected) {
				this.items.forEach((v, i) => {
					if (
						v.code === this.chairMultiFacSelected.code &&
						v.codeGroup === this.chairMultiFacSelected.codeGroup
					) {
						this.pay = i
						this.selectedItem = v
					}
				})
			}
		},
		onClick(e) {
			this.selectedItem = this.items[e]
			this.$emit('input', this.selectedItem.code)
			if (this.array !== false) {
				this.$emit('item-list-selected', this.selectedItem, this.array)
			} else {
				this.$emit('item-selected', this.selectedItem)
			}
			this.seen = false
		}
	}
}
</script>
