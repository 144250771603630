<template>
	<div>
		<v-bottom-sheet v-model="seen" scrollable :persistent="persistent">
			<template v-slot:activator="{ attrs }" v-if="!hideButton">
				<v-btn
					class="btn_select_type"
					height="48px"
					depressed
					outlined
					@click="open"
					v-bind="attrs"
					:disabled="disabled"
				>
					<slot name="default" />
				</v-btn>
			</template>
			<h2 class="tit">{{ title }}</h2>
			<div class="scr_area">
				<v-btn-toggle class="btn_bottom_sheet" v-model="selected">
					<v-btn :ripple="false" @click="onClick(0)">
						멤버십 선납 선택 없음
					</v-btn>
					<v-btn :ripple="false" @click="onClick(1)">
						1년 선납
					</v-btn>

					<div class="u-pop-message">
						<ul>
							<li>
								<p class="info">
									멤버십 금액을 선납하는 경우, 할인 적용 됩니다.
									<br />멤버십금액 : {{ (items[1].monthAmt * 12) | comma }}원
									({{ items[1].monthAmt | comma }}원 x 12개월 )
								</p>
							</li>
							<li>
								<p class="discout">
									할인금액 : -{{
										(items[1].monthAmt * 12 - items[1].preAmt) | comma
									}}원
								</p>
							</li>
							<li>
								<p class="prepay">선납금액 : {{ items[1].preAmt | comma }}원</p>
							</li>
						</ul>
					</div>
				</v-btn-toggle>
			</div>
		</v-bottom-sheet>
	</div>
</template>

<script>
import filters from '@/mixins/filters'
export default {
	mixins: [filters],
	props: {
		value: [String, Number],
		text: String,
		title: String,
		persistent: Boolean,
		hideButton: Boolean,
		memAmts: {
			type: Object,
			default: function() {
				return {}
			}
		},
		membership: String,
		disabled: { type: Boolean, default: false },
		selectedMemberShipDiscountItem: Object
	},
	computed: {
		displayText() {
			return this.text
		},

		mandatory() {
			return this.items.some(x => x.code === this.value)
		}
	},
	data() {
		return {
			seen: false,
			items: [
				{
					code: '00',
					text: '멤버십 선납 할인 선택 없음',
					preAmt: 0,
					monthAmt: 0
				},
				{
					code: '01',
					text: '1년',
					desc: '1년치 멤버십 금액을 선납하고, 매월 결제금액을 할인합니다.',
					name: '1년',
					preAmt: +this.memAmts.BIZ1_PRE_AMT, // 1년선납비용
					monthAmt: +this.memAmts.MON_AMT, // 월회비
					biz1MonthAmt: +this.memAmts.BIZ1_MON_AMT, // 1년선납월회비
					amtDc: this.memAmts.MON_AMT * 12 - this.memAmts.BIZ1_PRE_AMT, // 월회비 * 12 - 1년선납막달회비
					goalAmt: this.memAmts.GOAL_AMT2, // 목표금액
					preMonth: this.memAmts.PRE_MONTH // 선납개월수
				}
			],
			selected: -1,
			selectedItem: {}
		}
	},
	methods: {
		open() {
			this.selected = -1
			this.selectedItem = {}
			this.seen = true

			this.items = [
				{
					code: '00',
					text: "멤버십 선납 할인 선택 없음'",
					preAmt: 0,
					monthAmt: 0
				}
			]
			this.items = [
				...this.items,
				{
					code: '01',
					text: '1년',
					desc: '1년치 멤버십 금액을 선납하고, 매월 결제금액을 할인합니다.',
					name: '1년',
					preAmt: +this.memAmts.BIZ1_PRE_AMT, // 1년선납비용
					monthAmt: +this.memAmts.MON_AMT, // 월회비
					biz1MonthAmt: +this.memAmts.BIZ1_MON_AMT, // 1년선납월회비
					amtDc: this.memAmts.MON_AMT * 12 - this.memAmts.BIZ1_PRE_AMT, // 월회비 * 12 - 1년선납막달회비
					goalAmt: this.memAmts.GOAL_AMT2, // 목표금액
					preMonth: this.memAmts.PRE_MONTH // 선납개월수
				}
			]
			if (this.selectedMemberShipDiscountItem) {
				this.items.forEach((v, i) => {
					if (v.code === this.selectedMemberShipDiscountItem.code) {
						this.selected = i
						this.selectedItem = v
					}
				})
			}

			this.$log('items:', this.items)
		},
		onClick(clickedValue) {
			this.close(clickedValue)
		},
		close(value) {
			if (value !== undefined) {
				const selectedIndex = value
				this.selectedItem = this.items[selectedIndex]
				this.$emit('item-selected', this.selectedItem)
			}
			this.seen = false
		}
	}
}
</script>
