var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"flat":""}},[(_vm.title)?_c('v-card-title',[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('v-data-table',{ref:"table",staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"show-select":_vm.showSelect,"fixed-header":true,"mobile-breakpoint":_vm.mobileBreakpoint,"height":_vm.height,"hide-default-header":_vm.hideHeaders,"hide-default-footer":true,"calculate-widths":true,"dense":_vm.dense,"disable-pagination":""},on:{"update:options":_vm.handleUpdateOptions},scopedSlots:_vm._u([_vm._l((Object.keys(_vm.$scopedSlots).filter(
					function (item) { return item.indexOf('header') === 0; }
				)),function(headerSlot){return {key:headerSlot,fn:function(ref){
				var header = ref.header;
return [_vm._t(headerSlot,null,{"header":header})]}}}),_vm._l((Object.keys(_vm.$scopedSlots).filter(
					function (item) { return item.indexOf('item') === 0; }
				)),function(bodySlot){return {key:bodySlot,fn:function(ref){
				var item = ref.item;
return [_vm._t(bodySlot,null,{"item":item})]}}})],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }