<template>
	<v-row class="fill-height">
		<v-col>
			<v-sheet height="64">
				<v-toolbar flat>
					<AButton
						:fab="true"
						:text="true"
						:small="true"
						icon="mdi-chevron-left"
						color="grey darken-2"
						@click="prev"
					/>
					<v-toolbar-title>{{ currentDate }}</v-toolbar-title>
					<AButton
						:fab="true"
						:text="true"
						:small="true"
						icon="mdi-chevron-right"
						color="grey darken-2"
						@click="next"
					/>
					<AButton
						:outlined="true"
						color="grey darken-2"
						:title="$t('common.word.today')"
						@click="setToday"
					/>
				</v-toolbar>
			</v-sheet>
			<v-sheet height="600">
				<v-calendar
					v-model="calendarDate"
					ref="calendar"
					color="primary"
					:events="events"
					:event-color="getEventColor"
					:type="type"
					:day-format="date => new Date(date.date).getDate()"
					:show-month-on-first="false"
					@click:event="clickEvent"
					@click:more="viewDay"
					@change="updateRange"
				/>
			</v-sheet>
		</v-col>
	</v-row>
</template>

<script>
import moment from 'moment'
import AButton from '@/components/atoms/AButton'

export default {
	data: () => ({
		calendarDate: moment(new Date()).format('YYYY-MM-DD'),
		type: 'month',
		events: [],
		colors: [
			'blue',
			'indigo',
			'deep-purple',
			'cyan',
			'green',
			'orange',
			'grey darken-1'
		],
		names: [
			'Meeting',
			'Holiday',
			'PTO',
			'Travel',
			'Event',
			'Birthday',
			'Conference',
			'Party'
		]
	}),
	components: {
		AButton
	},
	computed: {
		currentDate() {
			return (
				this.calendarDate.split('-')[0] + '-' + this.calendarDate.split('-')[1]
			)
		}
	},
	mounted() {
		this.$refs.calendar.checkChange()
	},
	methods: {
		viewDay({ date }) {
			this.calendarDate = date
			this.type = 'day'
		},
		getEventColor(event) {
			return event.color
		},
		setToday() {
			this.calendarDate = moment(new Date()).format('YYYY-MM-DD')
		},
		prev() {
			this.$refs.calendar.prev()
		},
		next() {
			this.$refs.calendar.next()
		},
		clickEvent({ nativeEvent, event }) {
			this.$log(nativeEvent, ' , ', event)
		},
		updateRange({ start, end }) {
			const events = []
			const min = new Date(`${start.date}T00:00:00`)
			const max = new Date(`${end.date}T23:59:59`)
			const days = (max.getTime() - min.getTime()) / 86400000
			const eventCount = this.rnd(days, days + 20)

			for (let i = 0; i < eventCount; i++) {
				const allDay = this.rnd(0, 3) === 0
				const firstTimestamp = this.rnd(min.getTime(), max.getTime())
				const first = new Date(firstTimestamp - (firstTimestamp % 900000))
				const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
				const second = new Date(first.getTime() + secondTimestamp)

				events.push({
					name: this.names[this.rnd(0, this.names.length - 1)],
					start: first,
					end: second,
					color: this.colors[this.rnd(0, this.colors.length - 1)],
					timed: !allDay
				})
			}

			this.events = events
		},
		rnd(a, b) {
			return Math.floor((b - a + 1) * Math.random()) + a
		}
	}
}
</script>
