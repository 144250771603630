import log from '@/utils/log'
const state = {
	categories: [
		{ name: '정수기', code: 399 },
		{ name: '공기청정기', code: 400 },
		{ name: '비데', code: 401 },
		{ name: '연수기', code: 402 },
		{ name: '매트리스', code: 403 },
		{ name: '프레임', code: 404 },
		{ name: '주방가전', code: 406 },
		{ name: '건강가전', code: 407 },
		{ name: '리빙가전', code: 409 },
		{ name: 'MD Collection', code: 562 }
	],
	appendedPayServices: [
		{ name: '부가서비스', code: '01' },
		{ name: '맴버십', code: '02' },
		{ name: '별매상품', code: '03' },
		{ name: '선납금', code: '04' },
		{ name: '추가구매상품', code: '05' },
		{ name: '등록비분납', code: '06' },
		{ name: '반납위약금', code: '07' }
	],
	discountedPayServices: [
		{ name: 'pre-pass', code: '01' },
		{ name: '프로모션할인', code: '02' },
		{ name: '선납할인', code: '03' },
		{ name: '패키지할인', code: '04' },
		{ name: '멤버십할인', code: '05' },
		{ name: '등록비분납', code: '06' },
		{ name: '정기결제할인', code: '07' }
	],
	sellTypes: [
		{ code: 'Z001', text: '렌탈' },
		{ code: 'Z002', text: '재렌탈' },
		{ code: 'Z003', text: '일시불' },
		{ code: 'Z004', text: '자체할부' },
		{ code: 'Z005', text: '일시불 보상판매' }
	],
	// recpAmt: [],
	recpAmt: 0,
	paymentOrders: [],
	testMode: false,
	monthAmtDc: 0,
	payProducts: [],
	IT_PACKCOMBI: [],
	combiExistingPrd: [], // 기존 결합된 상품
	combiExistingApplyPrd: [], // 기존 미결합된 상품
	combiApplyPrd: [], // 신규 상품(동시, 결합)
	productSaleInfo: [], // 자동할인 응답 데이터
	packagePrd: [] // 동시구매 요금제 상품
}

const getters = {
	payProducts: state => state.payProducts,
	itPackcombi: state => state.IT_PACKCOMBI,

	sellTypeCodes: state => {
		return state.sellTypes.map(selltype => selltype.code)
	},
	paymentOrders: state => {
		return state.paymentOrders
	},
	// recpAmt: (state, rootState, currentCustNo) => {
	// 	const custNo = currentCustNo ?? rootState.verify.customer.custNo
	// 	const data = state.recpAmt.find(x => x.custNo === custNo)
	// 	if (!data) return 0
	// 	return data.amt
	// },
	recpAmt: state => {
		return state.recpAmt
	},
	monthAmtDc: state => {
		return state.monthAmtDc
	},
	getCombiExistingPrd: state => state.combiExistingPrd,
	getCombiExistingApplyPrd: state => state.combiExistingApplyPrd,
	getCombiApplyPrd: state => state.combiApplyPrd,
	getProductSaleInfo: state => state.productSaleInfo,
	getPackagePrd: state => state.packagePrd
}

const actions = {
	async savePayPrd({ commit }, payload) {
		commit('savePayPrd', payload)
	},
	async delPrd({ commit }, payload) {
		commit('delPrd', payload)
	},
	delPayOid2({ commit }, payload) {
		commit('delPayOid2', payload)
	},
	async delAllPrd({ commit }) {
		commit('delAllPrd')
	},
	async savePayPrdOptions({ commit }, payload) {
		commit('savePayPrdOptions', payload)
	},
	async saveRecpAmt({ commit, rootState }, amt) {
		commit('saveRecpAmt', { custNo: rootState.verify.customer.custNo, amt })
	},
	async savePaymentForOrder({ commit }, payload) {
		commit('savePaymentForOrder', payload)
	},
	async saveMonthAmtDc({ commit }, payload) {
		commit('saveMonthAmtDc', payload)
	},
	// 결합 적용 상품 추가
	async saveApplyPrd({ commit }, payload) {
		commit('saveApplyPrd', payload)
	},
	async saveApplyPrdAll({ commit }, payload) {
		commit('saveApplyPrdAll', payload)
	},
	async saveCombiExistingApplyPrd({ commit }, payload) {
		commit('saveCombiExistingApplyPrd', payload)
	},

	// 동시구매 (패키지 정보 저장)
	async savePackagePrd({ commit }, payload) {
		commit('savePackagePrd', payload)
	},

	// 결합 적용 상품 삭제
	async delApplyPrd({ commit }, payload) {
		commit('delApplyPrd', payload)
	},
	// 결합 미적용 상품 추가
	async saveNotApplyPrd({ commit }, payload) {
		commit('saveNotApplyPrd', payload)
	},
	async saveNotApplyPrdAll({ commit }, payload) {
		commit('saveNotApplyPrdAll', payload)
	},

	// 결합 미적용 상품 삭제
	async delNotApplyPrd({ commit }, payload) {
		commit('delNotApplyPrd', payload)
	},
	// 결합된 이전 상품 리스트 추가
	async saveCombiExistingPrd({ commit }, payload) {
		commit('saveCombiExistingPrd', payload)
	},
	async saveCombiExistingPrdAll({ commit }, payload) {
		commit('saveCombiExistingPrdAll', payload)
	},
	async saveItPackcombi({ commit }, payload) {
		commit('saveItPackcombi', payload)
	},
	// 할인적용 데이터 추가 (POLICY_SETTING)
	async saveProductSaleInfo({ commit }, payload) {
		commit('saveProductSaleInfo', payload)
	},
	// 할인적용 데이터 초기화
	async delProductSaleInfo({ commit }) {
		commit('delProductSaleInfo')
	}
}

const mutations = {
	savePayPrd(state, payload) {
		state.payProducts = [...payload]
	},
	delPrd(state, removableIndex) {
		const items = state.payProducts
		state.payProducts = [
			...items.slice(0, removableIndex),
			...items.slice(removableIndex + 1)
		]
	},
	delPayOid2(state, payload) {
		state.payProducts.forEach((v, i) => {
			if (v.oid2 === payload) {
				// 삭제
				state.payProducts.splice(i, 1)
			}
		})
	},
	delAllPrd(state) {
		state.payProducts = []
	},
	savePayPrdOptions(state, payload) {
		const { orderIndex, payOptions } = payload
		let product = state.payProducts[orderIndex]
		product.payOptions = { ...payOptions }
		state.payProducts[orderIndex] = product
	},
	// saveRecpAmt(state, payload) {
	// 	const { custNo, amt } = payload
	// 	log('custNo', custNo)
	// 	const index = state.recpAmt.findIndex(x => x.custNo === custNo)
	// 	state.recpAmt = [
	// 		...state.recpAmt.slice(0, index),
	// 		{ ...state.recpAmt[index], custNo, amt },
	// 		...state.recpAmt.slice(index + 1)
	// 	]
	// },
	saveRecpAmt(state, payload) {
		const { custNo, amt } = payload
		log('custNo', custNo)
		// const index = state.recpAmt.findIndex(x => x.custNo === custNo)
		state.recpAmt = amt
	},
	savePaymentForOrder(state, payload) {
		state.paymentOrders = [...payload]
	},
	savePaymentOptions(state, payload) {
		state.payProducts = [...payload]
	},
	setTestMode(state, payload) {
		state.testMode = payload
	},
	saveMonthAmtDc(state, payload) {
		state.monthAmtDc = payload
	},
	// 결합 적용 상품 추가
	saveApplyPrd(state, payload) {
		state.combiApplyPrd.push(...payload)
	},

	saveApplyPrdAll(state, payload) {
		state.combiApplyPrd = payload
	},

	saveCombiExistingApplyPrd(state, payload) {
		state.combiExistingApplyPrd = payload
	},

	// 동시구매(패키지 정보 저장)
	savePackagePrd(state, payload) {
		state.packagePrd = payload
	},

	// 결합 적용 상품 삭제
	delApplyPrd(state, removableIndex) {
		const items = state.combiApplyPrd
		state.combiApplyPrd = [
			...items.slice(0, removableIndex),
			...items.slice(removableIndex + 1)
		]
	},
	// 결합 미적용 상품 추가
	saveNotApplyPrd(state, payload) {
		state.combiNotApplyPrd.push({ ...payload })
	},

	saveNotApplyPrdAll(state, payload) {
		state.combiNotApplyPrd = payload
	},

	// 결합된 이전 상품 리스트 추가
	saveCombiExistingPrd(state, payload) {
		state.combiExistingPrd = [...payload]
	},

	saveCombiExistingPrdAll(state, payload) {
		state.combiExistingPrd = [...payload]
	},

	// 결합 미적용 상품 삭제
	delNotApplyPrd(state, removableIndex) {
		const items = state.combiNotApplyPrd
		state.combiNotApplyPrd = [
			...items.slice(0, removableIndex),
			...items.slice(removableIndex + 1)
		]
	},
	saveItPackcombi(state, payload) {
		state.IT_PACKCOMBI = payload
	},

	saveProductSaleInfo(state, payload) {
		state.productSaleInfo = payload
	},
	delProductSaleInfo(state) {
		state.productSaleInfo = []
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
