export default [
	{
		path: '/esign',
		name: 'esign',
		meta: {
			isPublic: true
		},
		component: () => import('@/views/EsignPage.vue'),
		children: [
			{
				path: 'download',
				name: 'esign-download',
				meta: {
					isPublic: true,
					type: 'main',
					hideAppBar: true,
					showWebHeader: true
				},
				component: () => import('@/views/esign/Download.vue')
			},
			{
				path: 'download/list',
				name: 'esign-download-list',
				meta: {
					isPublic: true,
					type: 'main',
					hideAppBar: true,
					showWebHeader: true
				},
				component: () => import('@/views/esign/DownloadList.vue')
			},
			{
				path: 'expire',
				name: 'esign-expire',
				meta: {
					isPublic: true,
					type: 'main',
					hideAppBar: true,
					showWebHeader: true
				},
				component: () => import('@/views/esign/Expire.vue')
			}
		]
	}
]
