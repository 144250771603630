<template>
	<v-dialog v-model="dialog" max-width="312" scrollable>
		<!--contents-->
		<section class="u-confirm-wrap">
			<article class="contents no-title">
				<v-card-title>
					<h2 class="title">
						{{ content }}
					</h2>
				</v-card-title>
				<v-text-field
					dense
					outlined
					v-model="message"
					class="mt-20"
					inputmode="text"
					maxlength="20"
					v-mask="'XXXXXXXXXXXXXXXXXXXX'"
					:placeholder="`${placeholderText}`"
				/>
			</article>

			<article class="bottom">
				<v-card-actions>
					<div class="col">
						<v-btn class="btn-cancel" @click="cancel()">취소</v-btn>
					</div>
					<div class="col">
						<v-btn :disabled="disabledSaveBtn" class="btn-confirm" @click="ok()"
							>확인</v-btn
						>
					</div>
				</v-card-actions>
			</article>
		</section>
		<!--//contents-->
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			dialog: false,
			content: '',
			placeholderText: '',
			message: undefined
		}
	},
	computed: {
		disabledSaveBtn() {
			return this.message === undefined || this.message.length === 0
		}
	},
	methods: {
		async open(flag) {
			this.message = undefined
			this.content =
				flag === 'D'
					? '자동입력 추천판매인 삭제 사유를 입력해주세요.(최대 20자)'
					: '자동입력 추천판매인 변경 사유를 입력해주세요.(최대 20자)'

			this.placeholderText =
				flag === 'D' ? '예: 고객 요청에 의한 삭제' : '예: 고객 요청에 의한 변경'

			this.dialog = true
			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},
		async cancel() {
			this.dialog = false
			this.resolve(false)
		},
		async ok() {
			this.dialog = false
			this.resolve(this.message)
		}
	}
}
</script>
