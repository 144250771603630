import { canChangeCardPayOwner } from '@/apis/order.recp.api'
const state = {
	recpParams: {},
	recpInfos: {},
	vaccountParams: {},
	dpParams: {},
	payRecp: undefined, // payRecpList item
	isCustSelfPay: false,
	recpCardResult: {},
	canOtherCardPay: false
}

const getters = {
	recpParams: state => state.recpParams,
	recpInfos: state => state.recpInfos,
	vaccountParams: state => state.vaccountParams,
	dpParams: state => state.dpParams,
	payRecp: state => state.payRecp,
	isCustSelfPay: state => state.isCustSelfPay,
	recpCardResult: state => state.recpCardResult
}

const actions = {
	async setRecpParams({ commit }, value) {
		commit('setRecpParams', value)
	},
	async setRecpInfos({ commit }, value) {
		commit('setRecpInfos', value)
	},
	async setVAccountParams({ commit }, value) {
		commit('setVAccountParams', value)
	},
	async setDpParams({ commit }, value) {
		commit('setDpParams', value)
	},
	async setPayRecp({ commit }, value) {
		commit('setPayRecp', value)
	},
	async setIsCustSelfPay({ commit }, value) {
		commit('setIsCustSelfPay', value)
	},
	async setRecpCardResult({ commit }, value) {
		commit('setRecpCardResult', value)
	},
	async setCanOtherCardPay({ commit, rootGetters }) {
		const mobOrderNo = rootGetters['verify/mobOrderNo']
		const res = await canChangeCardPayOwner(mobOrderNo)
		const payload = res.resultObject === 'Y'
		commit('setCanOtherCardPay', payload)
	}
}

const mutations = {
	setRecpParams(state, value) {
		state.recpParams = value
	},
	setRecpInfos(state, value) {
		state.recpInfos = value
	},
	setVAccountParams(state, value) {
		state.vaccountParams = value
	},
	setDpParams(state, value) {
		state.dpParams = value
	},
	setPayRecp(state, value) {
		state.payRecp = value
	},
	setIsCustSelfPay(state, value) {
		state.isCustSelfPay = value
	},
	setRecpCardResult(state, value) {
		state.recpCardResult = value
	},
	setCanOtherCardPay(state, payload) {
		state.canOtherCardPay = payload
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
