export default [
	{
		path: 'recp/method',
		name: 'recp-method',
		meta: {
			isPublic: true,
			type: 'sub',
			title: '결제정보 선택'
		},
		component: () => import('@/views/recp/RecpMethodPage.vue')
	},
	{
		path: 'recp/card',
		name: 'recp-card',
		meta: {
			isPublic: true,
			type: 'main'
		},
		component: () => import('@/views/recp/RecpCardPaymentPage.vue')
	},
	{
		path: 'recp/bank',
		name: 'recp-bank',
		meta: {
			isPublic: true,
			type: 'main'
		},
		component: () => import('@/views/recp/RecpBankPaymentPage.vue')
	},
	{
		path: 'recp/vaccount',
		name: 'recp-vaccount',
		meta: {
			isPublic: true,
			type: 'main'
		},
		component: () => import('@/views/recp/RecpVirtualAccountPage.vue')
	},
	{
		path: 'recp/complex',
		name: 'recp-complex',
		meta: {
			isPublic: true,
			type: 'main'
		},
		component: () => import('@/views/recp/RecpComplexPaymentPage.vue')
	},
	{
		path: 'recp/complex/card',
		name: 'recp-complex-card',
		meta: {
			isPublic: true,
			type: 'main'
		},
		component: () => import('@/views/recp/RecpComplexCardPaymentPage.vue')
	},
	{
		path: 'recp/complex/bank',
		name: 'recp-complex-bank',
		meta: {
			isPublic: true,
			type: 'main'
		},
		component: () => import('@/views/recp/RecpComplexBankPaymentPage.vue')
	},
	{
		path: 'recp/cust/method',
		name: 'recp-cust-method',
		meta: {
			isPublic: true,
			type: 'main',
			hideAppBar: true,
			showWebHeader: true
		},
		component: () => import('@/views/recp/cust/RecpMethodSelectPage.vue')
	},
	{
		path: 'recp/cust/card',
		name: 'recp-cust-card',
		meta: {
			isPublic: true,
			type: 'main',
			hideAppBar: true,
			showWebHeader: true
		},
		component: () => import('@/views/recp/cust/RecpCardPaymentPage.vue')
	},
	{
		path: 'recp/cust/vaccount',
		name: 'recp-cust-vaccount',
		meta: {
			isPublic: true,
			type: 'main',
			hideAppBar: true,
			showWebHeader: true
		},
		component: () => import('@/views/recp/cust/RecpVirtualAccountPage.vue')
	},
	{
		path: 'recp/cust/bank',
		name: 'recp-cust-bank',
		meta: {
			isPublic: true,
			type: 'main',
			hideAppBar: true,
			showWebHeader: true
		},
		component: () => import('@/views/recp/cust/RecpBankPaymentPage.vue')
	},
	{
		path: 'recp/cust/complex',
		name: 'recp-cust-complex',
		meta: {
			isPublic: true,
			type: 'main',
			hideAppBar: true,
			showWebHeader: true
		},
		component: () => import('@/views/recp/cust/RecpComplexPaymentPage.vue')
	},
	{
		path: 'recp/cust/complex/card',
		name: 'recp-cust-complex-card',
		meta: {
			isPublic: true,
			type: 'main',
			hideAppBar: true,
			showWebHeader: true
		},
		component: () => import('@/views/recp/cust/RecpComplexCardPaymentPage.vue')
	},
	{
		path: 'recp/cust/complex/bank',
		name: 'recp-cust-complex-bank',
		meta: {
			isPublic: true,
			type: 'main',
			hideAppBar: true,
			showWebHeader: true
		},
		component: () => import('@/views/recp/cust/RecpComplexBankPaymentPage.vue')
	},
	{
		path: 'recp/cust/uncomplete',
		name: 'recp-cust-uncomplete',
		meta: {
			isPublic: true,
			type: 'main',
			hideAppBar: true,
			showWebHeader: true
		},
		component: () => import('@/views/recp/cust/CustRecpUnComplete.vue')
	},
	{
		path: 'recp/vaccount/info',
		name: 'recp-vaccount-info',
		meta: {
			isPublic: true,
			type: 'main'
		},
		component: () => import('@/views/recp/RecpVirtualAccountInfoPage.vue')
	},
	{
		path: 'recp/regcard',
		name: 'recp-regcard',
		meta: {
			isPublic: true,
			type: 'main'
		},
		component: () => import('@/views/recp/RecpRegularCardPaymentPage.vue')
	},
	{
		path: 'recp/cust/regcard',
		name: 'recp-cust-regcard',
		meta: {
			isPublic: true,
			type: 'main',
			showWebHeader: true
		},
		component: () => import('@/views/recp/cust/RecpRegularCardPaymentPage.vue')
	}
]
