<template>
	<v-dialog v-model="dialog" fullscreen>
		<!-- 주소입력 상단 타이틀 -->
		<div class="full_dialog_header">
			<h2 class="tit">주소입력</h2>
			<v-btn depressed icon plain class="btn_right" @click="close"
				><v-icon dark>
					mdi-window-close
				</v-icon></v-btn
			>
		</div>
		<!--// 주소입력 상단 타이틀 -->

		<div class="dialog_all_page pa-6" style="height: calc(100vh - 50px);">
			<!-- 주소 검색 -->
			<v-form
				class="form"
				ref="instAddrSearchForm"
				@submit.prevent="clickSearch"
			>
				<v-row class="mb-0">
					<v-text-field
						dense
						outlined
						height="48px"
						class="search wcalc90"
						inputmode="text"
						maxlength="30"
						prepend-inner-icon="mdi-magnify"
						placeholder="검색할 주소 입력"
						v-model="searchText"
						:rules="rules.searchText"
						@click:append="clickSearch()"
						@keyup.enter="clickSearch()"
					/>
					<v-btn
						class="ml-2 bor_default"
						height="48px"
						color="primary"
						depressed
						@click="clickSearch()"
					>
						검색
					</v-btn>
				</v-row>
			</v-form>
			<!--// 주소 검색 -->

			<!-- 주소 검색전 -->
			<div v-if="view1">
				<div class="txt_body1 mt-3">
					지번 주소 또는 도로명 주소를 입력 후 <br />
					검색을 눌러주세요.
				</div>
			</div>
			<!--// 주소 검색전 -->

			<!-- 주소 검색 후 -->
			<div v-if="view2">
				<v-btn
					depressed
					text
					class="box_result mt-7"
					v-for="(addr, i) in addrList"
					:key="i"
					@click="setAddress(addr)"
				>
					<div class="result_add_more">
						<dl class="add_item zip">
							<dt>우편번호</dt>
							<dd>{{ addr.zonecode }}</dd>
						</dl>
						<dl class="add_item">
							<dt>도로명</dt>
							<dd>{{ addr.roadAddress }}</dd>
						</dl>
						<dl class="add_item">
							<dt>지번</dt>
							<dd>{{ addr.jibunAddress }}</dd>
						</dl>
					</div>
				</v-btn>
			</div>
			<!-- 주소 검색 후 -->

			<!-- 주소 선택 후  -->
			<div v-if="view3" class="box_result mt-7">
				<div class="result_add_more">
					<dl class="add_item zip">
						<dt>우편번호</dt>
						<dd>{{ addressData.zoneCode }}</dd>
					</dl>
					<dl class="add_item">
						<dt>도로명</dt>
						<dd>{{ addressData.road }}</dd>
					</dl>
					<dl class="add_item">
						<dt>지번</dt>
						<dd>{{ addressData.jibun }}</dd>
					</dl>
				</div>
				<div class="wrap_input mt-5">
					<v-form class="form" ref="instAddrSearchFormSave">
						<v-text-field
							outlined
							placeholder="상세주소(아파트명, 건물명) 필수 입력"
							dense
							v-model="addressData.detail"
							:rules="rules.detailAddress"
							@keydown.enter.prevent="saveAddress"
						/>
						<div class="default--text importantMessage mt-2">
							(예시) A아파트 1동 1호, B상가 3층, C빌라 2층
						</div>
					</v-form>
				</div>
			</div>
			<!-- 주소 선택 후 -->

			<div class="bottom_box mt-8">
				<v-btn
					v-if="view3"
					color="primary"
					class="col_1_small"
					:disabled="chk"
					@click="saveAddress()"
				>
					주소 입력
				</v-btn>
			</div>
		</div>
		<PostSearchDialog ref="psd" :q="searchText" />
	</v-dialog>
</template>
<script>
import { required, maxLength } from '@/utils/rules'
import { postAddressChk } from '@/apis/order.inst.api'
import filters from '@/mixins/filters'
export default {
	name: 'AddrSearch',
	mixins: [filters],
	data() {
		return {
			dialog: false,
			searchText: '',
			view1: true,
			view2: false,
			view3: false,
			addressData: {
				road: '',
				jibun: '',
				detail: '',
				zoneCode: '',
				buildingName: ''
			}, // 주소 데이터
			addr: {},
			addrList: [],
			rules: {
				searchText: [
					v =>
						required(v) === '필수 입력값입니다'
							? '검색어를 입력해 주세요.'
							: required(v),
					v => maxLength(v, 30)
				],
				detailAddress: [v => required(v), v => maxLength(v, 50)]
			},
			resolve: null,
			reject: null
		}
	},
	computed: {
		//roadAddress 없는 CASE : 전남 고흥군 도양읍봉암리 1967-8번지 녹동5구
		chk() {
			const addrIsDisable =
				this.addr.roadAddress === ''
					? this.addr.autoRoadAddress
					: this.addr.roadAddress
			return addrIsDisable === '' ? true : false
		}
	},
	created() {
		// 폼 초기화
		this.reset()
	},
	methods: {
		// 초기화
		reset() {
			this.searchText = ''
		},

		// 주소 검색 오픈
		open() {
			this.dialog = true
			this.searchText = ''
			this.view1 = true
			this.view2 = false
			this.view3 = false
			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},

		// 닫기
		close() {
			this.dialog = false
		},

		// 검색
		async clickSearch() {
			if (!this.$refs.instAddrSearchForm.validate()) {
				return
			}

			this.view1 = false
			this.view2 = true
			this.view3 = false
			this.addrList = []
			const res = await this.$refs['psd'].open()
			const { query } = res
			this.searchText = query
			this.setAddress(res)
		},

		setAddress(addr) {
			this.view1 = false
			this.view2 = false
			this.view3 = true
			this.addr = { ...this.addr, ...addr }
			// 우편번호 (
			this.addressData.zoneCode = addr.zonecode
			// 도로명 주소
			this.addressData.road =
				addr.roadAddress !== '' ? addr.roadAddress : addr.autoRoadAddress
			// 지번 주소
			this.addressData.jibun =
				addr.jibunAddress !== '' ? addr.jibunAddress : addr.autoJibunAddress
			// 건물명 ( 추후 요건이 있을때 적용)
			this.addressData.buildingName = addr.buildingName
			this.addressData.detail = ''
		},

		// 주소 저장
		async saveAddress() {
			if (!this.$refs.instAddrSearchFormSave.validate()) {
				return
			}
			// SAP에 정제주소 조회 PARAM
			const param = {
				zipCode: this.addressData.zoneCode,
				address: `${this.addressData.road}`,
				addEtc: this.addressData.detail
			}
			this.$log('정제요청 PARAM', param)

			// SAP에 정제주소 조회
			const res = await postAddressChk(param)
			const addressChk = res.resultObject.data
			if (addressChk.ES_RETURN.TYPE !== 'S') {
				this.addr = {}
				await this.$alert({
					message:
						'입력된 주소는 코웨이 주소 시스템에 미등록된 신규 주소입니다.<br />지국에 확인하여 등록하시기 바랍니다.',
					okText: '확인'
				})
			} else {
				this.addr = {}
				this.addr.zonecode = addressChk.ET_ITEM2[0].ZIPCODE
				this.addr.roadAddress = param.address
				this.addr.detailAddress = param.addEtc
				this.addr.zipSeq = addressChk.ET_ITEM2[0].ZIP_SEQ
				this.resolve(this.addr)
			}
			this.close()
		}
	}
}
</script>
