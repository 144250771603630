<template>
	<div class="cw-app-wrapper pre-contract">
		<!-- contents -->
		<div class="cw-app-container">
			<div class="pre-subs-box01">
				<h3>고객인증 요청</h3>
				<p>
					<em>‘고객인증 요청'</em> 버튼을 누르면, 고객에게 재렌탈<br />사전계약
					고객인증 알림톡이 발송되고 고객 인증이 진행됩니다. 인증 완료 후
					주문현황을 통해 주문을 진행해 주세요.
				</p>
			</div>

			<div class="pre-subs-box02">
				<h4>고객 정보 확인</h4>
				<div class="box">
					<dl>
						<dt>고객구분</dt>
						<dd>
							{{ customer.CUST_TYPE === '1' ? '개인' : '개인사업자' }}
						</dd>
					</dl>
					<dl>
						<dt>고객명</dt>
						<dd>{{ customer.CUST_NM }} ({{ customer.KUNNR }})</dd>
					</dl>

					<dl>
						<dt>휴대폰 번호</dt>
						<dd class="highlight" ref="userPhoneNumberValidateText"></dd>
					</dl>
					<v-form class="form" ref="custForm">
						<div class="input">
							<v-text-field
								dense
								outlined
								v-model="userPhoneNumber"
								placeholder="휴대폰 번호를 입력해 주세요."
								inputmode="numeric"
								maxlength="11"
								minlength="10"
								v-mask="['##########', '###########']"
								:rules="rules.phone"
							/>
						</div>
					</v-form>
				</div>
			</div>
		</div>
		<!-- //contents -->

		<!-- bottom -->
		<div class="cw-app-fixed">
			<div class="u-btns-group">
				<div class="column">
					<v-btn large class="u-btn-text" @click="prev()">이전</v-btn>
				</div>
				<div class="column">
					<v-btn
						large
						class="u-btn-active"
						@click="preContractPost(customer)"
						:disabled="disableBtn === true"
					>
						고객인증 요청
					</v-btn>
				</div>
			</div>
		</div>
		<!-- //bottom -->

		<VerifySelfDialog ref="verify-self" />
	</div>
</template>

<script>
/**
 * 사전계약 신청서 발송
 * @description
 */
import { mapActions } from 'vuex'
import filters from '@/mixins/filters'
import { sendLmsLinkToCustForVerification } from '@/apis/order.verify.api'
import { registerCallbackToApp } from '@/utils/navigation'
import { phoneNumber, minLength } from '@/utils/rules'

export default {
	name: 'PreContractPost',
	props: {
		params: Object
	},
	data() {
		return {
			rules: {
				phone: [v => minLength(v, 10), v => phoneNumber(v)]
			},
			//----------------------
			customer: {},
			//----------------------
			// 휴대폰번호
			userPhoneNumber: '',
			// 토스트메시지
			timeOut: 3000,

			// 발송확인 팝업
			popConfirmShow: false,
			disableBtn: true
		}
	},
	mixins: [filters],
	activated() {
		this.initAppPage()

		if (!this.params) {
			return
		}

		this.customer = this.params.customer
		this.$refs.userPhoneNumberValidateText.textContent =
			'*계약자 본인 명의 휴대폰 번호를 입력해 주세요.'

		this.userPhoneNumber = ''
		if (this.customer?.MOB_NUMBER) {
			this.userPhoneNumber = this.customer.MOB_NUMBER.replace(/\s+/g, '')
			this.$refs.userPhoneNumberValidateText.textContent =
				'*저장된 고객님의 휴대전화번호를 불러왔습니다. 휴대전화번호가 다를 경우 직접 수정해주세요.'
			this.$alert({
				message:
					'기준정보로 휴대폰 번호가 입력 되었습니다. 휴대폰 번호가 변경된 경우, 화면에서 수정 후 고객인증 요청 진행해 주세요.'
			})
		}
	},
	watch: {
		userPhoneNumber() {
			if (
				minLength(this.userPhoneNumber, 10) === true &&
				phoneNumber(this.userPhoneNumber) === true
			) {
				this.disableBtn = false
			} else {
				this.disableBtn = true
			}
		}
	},
	methods: {
		...mapActions({ showSnackbar: 'common/showSnackbar' }),
		initAppPage() {
			registerCallbackToApp(
				{
					title: '재렌탈 사전계약',
					cbPhysicalBack: 'cbPhysicalBack'
				},
				this
			)
		},
		async cbPhysicalBack() {
			this.prev()
		},
		prev() {
			this.userPhoneNumber = ''
			this.$emit('replaceComponent', {
				name: 'PreContractListDetail',
				id: this.customer.KUNNR
			})
		},

		async preContractPost(customer) {
			if (!this.$refs.custForm.validate()) {
				return
			}
			const options = {
				title: '다음 고객에게 고객인증을 위한 메시지를 발송합니다.',
				guide: '고객정보를 다시 한 번 확인해주세요',
				contents: {
					고객구분:
						customer.CUST_TYPE === '1'
							? `개인(${customer.KUNNR})`
							: `개인사업자(${customer.KUNNR})`,
					고객명: customer.CUST_NM,
					휴대폰번호: this.$options.filters.phoneFormat(this.userPhoneNumber, 1)
				},
				okText: '발송',
				cancelText: '취소'
			}
			const res = await this.$refs['verify-self'].open(options)
			if (res) this.send()
		},

		async send() {
			const params = {
				name: this.customer.CUST_NM,
				phoneNo: this.userPhoneNumber,
				custType: this.customer.CUST_TYPE === '1' ? 'P' : 'L',
				codyNo: this.$store.getters['common/codyNo'],
				codyPhoneNo: this.$store.getters['common/codyPhoneNo'],
				url: `${process.env.VUE_APP_FRT_URL}/order/verify/cust/self`,
				codyMatch: '',
				type: this.$store.getters['common/type'],
				orderType: 'C',
				app: this.$store.getters['common/app']
			}
			sendLmsLinkToCustForVerification(params)
				.then(async res => {
					const result = res.resultObject
					// 디버깅용
					this.authDebugUrl(result.userKey, result.id)

					if (res.code) {
						const confirmRes = await this.$confirm({
							message: `고객인증 메시지를 발송하였습니다. 주문현황으로 이동하시겠습니까?`,
							okText: '예',
							cancelText: '아니오'
						})

						if (confirmRes) {
							// 확인 : 주문현황
							const codyQuery = {
								tabCode: 'C'
							}
							this.$store.dispatch('common/setUserInfo', codyQuery)
							this.$router.push({
								name: 'order-status'
							})
						}
					} else {
						this.$alert({
							message: res.result
						})
					}
				})
				.catch(() => {
					this.$alert({
						message:
							'일시적인 통신 장애로 서비스에 접속할 수 없습니다. 문제가 계속된다면, 담당자에게 문의해 주세요.'
					})
				})
		},

		/**
		 * 디버깅을 위한 URL 출력
		 * @param userKey
		 * @param orderId
		 */
		authDebugUrl(userKey, orderId) {
			this.$log(
				'url with userKey:',
				`${process.env.VUE_APP_FRT_URL}/order/verify/cust/self?userKey=${userKey}&id=${orderId}`
			)
		}
	}
}
</script>

<style scoped></style>
