<template>
	<v-container>
		<v-card flat>
			<v-card-title v-if="title">{{ title }}</v-card-title>
			<v-data-table
				ref="table"
				:headers="headers"
				:items="items"
				:loading="loading"
				:show-select="showSelect"
				:fixed-header="true"
				:mobile-breakpoint="mobileBreakpoint"
				:height="height"
				:hide-default-header="hideHeaders"
				:hide-default-footer="true"
				:calculate-widths="true"
				:dense="dense"
				class="elevation-1"
				disable-pagination
				@update:options="handleUpdateOptions"
			>
				<template
					v-for="headerSlot in Object.keys($scopedSlots).filter(
						item => item.indexOf('header') === 0
					)"
					v-slot:[headerSlot]="{ header }"
				>
					<slot :name="headerSlot" v-bind:header="header" />
				</template>

				<template
					v-for="bodySlot in Object.keys($scopedSlots).filter(
						item => item.indexOf('item') === 0
					)"
					v-slot:[bodySlot]="{ item }"
				>
					<slot :name="bodySlot" v-bind:item="item" />
				</template>
			</v-data-table>
		</v-card>
	</v-container>
</template>

<script>
export default {
	props: {
		title: String,
		headers: {
			type: Array,
			required: true
		},
		items: {
			type: Array,
			required: true
		},
		loading: {
			type: Boolean,
			required: true
		},
		currentPage: {
			type: Number,
			required: true
		},
		pageSize: {
			type: Number,
			required: true
		},
		showSelect: {
			type: Boolean,
			default: false
		},
		mobileBreakpoint: {
			type: Number,
			default: 200
		},
		height: Number,
		dense: Boolean,
		hideHeaders: Boolean
	},
	data() {
		return {
			renderedPage: []
		}
	},
	watch: {
		currentPage() {
			this.renderedPage.push(this.currentPage)
		}
	},
	methods: {
		// sorting 변경: {key: 'key명', isDesc: 'true|false'}
		handleUpdateOptions(payload) {
			if (payload.sortBy) {
				const sortArr = []
				payload.sortBy.forEach((value, index) => {
					sortArr.push({
						key: value,
						isDesc: payload.sortDesc[index]
					})
				})
				this.$emit('updateOptions', sortArr)
			}
		},
		// scroll to bottom
		nextPage() {
			this.$emit('nextPage', this.currentPage + 1)
		},
		getNextTargetPage(currentTarget) {
			return this.renderedPage.indexOf(currentTarget) > -1
				? this.getNextTargetPage(currentTarget + 1)
				: currentTarget
		}
	},
	mounted() {
		// 시작 페이지 셋팅
		this.renderedPage.push(this.currentPage)

		// scroll event 추가
		this.$refs.table.$el.firstElementChild.addEventListener('scroll', e => {
			if (this.loading) return
			let { offsetHeight, scrollTop, scrollHeight } = e.target
			// bottom
			if (offsetHeight + scrollTop === scrollHeight) {
				this.$emit('nextPage', this.getNextTargetPage(this.currentPage + 1))
			}
		})
		window.vm = this
	},
	destroyed() {
		// scroll event 삭제
		this.$refs.table.$el.firstElementChild.removeEventListener('scroll')
	}
}
</script>
