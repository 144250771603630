<template>
	<div class="u-bank-wrap">
		<v-bottom-sheet
			v-model="seen"
			scrollable
			:persistent="persistent"
			:content-class="`u-bank-sheet`"
		>
			<template v-slot:activator="{ attrs }" v-if="!hideButton">
				<v-btn
					:disabled="disabledButton"
					class="btn_select_type"
					height="48px"
					depressed
					outlined
					@click="open"
					v-bind="attrs"
				>
					<slot name="default" />
					<!-- <span class="btn_tit">CRP </span>
					<span class="btn_value">
						<b class="primary--text">등록비-100000원</b></span
					> -->
				</v-btn>
			</template>

			<v-card class="u-bank-grid">
				<v-card-title>{{ title }}</v-card-title>

				<v-card-text
					v-if="paymentType === 'recp'"
					style="background-color: white;"
				>
					<div class="box row">
						<v-btn-toggle :mandatory="mandatory" v-model="selected">
							<div
								class="u-bank-cover"
								v-for="(item, i) in paymentList"
								:key="`item-${i}`"
							>
								<v-btn
									:ripple="false"
									class="ico"
									:class="`cd-${item.cd}`"
									@click="onClick(i, item)"
									>{{ item.cdNm }}</v-btn
								>
							</div>
						</v-btn-toggle>
					</div>
				</v-card-text>
				<v-card-text v-if="paymentType === 'cust'">
					<div class="box row">
						<v-btn-toggle :mandatory="mandatoryCommon" v-model="selectedCommon">
							<div
								class="u-bank-cover"
								v-for="(item, i) in common"
								:key="`item-${i}`"
							>
								<v-btn
									class="ico"
									:class="`cd-${item.cd}`"
									@click="onClick(i, item)"
									>{{ item.cdNm }}</v-btn
								>
							</div>
						</v-btn-toggle>
					</div>
					<div class="box bg">
						<p class="msg">
							아래 은행을 선택하는 경우, 추후 결제정보 추가 등록이 필요할 수
							있습니다.
						</p>
						<v-btn-toggle
							:mandatory="mandatoryEtcBank"
							v-model="selectedEtcBank"
						>
							<div
								class="u-bank-cover"
								v-for="(item, i) in etcBank"
								:key="`item-${i}`"
							>
								<v-btn
									class="ico"
									:class="`cd-${item.cd}`"
									@click="onClick(i, item)"
									>{{ item.cdNm }}</v-btn
								>
							</div>
						</v-btn-toggle>
					</div>
				</v-card-text>
			</v-card>
		</v-bottom-sheet>
	</div>
</template>

<script>
import { paymentList } from '@/apis/order.status.api'
export default {
	props: {
		value: [String, Number],
		title: String,
		persistent: Boolean,
		hideButton: Boolean,
		instantTransfer: Boolean,
		div: String,
		disabled: {
			type: Boolean,
			default: false
		},
		paymentType: [String],
		paymentGroup: { Number, default: -1 },
		disabledButton: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		selected: {
			get() {
				return this.paymentList.findIndex(x => x.cd === this.value)
			},
			set(value) {
				if (value !== undefined) {
					this.$emit('input', this.paymentList[value].cd)
				}
			}
		},
		selectedCommon: {
			get() {
				return this.common.findIndex(x => x.cd === this.value)
			},
			set(value) {
				if (value !== undefined) {
					this.$emit('input', this.common[value].cd)
				}
			}
		},
		selectedEtcBank: {
			get() {
				return this.etcBank.findIndex(x => x.cd === this.value)
			},
			set(value) {
				if (value !== undefined) {
					this.$emit('input', this.etcBank[value].cd)
				}
			}
		},

		mandatory() {
			return this.paymentList.some(x => x.cd === this.value)
		},

		mandatoryCommon() {
			return this.common.some(x => x.cd === this.value)
		},
		mandatoryEtcBank() {
			return this.etcBank.some(x => x.cd === this.value)
		}
	},
	data() {
		return {
			seen: false,
			paymentList: [],
			common: [],
			etcBank: []
		}
	},

	methods: {
		open() {
			this.common = []
			this.etcBank = []
			this.getPaymentList()
			this.seen = true
		},

		onClick(clickedValue, item) {
			this.close(clickedValue, item)
		},
		close(value, item) {
			if (value !== undefined) {
				const selectedIndex = value
				let selectedItem = ''
				if (this.paymentType === 'recp') {
					selectedItem = this.paymentList[selectedIndex]
				} else {
					if (item.receiptYn === 'Y') {
						selectedItem = this.common[selectedIndex]
					} else {
						selectedItem = this.etcBank[selectedIndex]
					}
				}
				this.$emit('item-selected', selectedItem)
			}
			this.seen = false
		},
		commonBank(x) {
			x.forEach(v => {
				if (v.receiptYn === 'Y') {
					this.common.push({
						cdNm: v.cdNm,
						cd: v.cd,
						receiptYn: 'Y'
					})
					return this.common
				} else {
					this.etcBank.push({
						cdNm: v.cdNm,
						cd: v.cd,
						receiptYn: 'N'
					})
					return this.etcBank
				}
			})
		},
		async getPaymentList() {
			const params = { grpCd: 'G6' }

			await paymentList(params).then(async chkRes => {
				this.paymentList = chkRes.resultObject

				this.paymentList = chkRes.resultObject.filter(x => {
					const result =
						this.div === 'R' ? x.regularYn === 'Y' : x.receiptYn === 'Y'
					return result
				})

				this.commonBank(this.paymentList)
			})
		}
	}
}
</script>
