import api from '@/apis/config'
const { post } = api

// import log from '@/utils/log'

/**
 * 할인선택 항목별 옵션 조회
 * @param {Object} 선택 상품 정보
 */
export const fetchPayOptions = async product => {
	const response = await post('v1/03/01', [...product])
	return response
}

/**
 * 정기 결제정보 추가시 카드 확인
 * @param {Object} 카드 정보
 */
export const cardCertify = async card => {
	const response = await post('v1/03/02', {
		...card
	})
	return response
}

/**
 * 정기 결제정보 추가시 은행계좌 확인
 * @param {Object} 은행계좌 정보
 */
export const bankAccountCertify = async bank => {
	const response = await post('v1/03/03', {
		...bank
	})
	return response
}

/**
 * 할부계약금 최소금액 조회
 * @param {Object} 할부계약금 정보
 */
export const checkMinInstallment = async param => {
	const response = await post('v1/03/05', {
		...param
	})
	return response
}

/**
 * 자동할인 적용
 * @description 동시구매할인, 결합할인 적용을 위해 SAP에 요청한다.
 * @rfc ZSD_1000_MOBILE_POLICY_SETTING_모바일 자동 금액 세팅
 * @param param (I_CONTRACT_CUST_ID, I_REGER_NO, IT_INPUT)
 * @returns {Promise<*>}
 */
export const policySetting = async param => {
	const response = await post('v1/03/06', {
		...param
	})
	return response
}

/**
 * 할인입력 유효성 체크
 *
 */
export const policyCheck = async param => {
	const response = await post('v1/03/07', {
		...param
	})
	return response
}

/**
 * 할인입력 유효성 체크
 *
 */
export const getPreAmt = async param => {
	const response = await post('v1/03/08', {
		...param
	})
	return response
}
