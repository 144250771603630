import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n'
import { sync } from 'vuex-router-sync'
import '@/plugins/alert'
import '@/plugins/base'
import '@/utils/log'
import VueMask from 'v-mask'
import '@/plugins/vuetify-money'
import '@/plugins/constants'
import VueGtag from 'vue-gtag'
import '@/assets/scss/index.scss'
import VueCryptojs from 'vue-cryptojs'

Vue.use(VueMask)
Vue.use(VueCryptojs)
Vue.use(VueGtag, {
	config: {
		// Google Analytics의 Tracking ID를 넣어준다
		id: 'UA-174643619-4',
		params: {
			send_page_view: true
		}
	}
})

Vue.config.productionTip = JSON.parse(process.env.VUE_APP_PRODUCTION_TIP)
Vue.config.devtools = JSON.parse(process.env.VUE_APP_DEV_TOOLS) // VueJS 개발툴 활설/비활성

sync(store, router)

new Vue({
	router,
	store,
	vuetify,
	i18n,
	render: h => h(App)
}).$mount('#app')
