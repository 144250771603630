import { ko } from 'vuetify/es5/locale'
import common from './common.msg'
import subscription from './subscription'
import preContract from './preContract'
import title from './title'

const messages = {
	$vuetify: ko,
	common,
	title,
	subscription,
	preContract
}

export default messages
