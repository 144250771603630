<template>
	<div>
		<v-bottom-sheet v-model="seen" scrollable :persistent="persistent">
			<template v-slot:activator="{ attrs }" v-if="!hideButton">
				<v-btn
					class="btn_select_type"
					height="48px"
					depressed
					outlined
					@click="open"
					v-bind="attrs"
				>
					<slot name="default" />
				</v-btn>
			</template>
			<h2 class="tit">{{ title }}</h2>
			<div class="scr_area">
				<v-btn-toggle
					class="btn_bottom_sheet"
					v-model="selected"
					:mandatory="mandatory"
				>
					<v-btn
						v-for="(item, index) in items"
						@click="onClick(index)"
						:key="index"
					>
						<p class="mb-0" v-if="index !== 0">
							<span class="impact_01">{{ item.text }}</span>
							<span class="impact_02"> 매월 + {{ +item.amt | comma }}</span>
						</p>
						<span v-else>{{ item.text }}</span>
					</v-btn>
					<p class="infor">
						무상 하트서비스/필터 교환 서비스를 신청합니다.
						<br />
						의무 사용기간: 1년
					</p>
				</v-btn-toggle>
			</div>
		</v-bottom-sheet>
	</div>
</template>

<script>
import filters from '@/mixins/filters'
import { pad } from '@/utils'
export default {
	mixins: [filters],
	props: {
		value: [String, Number],
		text: String,
		title: String,
		persistent: Boolean,
		hideButton: Boolean,
		monAmt: [String, Number],
		memberships: Array
	},
	computed: {
		displayText() {
			return this.text
		},
		selected: {
			get() {
				return this.items.findIndex(x => x.code === this.value)
			},
			set(value) {
				if (value !== undefined) {
					this.$emit('input', this.items[value].code)
				}
			}
		},
		mandatory() {
			return this.items.some(x => x.code === this.value)
		}
	},
	data() {
		return {
			seen: false,
			items: []
		}
	},
	methods: {
		open() {
			const pms = this.memberships.map((p, index) => {
				return {
					code: pad(index + 1),
					text: this.getUseTpDesc(p.USE_TP),
					desc: '',
					amt: +p.MON_AMT,
					useTp: p.USE_TP
				}
			})
			this.items = [{ code: '00', text: '미가입', amt: 0, useTp: '' }, ...pms]
			this.seen = true
		},
		onClick(clickedValue) {
			this.close(clickedValue)
		},
		close(value) {
			if (value !== undefined) {
				const selectedIndex = value
				const selectedItem = this.items[selectedIndex]
				this.$emit('item-selected', selectedItem)
			}
			this.seen = false
		},
		getUseTpDesc(useTp) {
			let desc = ''
			switch (useTp) {
				case '1':
					desc = '[방문관리] 기본형'
					break
				case '2':
					desc = '[방문관리] 강화형'
					break
				case '3':
					desc = '[방문관리] 지정/특수'
					break
				case '4':
					desc = '[방문관리] 서비스프리'
					break
				case '5':
					desc = '자가관리'
					break
				default:
					desc = '미가입'
			}

			return desc
		}
	}
}
</script>
