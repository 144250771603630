<template>
	<div class="mb_3">
		<v-bottom-sheet v-model="seen" scrollable :persistent="persistent">
			<template v-slot:activator="{ attrs }" v-if="!hideButton">
				<v-btn
					class="btn_select_type"
					height="48px"
					depressed
					outlined
					v-bind="attrs"
					@click="open"
				>
					<slot name="default" />
				</v-btn>
			</template>
			<h2 class="tit">{{ title }}</h2>
			<div class="scr_area">
				<v-btn-toggle
					class="btn_bottom_sheet"
					v-model="selected"
					:mandatory="mandatory"
				>
					<v-btn
						v-for="(item, i) in items"
						:key="`item-${i}`"
						class="btn_bank"
						:class="`bank_${item.code}`"
						@click="onClick(i)"
					>
						{{ item.text }}
					</v-btn>
				</v-btn-toggle>
			</div>
		</v-bottom-sheet>
	</div>
</template>

<script>
import { fetchRecpType } from '@/apis/order.recp.api'
export default {
	props: {
		value: [String, Number],
		text: String,
		title: String,
		persistent: Boolean,
		hideButton: Boolean
	},
	computed: {
		displayText() {
			return this.text
		},
		selected: {
			get() {
				return this.items.findIndex(x => x.code === this.value)
			},
			set(value) {
				if (value !== undefined) {
					this.$emit('input', this.items[value].code)
				}
			}
		},
		mandatory() {
			return this.items.some(x => x.code === this.value)
		}
	},
	data() {
		return {
			seen: false,
			items: [
				{ code: '32', text: '부산은행' },
				{ code: '81', text: 'KEB하나은행' },
				{ code: '20', text: '우리은행' },
				{ code: '04', text: '국민은행' },
				{ code: '88', text: '신한은행' },
				{ code: '11', text: '농협중앙회' },
				{ code: '31', text: '대구은행' }
			]
		}
	},
	methods: {
		async open() {
			await this.fetchVAccountBanks()
			// this.items = this.$store.state['pay'].banks
			// this.items = this.items.sort((a, b) => a.text.localeCompare(b.text))
			this.seen = true
		},
		onClick(clickedValue) {
			this.close(clickedValue)
		},
		close(value) {
			if (value !== undefined) {
				const selectedIndex = value
				const selectedItem = this.items[selectedIndex]
				this.$emit('item-selected', selectedItem)
			}
			this.seen = false
		},
		async fetchVAccountBanks() {
			const payMthd = '16'
			const { zwebRecp, kunnr, amount, userId } = this.$store.getters[
				'recp/recpInfos'
			]
			const params = {
				zwebRecp: zwebRecp,
				payMthd,
				kunnr: kunnr,
				okamt: amount,
				userId: userId
			}
			// const params = {
			// 	zwebRecp:'04',
			// 	payMthd,
			// 	kunnr: '0105921650',
			// 	okamt: '10000',
			// 	userId: '90000165'
			// }

			const res = await fetchRecpType(params)
			const { data } = res.resultObject
			const vbanks = data.TC_CODE || []
			this.$log(vbanks)
			this.items = vbanks
				.map(b => {
					return { code: b.CD, text: b.CD_NM }
				})
				.sort((a, b) => a.text.localeCompare(b.text))
		}
	}
}
</script>
