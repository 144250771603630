<template>
	<div v-if="true" class="header_wrap web" style="z-index: 9999;width:100px">
		<header>
			<div class="header_area label-dev">
				<v-sheet class="mx-auto" height="30px" width="130px" color="yellow">
					<div style="line-height:30px;text-align:center" @click="onClick">
						<template v-if="mode === 'development'"
							>개발 [{{ appVersion }}]</template
						>
						<template v-if="mode === 'blue'">검증 [{{ appVersion }}]</template>
					</div>
				</v-sheet>
			</div>
		</header>
		<AppTester ref="tester" />
	</div>
</template>

<script>
/**
 * 개발,검증 라벨
 */
import AppTester from './AppTester'

export default {
	name: 'MobileWebHeaderNoti',
	props: {
		mode: {
			type: String
		},
		appVersion: {
			type: String
		},
		appName: {
			type: String
		}
	},
	components: {
		AppTester
	},
	data() {
		return {
			visible: false
		}
	},
	methods: {
		onClick() {
			this.$refs.tester.open()
		}
	}
}
</script>
