<template>
	<v-tabs
		v-model="tab"
		show-arrows
		fixed-tabs
		:hide-slider="
			paymentType === 'recp' && (paymentLimit === 'B' || paymentLimit === 'C')
		"
	>
		<template
			v-if="
				paymentType === 'cust' ||
					(paymentType === 'recp' && paymentLimit === '') ||
					(paymentType === '' && paymentLimit === '')
			"
		>
			<v-tab
				v-for="tab in tabs"
				:key="tab.id"
				@click="onAlert(tab, paymentLimit)"
			>
				<v-icon small>{{ tab.icon }}</v-icon>
				<span class="ml-1">{{ tab.name }}</span>
			</v-tab>
		</template>
		<template
			v-if="
				paymentType === 'recp' && (paymentLimit === 'B' || paymentLimit === 'C')
			"
		>
			<div
				v-for="tab in tabs"
				:key="tab.id"
				class="flex_row"
				@click="onAlert(tab, paymentLimit)"
			>
				<v-tab :disabled="paymentType === 'recp' && paymentLimit === 'C'">
					<v-icon small>{{ tab.icon }}</v-icon>
					<span class="ml-1">{{ tab.name }}</span>
				</v-tab>
			</div>
		</template>

		<v-tabs-items v-model="tab">
			<div v-for="tab in tabs" :key="tab.id">
				<v-tab-item :disabled="paymentType === 'recp' && paymentLimit == 'C'">
					<v-card flat>
						<slot :name="tab.id" />
					</v-card>
				</v-tab-item>
			</div>
		</v-tabs-items>
	</v-tabs>
</template>

<script>
import moment from 'moment'

export default {
	props: {
		tabs: Array,
		selectedTab: [String, Number],
		paymentLimit: [String],
		paymentType: [String]
	},
	watch: {
		selectedTab(val) {
			if (val !== this.tab) {
				// tab changed without click
				const tab = this.tabs[val]
				this.onClick(tab)
			}
			this.tab = val
		},
		tab(val) {
			if (this.tabs[val].id === 'BankPayMethodPage') {
				this.limitAlert()
			}
			this.$emit('update:selectedTab', val)
		}
	},
	mounted() {
		if (this.paymentType === 'recp' && this.paymentLimit === 'C') {
			this.onClick(this.tabs[0])
			this.tab = 0
			this.$emit('update:selectedTab', 0)
			this.$emit('change', this.tabs[0].id)
		} else if (this.paymentType === 'recp' && this.paymentLimit === 'B') {
			this.onClick(this.tabs[1])
			this.tab = 1
			this.$emit('update:selectedTab', 1)
			this.$emit('update:selectedTab', 1)
			this.$emit('change', this.tabs[1].id)
		}
	},
	data() {
		return {
			tab: null,
			beforeTab: null
			//selectedTab: null
		}
	},
	methods: {
		onClick(tab) {
			this.$log('click tab', tab, this.paymentLimit, this.paymentType)
			this.$emit('change', tab.id)
		},
		async limitAlert() {
			//은행점검 이용불가시간
			const CURRENT_TIME = moment().format('HH:mm:ss')
			this.$log('CURRENT_TIME', CURRENT_TIME)
			const START_TIME = '23:30:00'
			const END_TIME = '00:30:00'
			if (CURRENT_TIME > START_TIME || CURRENT_TIME < END_TIME) {
				const res = await this.$alert({
					message:
						'해당시간 (23:30 ~ 00:30)동안 은행점검으로 이용불가 합니다. 점검 완료 후 이용해주십시오'
				})
				if (res) {
					this.onClick(this.tabs[0])
					this.tab = 0
					this.$emit('update:selectedTab', 0)
					this.$emit('change', this.tabs[0].id)
				}
			}
		},
		async onAlert(tab, value) {
			this.$log('TEST', tab.id, value)
			if (
				this.paymentType === 'recp' &&
				tab.id === 'BankPayMethodPage' &&
				value !== 'B' &&
				value !== ''
			) {
				// 카드이체 허용, 은행이체 제한
				this.$alert({
					message: '은행이체는 정기결제정보로 설정이 불가합니다.'
				})
			} else if (
				this.paymentType === 'recp' &&
				tab.id === 'CardPayMethodPage' &&
				value !== 'C' &&
				value !== ''
			) {
				// 카드이체 제한, 은행이체 허용
				this.$alert({
					message: '카드이체는 정기결제정보로 설정이 불가합니다.'
				})
			}
		}
	}
}
</script>
