<template>
	<v-dialog
		v-model="open"
		@click:outside="clickOutside"
		:persistent="persistent"
		:overlay-opacity="0.8"
		:overlay-color="'#000'"
		:content-class="('alert', 'u-pop-titleType')"
	>
		<v-card>
			<v-card-title
				class="tit_system"
				v-if="dialogTitle"
				v-html="dialogTitle"
			/>
			<v-card-text v-if="dialogText" v-html="dialogText" />
			<slot name="subText" />
			<v-card-actions class="">
				<v-spacer />

				<v-btn
					v-if="subButtonText"
					@click="clickSubButton"
					color="primary"
					text
					class="btn_sub"
					:class="{ btn_all: singleButton }"
				>
					{{ subButtonText }}
				</v-btn>
				<v-btn
					v-if="buttonText"
					@click="clickButton"
					color="primary"
					text
					class="btn_confirm"
					:class="{ btn_all: singleButton }"
				>
					{{ buttonText }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'AAlert',
	props: {
		dialogTitle: String,
		dialogText: String,
		buttonText: String,
		subButtonText: String,
		persistent: Boolean
	},
	computed: {
		singleButton() {
			return !(this.buttonText && this.subButtonText)
		}
	},
	data: () => ({
		open: true
	}),
	methods: {
		clickButton() {
			this.$emit('confirm')
		},
		clickSubButton() {
			this.$emit('cancel')
		},
		clickOutside() {
			if (!this.persistent) this.$emit('cancel')
		}
	}
}
</script>
