import i18n from '@/plugins/i18n'

/**
 * 필수 입력값
 * @param {*} value 대상 값
 */
export const required = value => {
	return !!value || i18n.t('common.rules.required')
}

/**
 * 최대 입력 가능 글자 수
 * @param {*} value 대상 값
 * @param {number} length 길이
 */
export const maxLength = (value, length = 50) => {
	return (
		(!!value && value.length <= length) ||
		i18n.t('common.rules.maxLength', [length])
	)
}

/**
 * 최소 입력 가능 글자 수
 * @param {*} value 대상 값
 * @param {number} length 길이
 */
export const minLength = (value, length = 5) => {
	return (
		(!!value && value.length >= length) ||
		i18n.t('common.rules.minLength', [length])
	)
}
/**
 * 고유번호 입력
 * 최소 입력 가능 글자 수
 * @param {*} value 대상 값
 * @param {number} length 길이
 */
export const minSafetyLength = (value, length = 5) => {
	return (
		(!!value && value.length >= length) ||
		i18n.t('common.rules.minSafetyLength', [length])
	)
}
/**
 * 휴대폰번호 11자리
 * @param {*} value 대상 값
 * @param {number} length 길이
 */
export const phoneLength = (value, length = 5) => {
	return (
		(!!value && value.length == length) ||
		i18n.t('common.rules.phoneLength', [length])
	)
}
/**
 * 전화번호 형식(휴대폰)
 * @param {*} value 대상 값
 */
export const phoneNumber = value => {
	return regex(
		value,
		/^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/,
		i18n.t('common.rules.phoneNumber')
	)
}

/**
 * 전화번호 형식(집전화)
 * @param {*} value 대상 값
 */
export const tellNumber = value => {
	return regex(
		value,
		/^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/,
		i18n.t('common.rules.tellNumber')
	)
}

/**
 * 숫자만 입력 가능
 * @param {*} value 대상 값
 */
export const numeric = value => {
	return regex(value, /^[0-9]*$/, i18n.t('common.rules.numeric'))
}

/**
 * 영문, 숫자
 * @param {*} value 대상 값
 */
export const alphaNumeric = value => {
	return regex(value, /^[a-zA-Z0-9]*$/, i18n.t('common.rules.alphaNumeric'))
}

/**
 * 영문 대문자, 숫자
 * @param {*} value 대상 값
 * @param {*} text 가이드문구에 포함될 텍스트
 */
export const upperCaseAlphaNumeric = (value, text) => {
	return regex(
		value,
		/^[A-Z0-9]*$/,
		i18n.t('common.rules.upperCaseAlphaNumeric', [text])
	)
}

/**
 * 동일 여부 (String 기반으로 비교)
 * @param {*} value 대상 값
 * @param {*} equalTo 같아야 하는 값
 * @param {*} text UI에 출력 될 `equalTo` 텍스트(i18n 사용)
 */
export const sameAs = (value, equalTo, text) => {
	return (
		String(value) === String(equalTo) || i18n.t('common.rules.sameAs', [text])
	)
}

/**
 * email 형식
 * @param {*} value 대상 값
 */
export const email = value => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return regex(String(value).toLowerCase(), re) || i18n.t('common.rules.email')
}

/**
 * IPv4 형식
 * @param {*} value 대상 값
 */
export const ipV4Address = value => {
	const re = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
	return regex(value, re, i18n.t('common.rules.ipv4Address'))
}

/**
 * IPv6 형식
 * @param {*} value 대상 값
 */
export const ipV6Address = value => {
	const re = /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/
	return regex(value, re, i18n.t('common.rules.ipv6Address'))
}

/**
 * 영문, 숫자, 특수문자 포함(minLength, maxLength)
 * @param {*} value 대상 값
 * @param {number} minLength 최소 길이
 * @param {number} maxLength 최대 길이
 */
export const strongPassword = (value, minLength = 6, maxLength = 30) => {
	const re = new RegExp(
		`^(?=.*[a-zA-Z])(?=.*[!@#$%^~*+=-])(?=.*[0-9]).{${minLength},${maxLength}}$`
	)
	return regex(
		value,
		re,
		i18n.t('common.rules.strongPassword', [minLength, maxLength])
	)
}

// 사업자 입력 페이지 휴대폰 번호 유효성 검사
export const bizPhoneCheck = (value, length = 11) => {
	if (String(value).length === 0 || (!!value && value.length < length))
		return `휴대폰번호 ${length}자리를 입력해 주세요.`

	return regex(value, /^01/, `휴대폰번호 형식으로 입력해 주세요.`)
}

/**
 * 표현식
 * @param {*} value 대상 값
 * @param {RegExp} regExp 표현식
 * @param {String} msg 메시지
 */
export const regex = (value, regExp, msg) => {
	return regExp.test(String(value)) || msg
}
