import Vue from 'vue'
import VueRouter from 'vue-router'
import order from './order.router' // 주문
import esign from './esign.router' // 전자서명 (alert, 다운로드 등)
//import main from './main.router'
// import subscription from './subscription.router'
import preContract from './preContract.router'
import store from '@/store'
import UiData from '@/utils/ui-data'
import { passCertification } from '../utils/common'
import i18n from '../plugins/i18n'

Vue.use(VueRouter)

const routes = [...order, ...esign, ...preContract]

//운영일때 /order/main 접근불가
// if (process.env.VUE_APP_DEBUG_MODE) {
// 	routes.push(...main)
// }

routes.push(
	{
		path: '/error/:errorCode',
		name: 'error',
		component: () => import('@/views/error/ErrorPage.vue'),
		meta: {
			isPublic: true,
			hideAppBar: true
		}
	},
	// 공통으로 사용하는 안내페이지
	{
		path: '/guide',
		name: 'guide',
		component: () => import('@/views/common/Guide.vue'),
		meta: {
			isPublic: true,
			showWebHeader: true,
			hideAppBar: true
		}
	},
	// 공통으로 사용하는 점검페이지
	{
		path: '/info',
		name: 'info',
		component: () => import('@/views/common/ServiceInfo.vue'),
		meta: {
			isPublic: true,
			hideAppBar: true
		}
	},
	{
		path: '/*',
		name: 'notFound',
		redirect: '/error/404'
	}
)

// 배포 모드에 따라 타이틀에 구분값 추가
// 운영의 경우 구분값 출력 안함
let nodeTitle = ''
const envNode = process.env.VUE_APP_DEV_MODE
if (envNode === 'development') {
	nodeTitle = '[개발] '
} else if (envNode === 'blue') {
	nodeTitle = '[검증] '
} else {
	nodeTitle = ''
}

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		return savedPosition ? savedPosition : { x: 0, y: 0 }
	}
})

router.beforeEach((to, from, next) => {
	passCertification(to, from).then(async res => {
		const RES_CODE = res.code
		// 주문 접수, 주문 현황으로 진입시 권한 체크 진행
		if (RES_CODE === '0') {
			// 정상
			next()
		} else if (RES_CODE === '1') {
			// 점검중
			if (to.name === 'info') {
				next()
			} else {
				// 점검중
				let params = UiData.getGuideData('siteNotice')
				// 아래 시간은 추후 API에서 리턴주는 시간으로 적용예정
				params.start = res.start
				params.end = res.end
				store.commit('common/setGuideData', params)
				next({ name: 'info' })
			}
		} else if (RES_CODE === '2') {
			// 파일럿 대상이 아님
			const params = UiData.getGuideData('notPilot')
			store.commit('common/setGuideData', params)
			next({ name: 'guide', query: to.query })
		} else if (RES_CODE === '3') {
			// 판매인 확인 불가
			const params = UiData.getGuideData('NotSeller')
			store.commit('common/setGuideData', params)
			next({ name: 'guide', query: to.query })
		} else if (RES_CODE === '4' || RES_CODE === '5') {
			// 사전예약 or 사전계약 재랜탈 주문현황 CL : 코디(cody), HC : 홈케어(home)가 아닌 경우 사용 불가
			const title =
				RES_CODE === '4'
					? i18n.t('subscription.not-depth-code-title')
					: i18n.t('preContract.not-depth-code-title')
			const msg =
				RES_CODE === '4'
					? i18n.t('subscription.not-depth-code-msg')
					: i18n.t('preContract.not-depth-code-msg')
			const params = {
				title: title,
				msg: msg
			}
			store.commit('common/setGuideData', params)
			next({ name: 'guide', query: to.query })
		}
	})
})

router.afterEach(() => {
	Vue.nextTick(() => {
		document.title = `${nodeTitle} 모바일 주문`
	})
})

export default router
