import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ko from '@/locales/ko'
import log from '../utils/log'

Vue.use(VueI18n)

const messages = {
	ko
}

export default new VueI18n({
	locale: process.env.VUE_APP_I18N_LOCALE || 'en',
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
	messages,
	missing: (locale, key) => {
		log(
			`%cI18N MISSING KEY!` + `%c requested: ${key}`,
			'background: cyan; color: black; font-weight: bold;',
			'font-weight: bold;'
		)
		return ''
	}
})
