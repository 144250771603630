const state = {
	mobOrderNo: '', // 웹주문번호
	customer: {
		userKey: '', // 고객인증 userKey
		custSelf: false, // true: 고객 직접 입력 / false: 코디 대행 입력
		custNo: '',
		custSafekey: '',
		custType: '', // 고객구분(개인: P / 개인사업자: L / 법인)
		name: '',
		phone: '',
		bizName: '', // 개인사업자 상호(본인 인증 대행)
		bizNo: '', // 개인사업자번호
		ssn: '', // 생년월일
		custPassInfo: {}, // PASS RESULT
		custInfo: {}, // RFC RESULT
		custUpdateInfo: {}, // RFC CREATE&UPDATE RESULT
		custParams: {}, // RFC PARAMS,
		custAddParams: {}, // 개인사업자 정보 추가입력
		custInputParams: {}, // 개인사업자 정보 직접입력
		custDocInfo: {}, // 개인사업자 사본 업로드
		codyMatch: '', //코디매칭 대상 여부
		orderType: '' // 주문타입(일반, 청약)
	}
}

const getters = {
	mobOrderNo: state => state.mobOrderNo,
	customer: state => state.customer,
	userKey: state => state.customer.userKey,
	custSelf: state => state.customer.custSelf,
	custNo: state => state.customer.custNo,
	custSafekey: state => state.customer.custSafekey,
	customerType: state => state.customer.custType,
	custName: state => state.customer.name,
	custPhone: state => state.customer.phone,
	bizName: state => state.customer.bizName,
	bizNo: state => state.customer.bizNo,
	ssn: state => state.customer.ssn,
	custPassInfo: state => state.customer.custPassInfo,
	custInfo: state => state.customer.custInfo,
	custUpdateInfo: state => state.customer.custUpdateInfo,
	custParams: state => state.customer.custParams,
	custAddParams: state => state.customer.custAddParams,
	custInputParams: state => state.customer.custInputParams,
	custDocInfo: state => state.customer.custDocInfo,
	codyMatch: state => state.customer.codyMatch
}

const actions = {
	async setMobOrderNo({ commit }, value) {
		commit('setMobOrderNo', value)
	},
	async setCustomer({ commit }, value) {
		commit('setCustomer', value)
	},
	async setCustomerStore({ commit }, value) {
		commit('setCustomerStore', value)
	},
	async setUserKey({ commit }, value) {
		commit('setUserKey', value)
	},
	async setCustomerSelf({ commit }, value) {
		commit('setCustomerSelf', value)
	},
	async setCustomerNo({ commit }, value) {
		commit('setCustomerNo', value)
	},
	async setCustSafekey({ commit }, value) {
		commit('setCustSafekey', value)
	},
	async setCustPassInfo({ commit }, value) {
		commit('setCustPassInfo', value)
	},
	async setCustInfo({ commit }, value) {
		commit('setCustInfo', value)
	},
	async setCustUpdateInfo({ commit }, value) {
		commit('setCustUpdateInfo', value)
	},
	async setCustParams({ commit }, value) {
		commit('setCustParams', value)
	},
	async setCustAddParams({ commit }, value) {
		commit('setCustAddParams', value)
	},
	async setCustInputParams({ commit }, value) {
		commit('setCustInputParams', value)
	},
	async setCustDocInfo({ commit }, value) {
		commit('setCustDocInfo', value)
	},
	async setCodyMatch({ commit }, value) {
		commit('setCodyMatch', value)
	}
}

const mutations = {
	setCustomer(state, value) {
		state.customer = { ...state.customer, ...value }
		sessionStorage.setItem('customer', JSON.stringify(value))
	},
	setCustomerStore(state, value) {
		state.customer = { ...state.customer, ...value }
	},
	setMobOrderNo(state, value) {
		state.mobOrderNo = value
	},
	setCustomerSelf(state, value) {
		state.customer.custSelf = value
	},
	setUserKey(state, value) {
		state.customer.userKey = value
	},
	setCustomerNo(state, value) {
		state.customer.custNo = value
	},
	setCustSafekey(state, value) {
		state.customer.custSafekey = value
	},
	setCustPassInfo(state, value) {
		state.customer.custPassInfo = { ...state.customer.custPassInfo, ...value }
		state.customer.ssn = value.birYMD
	},
	setCustInfo(state, value) {
		state.customer.custInfo = { ...state.customer.custInfo, ...value }
		state.customer.custNo = value.KUNNR
		if (value.SAFEKEY) {
			state.customer.custSafekey = value.SAFEKEY
		}
	},
	setCustUpdateInfo(state, value) {
		state.customer.custUpdateInfo = {
			...state.customer.custUpdateInfo,
			...value
		}
		state.customer.custNo = value.KUNNR
		if (value.SAFEKEY) {
			state.customer.custSafekey = value.SAFEKEY
		}
	},
	setCustParams(state, value) {
		state.customer.custParams = { ...state.customer.custParams, ...value }
	},
	setCustAddParams(state, value) {
		state.customer.custAddParams = { ...state.customer.custAddParams, ...value }
		sessionStorage.setItem('custAddParam', JSON.stringify(value))
	},
	setCustInputParams(state, value) {
		state.customer.custInputParams = {
			...state.customer.custInputParams,
			...value
		}
		sessionStorage.setItem('custInputParams', JSON.stringify(value))
	},
	setCustDocInfo(state, value) {
		state.customer.custDocInfo = { ...state.customer.custDocInfo, ...value }
	},
	setCodyMatch(state, value) {
		state.customer.codyMatch = value.codyMatch
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
