<template>
	<div>
		<v-bottom-sheet v-model="seen" scrollable :persistent="persistent">
			<template v-slot:activator="{ attrs }" v-if="!hideButton">
				<v-btn
					class="btn_select_type"
					height="48px"
					depressed
					outlined
					@click="open"
					v-bind="attrs"
					:disabled="disabled"
				>
					<slot name="default" />
				</v-btn>
			</template>
			<h2 class="tit">{{ title }}</h2>
			<div class="scr_area">
				<v-btn-toggle
					class="btn_bottom_sheet"
					v-model="selected"
					:mandatory="mandatory"
					@change="onChange"
				>
					<v-btn
						:ripple="false"
						v-for="(item, i) in items"
						:key="`item-${i}`"
						@click="onClick(i)"
					>
						{{ item.text }}
					</v-btn>

					<!-- 렌탈 등록비 선납 할인 선택 예시 문구  -->
					<div class="u-pop-message" v-if="showType(product)">
						<ul>
							<li>
								<p class="info">
									렌탈료를 선납하는 경우, 할인 적용 됩니다.
									<br />(부가서비스, 별매상품 포함) <br />예시 : 1년
									<br />렌탈료 : {{ preAmt.preAmtTotal.toLocaleString() }}원 ({{
										preAmt.preAmtMonth.toLocaleString()
									}}원 x 12개월{{ preAmt.preSaleMonth }})
								</p>
							</li>
							<li>
								<p class="discout">
									할인금액 : -{{ preAmt.preAmtSaleTotal.toLocaleString() }}원
								</p>
							</li>
							<li>
								<p class="prepay">
									선납금액 : {{ preAmt.preAmt.toLocaleString() }}원
								</p>
							</li>
						</ul>
					</div>
					<!--// 렌탈 등록비 선납 할인 선택 예시 문구 -->
				</v-btn-toggle>
			</div>
		</v-bottom-sheet>
	</div>
</template>

<script>
/**
 * 선납할인 선택 화면이다.
 * @description PayPageSale.vue 화면에서 사용중이다.
 */
import { getPreAmt } from '@/apis/order.pay.api'
import moment from 'moment'

export default {
	name: 'PopMessageBottomSheet',
	props: {
		value: [String, Number],
		product: {
			type: Object,
			default: () => {
				return []
			}
		},
		prdMonthTotal: [String, Number],
		text: String,
		title: String,
		persistent: Boolean,
		hideButton: Boolean,
		disabled: { type: Boolean, default: false }
	},

	computed: {
		displayText() {
			return this.text
		},

		selected: {
			get() {
				return this.items.findIndex(x => x.code === this.value)
			},
			set(value) {
				if (value !== undefined) {
					this.$emit('input', this.items[value].code)
				}
			}
		},

		mandatory() {
			return this.items.some(x => x.code === this.value)
		}
	},

	data() {
		return {
			seen: false,
			items: [],
			preAmt: {
				preAmtTotal: 0,
				preAmt: 0,
				preAmtSaleTotal: 0,
				preAmtMonth: 0,
				preSaleMonth: 0
			}
		}
	},

	methods: {
		async open() {
			await this.preAmtInfo()
			await this.downRentalPrepaySelectedCheck()
			// this.seen = true
		},
		downRentalPrepaySelectedCheck() {
			if (
				this.product.payOptions.downRentalPrepayAmt !== '0' &&
				this.product.payOptions.downRentalPrepayAmt !== undefined
			) {
				this.seen = false
				this.$alert({
					message:
						'선택되어있는 할인제도와 중복적용 할 수 없습니다. 판매 정책을 확인해주세요'
				})
			} else {
				this.seen = true
			}
		},

		// 출력여부
		showType(product) {
			let result = true
			if (
				product.payOptions.mattressInfo &&
				product.payOptions.mattressInfo.selectMattress !== null &&
				(product.payOptions.mattressInfo.typeCd === 'M' ||
					product.payOptions.mattressInfo.typeCd === 'MF')
			) {
				result = false
			} else {
				result = true
			}
			return result
		},

		// 요청한 개월수에 해당하는 선납할인 금액 정보를 전달
		diplayedPreAmtYear(value) {
			const preAmtYear = this.items.filter(v => v.code === value)[0]

			const preAmtMonth = parseFloat(preAmtYear.MON_FI_AMT) // 월 렌탈료
			const preAmtTotal =
				parseFloat(preAmtYear.MON_FI_AMT) * parseFloat(preAmtYear.PRE_MONTH2) // 전체 렌탈료
			const preAmt = parseFloat(preAmtYear.RECP_AMT2) // 선납금액
			const preAmtSaleTotal = parseFloat(preAmtTotal - preAmt) // 선납할인 금액
			let preSaleMonth = '' // 개월차 면제 적용에 따른 출력 문구

			const saleMonth =
				parseFloat(preAmtYear.PRE_MONTH) - parseFloat(preAmtYear.PRE_MONTH2)
			if (saleMonth !== 0) {
				preSaleMonth = ` - ${saleMonth}개월치`
			}

			this.preAmt = {
				preAmtTotal,
				preAmt,
				preAmtSaleTotal,
				preAmtMonth,
				preSaleMonth
			}
		},

		async preAmtInfo() {
			// SAP에 선납 할인 정보 요청
			this.items = []
			this.items = [
				{
					code: '01',
					text: '선납 할인 선택 없음',
					preAmt: 0,
					monthAmt: 0,
					RECP_AMT2: 0,
					PRE_MONTH: '01'
				}
			]

			// 침대 패키지로 묶인 상품은 선납할인 불가
			if (
				!this.product.payOptions.mattressInfo ||
				this.product.payOptions.mattressInfo.selectMattress === null
			) {
				const p = this.product
				const selectedDate = moment(new Date()).format('YYYYMMDD')

				// 선납금액 요청
				// 파라메터는 랜탈료 선납계산 RFC를 참고
				const res = await getPreAmt({
					I_MON_FI_AMT: this.prdMonthTotal,
					I_PRM_CODE: p.payOptions.promotion,
					I_SALE_ID: p.esProd.SALE_ID,
					I_PLACE_ID: 2,
					I_LESGB: p.esProd.LESGB,
					I_GOODS_CD: p.esProd.GOODS_CD,
					I_REGI_GRADE: p.esProd.REGI_GRADE,
					I_REGI_ID: p.esProd.REGI_ID,
					I_ORDER_DT: selectedDate,
					I_PRE_COUNT:
						p.options.preOrder !== undefined
							? p.options.preOrder.PRE_COUNT
							: '',
					I_PACKAGE_ID:
						p.payOptions.packActive ||
						p.payOptions.festaActive ||
						p.payOptions.fifteenActive
							? p.payOptions.ET_INPUT.PACKAGE_ID
							: '',
					I_USE_TP: p.esProd.USE_TP,
					I_RENTAL_YEARS: p.esProd.OWNER,
					I_COMBI_DC_RATE: p.payOptions.combiActive
						? p.payOptions.ET_INPUT.DC_RATE
						: '0.00',
					I_GOODS_CD_002:
						p.options.prdOption2 && p.options.prdOption2.CONTRACT_ID === '2'
							? p.options.prdOption2.code
							: '',
					I_ADSVC: p.options.prdOption1?.ADSVC || ''
				})

				if (res) {
					const preAmtInfo = res.resultObject.data.T_PRE_AMT
					preAmtInfo.forEach(v => {
						this.items.push({
							code: v.PRE_MONTH,
							text: `${v.PRE_MONTH / 12}년`,
							...v
						})
					})

					this.$log('items:', this.items)

					this.$set(p.payOptions, 'T_PRE_AMT', preAmtInfo)
					this.diplayedPreAmtYear('12')
				}
			}
		},

		onChange(e) {
			this.$log('TEST', e)
			this.$emit('item-selected', this.items[e])
			this.seen = false
		},

		onClick(e) {
			if (e === this.selected) {
				this.onChange(e)
			}
		}
	}
}
</script>
