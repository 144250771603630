<template>
	<v-container>
		<v-card flat>
			<v-card-title v-if="title">{{ title }}</v-card-title>
		</v-card>
		<v-card>
			<v-data-table
				:headers="headers"
				:loading="loading"
				:show-select="showSelect"
				:mobile-breakpoint="mobileBreakpoint"
				:hide-default-footer="true"
				:hide-default-header="hideHeaders"
				:calculate-widths="true"
				:class="headerCssClass"
				disable-pagination
				@update:options="handleUpdateOptions"
			>
				<template v-slot:body />
			</v-data-table>
			<div v-if="loading">
				<v-progress-linear indeterminate absolute />
			</div>
			<div v-if="items.length < 1">
				<div class="pa-2 text-center grey--text">
					{{ $t('$vuetify.noDataText') }}
				</div>
			</div>
			<div v-else>
				<v-virtual-scroll
					:bench="pageSize"
					:items="items"
					:height="height"
					:item-height="itemHeight"
					@scroll="loadMoreData"
				>
					<template v-slot:default="{ item }">
						<slot name="items" :item="item">
							<v-row class="scroll-table" no-gutters @click="clickRow(item)">
								<v-col
									v-for="header in headers"
									:key="header.title"
									class="pa-2 scroll-table-cell"
									:style="{ width: header.width }"
								>
									<slot :name="`item.${header.value}`" :item="item">
										{{ item[header.value] }}
									</slot>
								</v-col>
							</v-row>
						</slot>
					</template>
				</v-virtual-scroll>
			</div>
		</v-card>
	</v-container>
</template>

<script>
export default {
	props: {
		title: String,
		headers: {
			type: Array,
			required: true
		},
		items: {
			type: Array,
			required: true
		},
		loading: {
			type: Boolean,
			required: true
		},
		currentPage: {
			type: Number,
			required: true
		},
		pageSize: {
			type: Number,
			required: true
		},
		showSelect: {
			type: Boolean,
			default: false
		},
		mobileBreakpoint: {
			type: Number,
			default: 200
		},
		height: Number,
		itemHeight: {
			type: Number,
			default: 40
		},
		headerCssClass: String,
		hideHeaders: Boolean
	},
	data() {
		return {
			renderedPage: []
		}
	},
	watch: {
		currentPage() {
			this.renderedPage.push(this.currentPage)
		}
	},
	methods: {
		// sorting 변경: {key: 'key명', isDesc: 'true|false'}
		handleUpdateOptions(payload) {
			if (payload.sortBy) {
				const sortArr = []
				payload.sortBy.forEach((value, index) => {
					sortArr.push({
						key: value,
						isDesc: payload.sortDesc[index]
					})
				})
				this.$emit('updateOptions', sortArr)
			}
		},
		// scroll to bottom
		nextPage() {
			this.$emit('nextPage', this.currentPage + 1)
		},
		getNextTargetPage(currentTarget) {
			return this.renderedPage.indexOf(currentTarget) > -1
				? this.getNextTargetPage(currentTarget + 1)
				: currentTarget
		},
		loadMoreData(e) {
			const { offsetHeight, scrollTop, scrollHeight } = e.target
			if (offsetHeight + scrollTop === scrollHeight) {
				this.$emit('nextPage', this.getNextTargetPage(this.currentPage + 1))
			}
		},
		clickRow(item) {
			this.$emit('clickRow', item)
		}
	},
	mounted() {
		this.renderedPage.push(this.currentPage)
		window.vm = this
	}
}
</script>

<style scoped>
.scroll-table {
	display: table;
	table-layout: fixed;
	width: 100%;
	border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.scroll-table-cell {
	/* .v-data-table > .v-data-table__wrapper > table > tbody > tr > td */
	font-size: 0.875rem;
	display: table-cell;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.scroll-table-cell-mobile-header {
	font-size: 0.875rem;
}
.scroll-table-cell-mobile-value {
	font-size: 0.875rem;
}
</style>
