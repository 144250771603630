/**
 * 사전계약 API
 */

import api from '@/apis/config'
const { get, post } = api

/**
 * 사전계약 고객/주문 목록
 * @param {String} saleCd
 */
export const getPreContractData = async params => {
	const response = await get(`/v1/11/01`, { ...params })
	return response
}

/**
 * 기존계정 관리판매인 체크
 * @param {String} params
 */
export const getPreContractSalesChk = async params => {
	const response = await post(`/v1/11/02`, { ...params })
	return response
}

/**
 * 사전계약 고객/주문 목록
 * @param {String} orderNo
 */
export const getPreContractPayMehdCechk = async params => {
	const response = await post(`/v1/11/03`, { ...params })
	return response
}
