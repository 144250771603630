<template>
	<v-btn @click="seen = true" v-if="text"
		>{{ text }}<v-icon v-if="showDropDown" icon>mdi-menu-down</v-icon>
		<v-bottom-sheet v-model="seen" scrollable>
			<h2 class="tit">{{ title }}</h2>
			<div class="scr_area">
				<v-btn-toggle class="btn_bottom_sheet" v-model="selected" multiple>
					<v-btn v-for="(item, i) in items" :key="`item-${i}`" :value="item">
						{{ item.text }}
					</v-btn>
				</v-btn-toggle>
			</div>
			<v-row class="justify-center">
				<v-btn color="default" class="mr-3" large @click="seen = false">
					닫기</v-btn
				>
				<v-btn color="primary" large @click="close">적용</v-btn>
			</v-row>
		</v-bottom-sheet>
	</v-btn>
</template>

<script>
export default {
	props: {
		// value: Boolean,
		items: {
			type: Array,
			default: () => {
				return []
			}
		},
		multiple: {
			type: Boolean,
			default: false
		},
		text: String,
		title: String,
		showDropDown: Boolean
	},
	computed: {
		mandatory() {
			return !!this.selected
		}
	},
	data() {
		return {
			seen: false,
			selected: []
		}
	},
	methods: {
		open() {
			this.seen = true
		},
		onChange(value) {
			this.close(value)
		},
		close() {
			this.$log('selected:', this.selected)
			this.$emit('item-selected', this.selected)
			this.seen = false
		}
	}
}
</script>

<style></style>
