import Vue from 'vue'
const c = {
	MAX_CUST_NAME_LENGTH: 40
}

export default c

const Constants = {
	install(Vue) {
		Vue.prototype.$Constants = c
		Vue.$Constants = c
	}
}

Vue.use(Constants)
