export default {
	'order-main': '전자결제 메인',
	'order-pay': '모바일 주문',
	'order-verify': '모바일 주문',

	'verify-choice': '모바일 주문',
	'verify-self': '모바일 주문',
	'verify-result': '모바일 주문',
	'verify-bizform': '모바일 주문',
	'verify-adddoc': '모바일 주문',

	'verify-cust-self': '모바일 주문',
	'verify-cust-adddoc': '모바일 주문',
	'verify-cust-expired': '모바일 주문',
	'verify-cust-deleted': '모바일 주문',
	'verify-cust-complete': '모바일 주문',
	'verify-cust-uncomplete': '모바일 주문',

	'prd-search': '모바일 주문',
	'prd-detail': '모바일 주문',
	'recp-method': '모바일 주문',
	'order-status': '모바일 주문',
	'common-popup': '공통 - 팝업가이드',
	'order-inst': '모바일 주문'
}
