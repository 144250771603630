<template>
	<v-dialog
		v-model="dialog"
		:max-width="options.width"
		@keydown.esc="cancel"
		scrollable
		:persistent="options.persistent"
		fullscreen
	>
		<div class="full_dialog_header">
			<h2 class="tit">반환정보 확인</h2>
			<v-btn depressed icon plain class="btn_right" @click="close"
				><v-icon dark>
					mdi-window-close
				</v-icon></v-btn
			>
		</div>
		<div class="dialog_all_page pa-6" style="height: calc(100vh - 50px);">
			<div class="txt_box">
				<h1 class="tit_page mb-2">수납정보</h1>
				<p class="txt_body1 mb-4 ">
					<span class="txt_warn fln"
						>※ 해당 제품을 설치할 때 반납처리 됩니다.</span
					>
				</p>
			</div>
			<div class="line_box type_01">
				<dl v-for="(item, index) in items" :key="index">
					<dt>{{ item.RECP_TP === '30' ? '총 수납 금액' : item.RECP_NM }}</dt>
					<dd :class="['tar', item.RECP_TP === '30' ? 'text_red' : '']">
						{{ item.RECV_AMT | comma }}원
					</dd>
				</dl>
			</div>
			<div class="bottom_box">
				<v-btn
					color="primary"
					class="col_1_small"
					@click="ok"
					:disabled="disableReturnAcpt"
				>
					반환 접수
				</v-btn>
			</div>
		</div>
	</v-dialog>
</template>
<script>
import filters from '@/mixins/filters'
import { returnReception } from '@/apis/order.prd.api'
export default {
	mixins: [filters],
	data: () => ({
		dialog: false,
		resolve: null,
		reject: null,
		options: {
			persistent: false,
			cancelOrder: false
		},
		res: false,
		orderNo: '',
		userId: '',
		mobOrderNo: '',
		items: [],
		disableReturnAcpt: false
	}),

	computed: {
		returnPenaltyAmt() {
			const rpaItem = this.items.find(x => x.RECP_TP === '30')
			if (rpaItem) {
				return +rpaItem.RECV_AMT
			}

			return 0
		}
	},

	methods: {
		async open(options) {
			this.disableReturnAcpt = false
			this.options = Object.assign(this.options, options)
			this.dialog = true
			this.res = await this.fetchReturnPenaltyItems('D')
			this.$log('반환정보 확인 RES ===>>>', this.res)

			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},

		async ok() {
			this.res
				? this.resolve({ ...this.res, returnPenaltyAmt: this.returnPenaltyAmt })
				: this.resolve(false)
			this.dialog = false
		},

		close() {
			this.resolve(false)
			this.dialog = false
		},

		async fetchReturnPenaltyItems(actType = 'D') {
			let ok = true
			const { orderNo, userId, mobOrderNo, contractId } = this.options
			const res = await returnReception({
				actType,
				orderNo,
				userId,
				mobOrderNo,
				contractId
			})

			if (res.code === 200) {
				const { E_RETURN } = res.resultObject
				if (actType === 'D') {
					const { data } = res.resultObject
					this.items = data.filter(d => +d.RECV_AMT > 0) || []
					//수납해야할 금액이 0원인 경우, 수납해야할 금액 0원만 표시 (나머지 위에 배치한 항목 전부 미표시)
					if (data.find(x => x.RECP_TP === '30' && x.RECV_AMT === '0')) {
						this.$log('item', this.items)
						this.items.splice(0)
						this.items = [{ RECP_TP: '30', RECV_AMT: '0' }]
					}

					if (E_RETURN && E_RETURN.TYPE === 'F') {
						this.disableReturnAcpt = true
						this.$alert({ message: E_RETURN.MESSAGE })
						return false
					}
				}
				if (actType === 'I') {
					this.disableReturnAcpt = true
					this.$alert({ message: E_RETURN.MESSAGE })
					if (E_RETURN && E_RETURN.TYPE === 'F') {
						return false
					}
				}
			} else {
				this.disableReturnAcpt = true
				return false
			}

			return ok
		}
	}
}
</script>
