const state = {
	userKey: ''
}

const getters = {
	userKey: state => state.userKey
}

const actions = {
	async setUserKey({ commit }, value) {
		commit('setUserKey', value)
	}
}

const mutations = {
	setUserKey(state, value) {
		state.userKey = value
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
