import api from '@/apis/config'
const { post, get, del } = api

// import log from '@/utils/log'
export const fetchPeriodicAccounts = async user => {
	const response = await post('v1/04/01', {
		...user
	})
	return response
}

/**
 * 수납방법선택
 * @param {*} user
 */
export const fetchRecpType = async params => {
	const response = await post('v1/04/02', {
		...params
	})
	return response
}

/**
 * 은행즉시 이체
 * @param {Object} 은행계좌 정보
 */
export const bankInstantTransfer = async bank => {
	const response = await post('v1/04/03', {
		...bank
	})
	return response
}

/**
 * 카드이체 - 정기결제
 * @param {Object} 카드이체 정보
 */
export const cardTransfer = async card => {
	const response = await post('v1/04/04', {
		...card
	})
	return response
}

/**
 * 카드이체 - 기타결제
 * @param {Object} 카드이체 정보
 */
export const cardTransferEtc = async card => {
	const response = await post('v1/04/05', {
		...card
	})
	return response
}

/**
 * 가상계좌 생성
 * @param {Object} 가상계좌 정보
 */
export const vaccountInstantCreate = async vaccount => {
	const response = await post('v1/04/06', {
		...vaccount
	})
	return response
}

/**
 * 가상계좌 수납
 * @param {Object} 가상계좌 정보
 */
export const vaccountInstantTransfer = async vaccount => {
	const response = await post('v1/04/07', {
		...vaccount
	})
	return response
}

/**
 * 카드이체 - 복합결제
 * @param {Object} 카드이체 정보
 */
export const complexCardTransfer = async card => {
	const response = await post('v1/04/08', {
		...card
	})
	return response
}

/**
 * 가상계좌 수납 - 복합결제
 * @param {Object} 가상계좌 정보
 */
export const complexVaccountInstantTransfer = async vaccount => {
	const response = await post('v1/04/09', {
		...vaccount
	})
	return response
}

/**
 * 복합결제 수납
 * @param {Object} 복합결제 수납 - 최종
 */
export const complexTransfer = async vaccount => {
	const response = await post('v1/04/10', {
		...vaccount
	})
	return response
}

/**
 * 타인명의 카드이체 가능여부 조회
 * @param {String} mobOrderNo: 웹주문번호
 */
export const canChangeCardPayOwner = async mobOrderNo => {
	const response = await get(`v1/04/11/${mobOrderNo}`)
	return response
}

/**
 * 가상계좌 납부 정보, 납부완료 정보, 고객 수납안내
 * @param {*}
 */
export const receiptAlim = async params => {
	const response = await post('v1/04/12', {
		...params
	})
	return response
}

/**
 * 정기결제정보 목록 조회
 * @param {String} webOrderNo: 웹주문번호
 * @returns
 */
export const paymentInfo = async (webOrderNo, params) => {
	const response = await get(`v1/04/13/${webOrderNo}`, { ...params })
	return response
}

/**
 * 결제수단(카드) 추가 or 검증
 * @param {String} webOrderNo: 웹주문번호
 * @returns
 */
export const cardChk = async (webOrderNo, params) => {
	const response = await post(`v1/04/14/${webOrderNo}`, { ...params })
	return response
}

/**
 * 결제수단(은행) 추가 or 검증
 * @param {String} webOrderNo: 웹주문번호
 * @returns
 */
export const bankChk = async (webOrderNo, params) => {
	const response = await post(`v1/04/15/${webOrderNo}`, { ...params })
	return response
}

/**
 * 결제수단(카드, 은행) 삭제
 * @param {String} webOrderNo: 웹주문번호
 * @returns
 */
export const custPayDelete = async (webOrderNo, params) => {
	const response = await del(`v1/04/16/${webOrderNo}`, { ...params })
	return response
}

/**
 * 결제일 체크
 *
 */
export const getPayDate = async params => {
	const response = await post('v1/04/17', {
		...params
	})
	return response
}
