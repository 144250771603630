import store from '@/store'
import { fetchCody } from '@/apis/order.status.api'
import { serviceCheck, serviceType } from '@/apis/common.api'
import { requestCreateMenuToApp } from '@/utils/navigation'
import UiData from '@/utils/ui-data'

/**
 * coway home url
 */
export const coway = {
	url: process.env.VUE_APP_COWAY_HOME_URL
}

/**
 * 접근제한 체크
 * @param to 목적지 라우터 정보
 * @param from 이전 라우터 정보
 * @returns {Promise<{code: string, start: string, end: string, message: string}>}
 * @description code 응답코드
 * 				허용이 아닌 응답 코드일 경우 아래의 데이터가 세팅된다.
 * 				start 시작시간, end 종료시간, message 메세지
 */
export const passCertification = async (to, from) => {
	//eslint-disable-next-line no-console
	//console.log('to', to)
	//eslint-disable-next-line no-console
	//console.log('from', from)

	let result = {
		code: '', // 0: 정상, 1: 점검중 , 2: 파일럿 대상 아님, 3: 판매인 확인불가, 999: 기타
		message: '', // 점검, 차단시 메세지
		start: '', // 시작시간
		end: '' // 종료시간
	}

	let userInfo = {
		codyNo: '',
		type: to.query.type,
		app: !to.query.app
			? store.getters['common/app']
			: to.query.app.toLowerCase(),
		tabCode: !to.query.tabCode
			? store.getters['common/tabCode']
			: to.query.tabCode
	}

	// Query String에 user_no(판매인번호)가 있는 경우 복호화
	if (to.query && to.query.user_no && to.query.user_no !== '') {
		let userNoEnc = to.query.user_no
		userNoEnc = userNoEnc.replaceAll(' ', '+')
		userNoEnc = userNoEnc.replaceAll('+', '%2B')
		const appDevMode = process.env.VUE_APP_DEV_MODE === 'development'
		if (getStringLength(to.query.user_no) > 8) {
			// 8자리 이상 암호화된 코디번호
			await fetchCody(encodeURIComponent(userNoEnc)).then(async res => {
				if (res.code === 200) {
					userInfo.codyNo = res.resultObject.codyNo
				}
				// store, sessionStorage 저장
				const codyQuery = {
					codyNo: userInfo.codyNo,
					type: userInfo.type
				}
				store.dispatch('common/setUserInfo', codyQuery)
			})
		} else if (appDevMode && getStringLength(to.query.user_no) === 8) {
			// 8자리 암호화 되지 않은 코디 번호
			// store, sessionStorage 저장
			const codyQuery = {
				codyNo: to.query.user_no,
				type: to.query.type
			}
			store.dispatch('common/setUserInfo', codyQuery)
		} else {
			// 기타, 잘못된 코디 번호
			const codyQuery = {
				codyNo: '',
				type: ''
			}
			store.dispatch('common/setUserInfo', codyQuery)
		}
	} else if (to.query && to.query.userKey && to.query.userKey !== '') {
		const params = { userKey: to.query.userKey }
		await serviceType(params).then(async res => {
			if (res.code === 200) {
				userInfo.codyNo = res.resultObject.codyNo
				userInfo.type = res.resultObject.type
			}
		})
	} else {
		// Query String이 없으면 저장된 정보를 가저와 활용
		userInfo.codyNo = store.getters['common/codyNo']
		userInfo.type = store.getters['common/type']
	}

	if (
		userInfo.codyNo &&
		['', 'null', 'undefined', null, undefined].includes(userInfo.type)
	) {
		const params = { codyNo: userInfo.codyNo }
		await serviceType(params).then(async res => {
			if (res.code === 200) {
				userInfo.codyNo = res.resultObject.codyNo
				userInfo.type = res.resultObject.type
			}
		})
	}

	const codyQuery = {
		codyNo: userInfo.codyNo,
		codyPhoneNo: undefined,
		type: userInfo.type,
		app: userInfo.app,
		tabCode: userInfo.tabCode
	}
	store.dispatch('common/setUserInfo', codyQuery)

	// 스토어에 없을 경우 Query Sting 값을 가저온다. 판매인번호(userNo), 접근앱(type))
	const userNo = userInfo.codyNo
	const type = userInfo.type

	const subFromNotFormToReRental =
		from.name !== 'subscription-form' && to.name === 'subscription-re-rental'
	const preFronNotFormToList =
		from.name !== 'pre-form' && to.name === 'pre-contract-list'
	// 접근 체크
	if (
		to.name === 'cody-form' ||
		to.name === 'guide' ||
		to.name === 'subscription-form' ||
		to.name === 'pre-form'
	) {
		// 아래는 접근제한 없이 무조건 접근 허용
		result.code = '0'
		result.message = '정상'
	} else {
		if (from.name === 'subscription-form' || from.name === 'pre-form') {
			result.code = '0' // 성공
			result.message = '사전예약 코디폼을 통한 접근시 타입 없어도 접근 가능'
		} else if (
			// 사전예약 코디폼을 통한 접근이 아닌 경우
			// 목적지가 사전예약 재랜탈 목록의 경우
			// 코디, 홈케이가 아닌 경우
			(subFromNotFormToReRental || preFronNotFormToList) &&
			!['cody', 'home'].includes(type)
		) {
			result.code = subFromNotFormToReRental ? '4' : '5'
			result.message =
				'CL : 코디(cody), HC : 홈케어(home)가 아닌 경우 사용 불가'
		} else {
			if (
				userNo &&
				!['', 'null', 'undefined', null, undefined].includes(type)
			) {
				const params = {
					userNo: userNo,
					type: type
				}

				await serviceCheck(params).then(async res => {
					if (res.code === 200) {
						const resultCode = res.resultObject.orderAvailability.result
						const orderAvailability = res.resultObject.orderAvailability
						if (resultCode === '2') {
							// 파일럿 대상이 아님
							result.code = '2'
							result.message = '파일럿 대상 아님'
						} else if (resultCode === '1') {
							// 점검중
							result.code = '1'
							result.message = '점검중'
							result.start = orderAvailability.start
							result.end = orderAvailability.end
						} else {
							// 판매인 번호는 있고, 타입이 없는 경우 허용
							if (type === 'cody') {
								// 코디앱 PASS
								requestCreateMenuToApp()
							}

							result.code = '0'
							result.message = '정상'
						}
					}
				})
			} else if (userNo && ['', 'null', 'undefined', null, undefined]) {
				result.code = '0'
				result.message = '정상'
			} else {
				result.code = '0'
				result.message = '정상'
			}
		}
	}

	return result
}

export const getIndemnifyData = id => {
	return UiData.getIndemnifyData(`I${id}`)
}

export const getContractId = sellType => {
	// 일시불: 1, 렌탈: 2
	let contractId = null
	if (sellType === 'Z001' || sellType === 'Z002') {
		contractId = '2'
	} else {
		contractId = '1'
	}

	return contractId
}

/**
 * 문자열 자리수 체크
 * @param string
 * @returns {number}
 * @description 인자로 받은 문자열의 자릿수를 체크하여 Number로 전달한다.
 */
export const getStringLength = string => {
	let length = 0
	for (let i = 0; i < string.length; i++) {
		if (escape(string.charAt(i)).length === 6) {
			length++
		}
		length++
	}
	return length
}

/**
 * 2개의 문자열 비교 ('*' 제외)
 * @param {*} string1
 * @param {*} string2
 * @returns 문자열이 다르면 true
 */
export const notEqualIgnoreMasking = (string1, string2) => {
	if (string1.length !== string2.length) {
		return true
	}

	for (let i = 0; i < string1.length; i++) {
		if (string1[i] !== '*' && string2[i] !== '*' && string1[i] !== string2[i]) {
			return true
		}
	}
	return false
}

export const getChairMultiFacData = id => {
	return UiData.getChairMultiFacData(`${id}`)
}
/**
 * 파라미터의 값이 빈값인지 체크한다.
 * ex. '' => true, undefined => true, null => true, ' ' => false, 'undefined' => false
 * @param {*} id 빈값 확인하고
 * @returns empty일 경우 true
 */
export const isParameterEmpty = id => {
	return id === undefined || id === '' || id === null
}
