<template>
	<div>
		<div v-if="!once">
			<p class="txt_warn">
				{{ guideText }}
			</p>
		</div>
		<CardPayMethodForm
			ref="card"
			:recpYn="once"
			:hidePaymentDate="once"
			:payInfo="payInfo"
		></CardPayMethodForm>

		<div class="bottom_box flex_row">
			<v-btn class="mb-2 btn_grey" color="grey" large @click="onCancel()">
				취소
			</v-btn>
			<v-btn class="mb-2 btn_primary" color="primary" large @click="onAdd()">
				{{ addBtnText }}
			</v-btn>
		</div>
	</div>
</template>
<script>
/**
 * 결제수단 추가 - 카드이체 추가 화면
 */
import { cardChk } from '@/apis/order.recp.api'
import { mapActions } from 'vuex'

export default {
	name: 'CardPayMethod',
	props: {
		saveable: {
			Boolean,
			default: false
		},
		once: { Boolean, default: false },
		paymentType: { String, default: '' },
		payInfo: { Object, default: {} },
		addBtnText: { String, default: '추가하기' },
		guideText: { String, default: '※ 계약자 명의 신용/체크카드만 가능' }
	},

	data() {
		return {
			cardNo: '',
			paymentDate: '',
			custType: '',
			dialog: false,
			cardCompany: '',
			cardCompanyDisplayText: '카드사 입력',
			webOrderNo: this.$store.getters['verify/mobOrderNo']
		}
	},
	watch: {
		payInfo: {
			handler() {
				this.$nextTick(async () => {
					this.$refs.card.validRest()
				})
			}
		}
	},
	methods: {
		...mapActions({ showSnackbar: 'common/showSnackbar' }),
		async onAdd() {
			const formData = this.$refs.card.onSubmit()
			if (!formData) return
			this.$log('card formData:', formData)
			const {
				ssn: ssnNo,
				avDat: expireYm,
				accountNo: cardNo,
				bankCd: cardcomCd,
				bankNm: cardcomNm,
				custName: ownerNm,
				dd: payDd,
				custType: katr2
			} = formData
			let card = {
				ssnNo,
				expireYm,
				cardNo,
				payType: this.once === true ? 'O' : 'R',
				cardcomCd,
				cardcomNm,
				ownerNm,
				payDd,
				katr2,
				iGubn: this.once === true || this.paymentType === 'cust' ? 'A1' : 'C1',
				saveable: this.saveable
			}

			if (!this.saveable && Object.keys(this.payInfo).length > 0) {
				this.$emit('paymethod-completed', {
					payMethod: '12',
					...formData,
					...card
				})
				this.$refs.card.validRest()
				return
			}

			cardChk(this.webOrderNo, card)
				.then(res => {
					this.$log(res)
					const { data } = res.resultObject
					this.$log('data.E_RETURN', data.E_RETURN)
					if (data && data.TYPE !== 'T') {
						let message = data.E_MSGTXT

						// E_MSGTXT가 비었으면 E_RETURN 으로 출력
						if (message === '') {
							message = data.E_RETURN
						}

						this.$alert({
							message
						})
						return
					}

					this.showSnackbar({ msg: '카드계좌 확인되었습니다.', color: '#222' })
					this.$emit('paymethod-completed', {
						payMethod: '12',
						...formData,
						...card
					})
					this.$refs.card.validRest()
				})
				.catch(err => {
					this.$log(err)
					this.$alert({ message: err })
				})
		},

		onCancel() {
			this.$alert({
				message: '입력한 정보를 저장하지 않고 이전화면으로 이동하시겠습니까?',
				okText: '이동',
				cancelText: '유지'
			}).then(res => {
				//false일때
				if (res) {
					this.$emit('paymethod-completed', false)
					this.$refs.card.validRest()
				}
			})
		},

		handleCardCompanySelected(item) {
			this.cardCompanyDisplayText = item.text
		},

		onInit() {
			this.$refs.card.validRest()
		}
	}
}
</script>
