/**
 * Production 환경 여부 조회
 */
export function isProduction() {
	return process.env.NODE_ENV === 'production'
}

/**
 * staging 환경 여부 조회
 */
export function isStaging() {
	return process.env.NODE_ENV === 'blue'
}

/**
 * development 환경 여부 조회
 */
export function isDevelop() {
	return process.env.NODE_ENV === 'development'
}

/**
 *
 * @param {value} val
 * @param {0 패딩후 전체자리수} len
 * @returns 0 패딩 문자열
 */
export const pad = (val, len) => {
	val = String(val)
	len = len || 2
	while (val.length < len) {
		val = '0' + val
	}
	return val
}

/**
 *
 * @returns 카드 할부개월 객체
 */
export const getInsMonthOfCard = () => {
	let items = [{ code: '00', text: '일시불' }]
	for (let i = 2; i < 19; i++) {
		items.push({
			code: pad(i),
			text: `${i} 개월`
		})
	}

	return items
}
