<template>
	<div class="u-bank-wrap">
		<v-bottom-sheet
			v-model="seen"
			scrollable
			:persistent="persistent"
			:content-class="`u-bank-sheet`"
		>
			<template v-slot:activator="{ on, attrs }" v-if="!hideButton">
				<v-btn
					:disabled="disabledButton"
					class="btn_select_type"
					height="48px"
					depressed
					outlined
					@click="open"
					v-bind="attrs"
					v-on="on"
				>
					<slot name="default" />
				</v-btn>
			</template>

			<v-card class="u-bank-grid">
				<v-card-title>{{ title }}</v-card-title>
				<v-card-text style="background-color: white;">
					<div class="box row">
						<v-btn-toggle v-model="selected" :mandatory="mandatory">
							<div
								class="u-bank-cover"
								v-for="(item, i) in paymentList"
								:key="`item-${i}`"
							>
								<v-btn
									:ripple="false"
									class="ico"
									:class="`card-${item.cd}`"
									@click="onClick(i)"
								>
									{{ item.cdNm }}
								</v-btn>
							</div>
						</v-btn-toggle>
					</div>
				</v-card-text>
			</v-card>
		</v-bottom-sheet>
	</div>
</template>

<script>
import { paymentList } from '@/apis/order.status.api'
export default {
	props: {
		value: [String, Number],
		title: String,
		persistent: Boolean,
		hideButton: Boolean,
		disabledButton: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		selected: {
			get() {
				return this.paymentList.findIndex(x => x.cd === this.value)
			},
			set(value) {
				if (value !== undefined) {
					this.$emit('input', this.paymentList[value].cd)
				}
			}
		},
		mandatory() {
			return this.paymentList.some(x => x.cd === this.value)
		}
	},
	data() {
		return {
			seen: false,
			paymentList: []
		}
	},
	methods: {
		open() {
			this.getPaymentList()

			this.seen = true
		},
		onClick(clickedValue) {
			this.close(clickedValue)
		},
		close(value) {
			if (value !== undefined) {
				const selectedIndex = value
				const selectedItem = this.paymentList[selectedIndex]
				this.$emit('item-selected', selectedItem)
			}
			this.seen = false
		},
		async getPaymentList() {
			const params = { grpCd: 'F9' }

			await paymentList(params).then(async chkRes => {
				this.paymentList = chkRes.resultObject
			})
		}
	}
}
</script>
