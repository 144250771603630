<template>
	<div>
		<v-bottom-sheet v-model="seen" scrollable>
			<template v-slot:activator="{ attrs }" v-if="!hideButton">
				<v-btn class="chip_btn_select" @click="open" v-bind="attrs">
					<slot name="default" />
					<v-icon>
						mdi-chevron-down
					</v-icon>
				</v-btn>
			</template>
			<h2 class="tit">{{ title }}</h2>
			<div class="scr_area">
				<v-btn-toggle
					class="btn_bottom_sheet"
					v-model="selected"
					@change="onChange"
				>
					<v-btn class="btn_half" v-for="(stat, i) in items" :key="i">
						{{ stat.text }}
					</v-btn>
				</v-btn-toggle>
			</div>
		</v-bottom-sheet>
	</div>
</template>
<script>
export default {
	props: {
		hideButton: Boolean,
		title: String
	},
	data() {
		return {
			seen: false,
			items: [
				{ code: '110', text: '인증입력' },
				{ code: '101', text: '정보입력' },
				{ code: '102', text: '인증완료' },
				{ code: '103', text: '이관요청' },
				{ code: '104', text: '할인입력' },
				{ code: '105', text: '결제입력' },
				{ code: '106', text: '서명완료' },
				{ code: '107', text: '설치입력' },
				{ code: '108', text: '서명입력' },
				{ code: '109', text: '주문확정' },
				{ code: '201', text: '주문불가' },
				{ code: '202', text: '주문필터' },
				{ code: '203', text: '주문취소' }
			]
		}
	},
	computed: {
		selected: {
			get() {
				return this.items.findIndex(x => x.code === this.value)
			},
			set(value) {
				if (value !== undefined) {
					this.$emit('input', this.items[value].code)
				}
			}
		}
	},
	methods: {
		open() {
			this.seen = true
		},
		onChange(e) {
			const selectedItem = this.items[e]
			this.$emit('item-selected', selectedItem)
			this.seen = false
		}
	}
}
</script>
