<template>
	<div>
		<v-bottom-sheet
			scrollable
			v-model="seen"
			:persistent="persistent"
			:content-class="'promotion'"
		>
			<template v-slot:activator="{ attrs }" v-if="!hideButton">
				<v-btn
					outlined
					depressed
					@click="open"
					v-bind="attrs"
					:disabled="disabled"
					class="btn_select_type"
				>
					<slot name="default" />
				</v-btn>
			</template>

			<h2 class="tit">{{ title }}</h2>

			<div class="u-select-order">
				<v-btn-toggle class="toggle_row" mandatory>
					<v-btn
						:ripple="false"
						class="btn_field"
						v-for="(item, i) in items"
						:key="i"
						:disabled="
							disabledList.length > 0 ? disabledList[i].disable : false
						"
						@click="onClick(i)"
					>
						<div class="box">
							<article class="desc">
								<div class="info">
									<dl>
										<dt>고객주문번호</dt>
										<dd>{{ item.ORDER_NO }}</dd>
									</dl>
									<dl>
										<dt>설치일자</dt>
										<dd>{{ item.FIRST_INST_DT }}</dd>
									</dl>
									<dl>
										<dt>설치주소</dt>
										<dd>
											{{ item.ADDRESS }}
										</dd>
									</dl>
									<dl>
										<dt>렌탈</dt>
										<dd>{{ item.MAKTX }}</dd>
									</dl>
								</div>
							</article>
							<v-checkbox></v-checkbox>
						</div>
					</v-btn>
				</v-btn-toggle>
			</div>
		</v-bottom-sheet>
	</div>
</template>

<script>
export default {
	props: {
		disuseList: Array,
		value: [String, Number],
		items: {
			type: Array,
			default: () => {
				return []
			}
		},
		text: String,
		title: String,
		persistent: Boolean,
		hideButton: Boolean,
		disabled: { type: Boolean, default: false }
	},

	computed: {
		displayText() {
			return this.text
		},
		selected: {
			get() {
				return this.items.findIndex(x => x.code === this.value)
			},
			set(value) {
				if (value !== undefined) {
					this.$emit('input', this.items[value].code)
				}
			}
		},
		mandatory() {
			return this.items.some(x => x.code === this.value)
		}
	},
	data() {
		return {
			seen: false,
			orderNumberChecked: 0,
			disabledList: []
		}
	},
	methods: {
		open() {
			this.seen = true

			this.disabledList = []
			this.items.forEach((x, i) => {
				this.disabledList.push({ ...x, disable: false })

				if (this.disuseList.length !== 0) {
					this.disuseList.forEach(y => {
						if (x.ORDER_NO === y.ORDER_NO) {
							this.disabledList[i].disable = true
						}
					})
				}
			})
		},
		onChange(e) {
			const selectedItem = this.items[e]
			this.$emit('item-selected', selectedItem)
			this.seen = false
		},
		onClick(e) {
			this.$emit('item-selected', e)
			this.seen = false
		}
	}
}
</script>
