const state = {
	/**
	 * {
		workerType: '', // 작업담당조직 (SERVICE_MANAGER, DELEGATE_3PL, AIR_3PL, EXPRESS) required
		bookingDate: '', // 배정날짜 (YYYYMMDD)
		bookingSlot: '', // 배정구간 (NC1, NC2, NC3, NC4, NC5, MC)
		bookingSlotTime: '', // 배정구간 시간 (HHmmHHmm) 
		bookingYn: undefined, // 배정여부 (undefined, true, false)
		goodsCd: '',  // 제품코드
		prodNm: '',  // 제품명
	 	orderSeq: '',  // 주문순번
		orderNo: ''  // 주문번호,
		orderCancelYn: undefined // 주문취소 여부, (undefined, true, false),
		zipSeq: '' // 우편일련번호
	   }
	 */
	deliveryInfos: [], // 배정 정보
	checkedAll: false // 일괄, 개별 배정여부 (true:일괄, false: 개별)
}

const getters = {
	deliveryInfos: state => state.deliveryInfos,
	checkedAll: state => state.checkedAll
}

const actions = {
	async setDeliveryInfos({ commit }, payload) {
		commit('setDeliveryInfos', payload)
	},
	async setCheckedAll({ commit }, payload) {
		commit('setCheckedAll', payload)
	}
}

const mutations = {
	setDeliveryInfos(state, payload) {
		state.deliveryInfos = payload
	},
	setCheckedAll(state, payload) {
		state.checkedAll = payload
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
