/**
 * 주문 유형 데이터
 * INDEDX는 S + SALE_ID
 * ex > S0001
 * @description id : 주문유형(SALE_ID), name: 주문유형명, rerental: 재렌탈 여부
 */
export default {
	S0001: { id: '0001', name: '일반', rerental: false },
	S0002: { id: '0002', name: '학교할인', rerental: false },
	S0003: { id: '0003', name: '비데공공기관할인', rerental: false },
	S0005: { id: '0005', name: '재렌탈(일시불)', rerental: true },
	S0016: { id: '0016', name: '재렌탈(렌탈)', rerental: true },
	S0017: { id: '0017', name: '일반(군부대-약정)', rerental: false },
	S0018: { id: '0018', name: 'CRP제도', rerental: false },
	S0019: { id: '0019', name: 'NCP제도', rerental: false },
	S0020: { id: '0020', name: '등록비 분할 납부 고객', rerental: false },
	S0024: { id: '0024', name: '온라인몰(렌탈)', rerental: false },
	S0025: { id: '0025', name: '군부대재렌탈(일시불)', rerental: true },
	S0026: { id: '0026', name: '군부대재렌탈(렌탈)', rerental: true },
	S0029: { id: '0029', name: '재렌탈(일시불)약정', rerental: true },
	S0030: { id: '0030', name: '재렌탈(렌탈)약정', rerental: true },
	S0033: { id: '0033', name: '관계사 직원산매', rerental: false },
	S0034: { id: '0034', name: 'FOUR SEASON(타사)', rerental: false },
	S0037: { id: '0037', name: '일반(군부대-약정)3년', rerental: false },
	S0038: { id: '0038', name: '군부대재렌탈(일시불)3년', rerental: true },
	S0039: { id: '0039', name: '군부대재렌탈(렌탈)3년', rerental: true },
	S0040: { id: '0040', name: '추가 CRP', rerental: false },
	S0043: { id: '0043', name: 'PRE-PASS', rerental: false },
	S0221: { id: '0221', name: '프로모션', rerental: true },
	S0520: { id: '0520', name: 'MBK관계사(렌탈)', rerental: false }
}
