import mixins from '@/mixins/filters'
import store from '../store/prd.store'
import UiData from './ui-data'

/**
 * 선택 상품 여부
 */
export const hasPrds = () => {
	let prevList = store.state.prdList
	if (prevList && prevList.length > 0) {
		return true
	} else {
		return false
	}
}

/**
 * 마스킹
 * @param {*} accNo
 */
export const getMaskedAccNo = accNo => {
	if (accNo) {
		if (accNo.length < 15) {
			return mixins.filters.star(accNo, 4)
		} else {
			return mixins.filters.star(accNo, 6)
		}
	} else {
		return ''
	}
}

/**
 * 판매구분명 조회
 * @param {*} payload 판매구분
 */
export const getSellType = sellType => {
	let sellNm = ''
	switch (sellType.code) {
		case 'Z001':
			sellNm = '렌탈'
			break
		case 'Z002':
			sellNm = '재렌탈'
			break
		case 'Z003':
			sellNm = '일시불'
			break
		case 'Z004':
			sellNm = '자체할부'
			break
		case 'Z005':
			sellNm = '일시불보상'
			break
		default:
			break
	}
	return sellNm
}

/**
 * 제품 카테고리 분류명 조회
 * @param {*} prodh 제품 카테고리
 */
export const getPrdName = prodh => {
	let name = '기타'

	if (!prodh) {
		return name
	}

	switch (prodh.substr(0, 3)) {
		case '001':
			name = '정수기'
			break
		case '002':
			name = '비데'
			break
		case '003':
			name = '연수기'
			break
		case '004':
			name = '공기청정기'
			break
		case '008':
			if (
				prodh.lastIndexOf('050006') > 0 ||
				prodh.lastIndexOf('050007') > 0 ||
				prodh.lastIndexOf('050008') > 0 ||
				prodh.lastIndexOf('050009') > 0 ||
				prodh.lastIndexOf('050012') > 0 ||
				prodh.lastIndexOf('050FO') > 0
			) {
				name = '파운데이션'
			} else if (
				prodh.lastIndexOf('050001') > 0 ||
				prodh.lastIndexOf('050002') > 0 ||
				prodh.lastIndexOf('050003') > 0 ||
				prodh.lastIndexOf('050004') > 0 ||
				prodh.lastIndexOf('050005') > 0 ||
				prodh.lastIndexOf('050011') > 0 ||
				prodh.lastIndexOf('050FR') > 0
			) {
				name = '프레임'
			} else if (prodh.lastIndexOf('031') > 0) {
				name = '매트리스'
			} else {
				name = '기타'
			}
			break
		case '510':
			name = '의류청정기'
			break
		case '005':
			if (prodh.indexOf('005102') === 0 || prodh.indexOf('005110') === 0) {
				name = '안마의자'
			} else if (prodh.indexOf('005060') === 0) {
				name = '전기레인지'
			} else {
				name = '기타'
			}
			break
		default:
			name = '기타'
			break
	}

	return name
}

/**
 * 제품 카테고리 분류 코드 세팅
 * @param {*} prodh 제품 카테고리
 */
export const getPrdCode = prodh => {
	let code = ''

	if (!prodh) {
		return code
	}

	switch (prodh.substr(0, 3)) {
		case '001':
		case '002':
		case '003':
		case '004':
			code = prodh.substr(0, 3)
			break
		case '008':
			if (
				prodh.lastIndexOf('050006') > 0 ||
				prodh.lastIndexOf('050007') > 0 ||
				prodh.lastIndexOf('050008') > 0 ||
				prodh.lastIndexOf('050009') > 0 ||
				prodh.lastIndexOf('050012') > 0
			) {
				code = '008050FO'
			} else if (
				prodh.lastIndexOf('050001') > 0 ||
				prodh.lastIndexOf('050002') > 0 ||
				prodh.lastIndexOf('050003') > 0 ||
				prodh.lastIndexOf('050004') > 0 ||
				prodh.lastIndexOf('050005') > 0 ||
				prodh.lastIndexOf('050011') > 0
			) {
				code = '008050FR'
			} else if (prodh.lastIndexOf('031')) {
				code = '008031'
			}
			break
		case '510':
			code = '510'
			break
		case '005':
			if (prodh.indexOf('005102') === 0) {
				code = '005102'
			} else if (prodh.indexOf('005110') === 0) {
				code = '005110'
			} else if (prodh.indexOf('005060') === 0) {
				code = '005060'
			} else {
				code = ''
			}
			break
		default:
			code = ''
			break
	}

	return code
}

export const getVaccBankName = bankc => {
	const items = [
		{ code: '32', text: '부산은행' },
		{ code: '81', text: 'KEB하나은행' },
		{ code: '20', text: '우리은행' },
		{ code: '04', text: '국민은행' },
		{ code: '88', text: '신한은행' },
		{ code: '11', text: '농협중앙회' },
		{ code: '31', text: '대구은행' }
	]
	return items.find(bank => bank.code === bankc).text
}

export const isWaterPurifier = prdha => {
	// 정수기
	const cateList = [
		'001001',
		'001002',
		'001003',
		'001022',
		'001025',
		'001028',
		'001066',
		'001067',
		'001068',
		'001073'
	]
	return cateList.some(x => prdha.indexOf(x) === 0)
}
export const isAirPurifier = prdha => {
	// 공기청정기
	const cateList = ['004006', '004047', '004048', '004065', '004071']
	return cateList.some(x => prdha.indexOf(x) === 0)
}
export const isWaterSoftener = prdha => {
	// 연수기
	const cateList = ['003005', '003059', '003062', '003069']
	return cateList.some(x => prdha.indexOf(x) === 0)
}
export const isBidet = prdha => {
	// 비데
	const cateList = ['002004', '002044', '002046', '002053', '002058', '002068']
	return cateList.some(x => prdha.indexOf(x) === 0)
}
export const isMattress = prdha => {
	// 매트리스
	const cateList = ['008031', '008050', '008055', '008056', '008057']
	return cateList.some(x => prdha.indexOf(x) === 0)
}
export const isChair = prdha => {
	// 안마의자
	const cateList = ['005102', '005110']
	return cateList.some(x => prdha.indexOf(x) === 0)
}
export const isClothesPurifier = prdha => {
	// 의류청정기
	const cateList = ['510094', '510095']
	return cateList.some(x => prdha.indexOf(x) === 0)
}
export const isFootBath = prdha => {
	// 족욕기
	const cateList = ['005105']
	return cateList.some(x => prdha.indexOf(x) === 0)
}

export const isKnee = prdha => {
	// 족욕기
	const cateList = ['005104']
	return cateList.some(x => prdha.indexOf(x) === 0)
}

export const getAgreeCateName = item => {
	let type = ''
	let description = ''
	switch (item) {
		case 'LCEE':
			{
				description = '<br>(매트리스 외)'
				type = `일시불약관-${item}${description}`
			}
			break
		case 'RCEE':
			{
				description = '<br>(정수기/비데/청정기 등)'
				type = `렌탈약관-${item}${description}`
			}

			break
		case 'LCMT':
			{
				description = '<br>(매트리스)'
				type = `일시불약관-${item}${description}`
			}
			break
		case 'RCMT':
			{
				description = '<br>(매트리스/파운데이션/프레임)'
				type = `렌탈약관-${item}${description}`
			}
			break
		case 'RCCP':
			{
				description = '<br>(의류청정기/안마의자/전기레인지)'
				type = `렌탈약관-${item}${description}`
			}
			break
		case 'LCHE':
			{
				description = '<br>(일시불체험)'
				type = `일시불체험약관-${item}${description}`
			}
			break
	}
	return type
}

export const getPdfLinkType = (prdha, contractId) => {
	let pdfLink = ''
	// 약관 상품구분명
	if (isMattress(prdha)) {
		if (contractId === '1') {
			pdfLink = 'MAT_1'
		} else {
			pdfLink = 'MAT_2'
		}
	} else if (isClothesPurifier(prdha)) {
		if (contractId === '1') {
			pdfLink = 'ENV_1'
		} else {
			pdfLink = 'CLO_2'
		}
	} else {
		if (contractId === '1') {
			pdfLink = 'ENV_1'
		} else {
			pdfLink = 'ENV_2'
		}
	}
	return pdfLink
}

/**
 * 지국확인
 *  상도지국 (AT4) , 신대방지국(B64),  당산지국(A94), 가락본동지국(B49), 인천용현지국(B53), 부산센텀지국(E06), 대전법동지국(C57)
 * @param {*} deptCd
 */
export const chkDeptCd = deptCd => {
	const deptCdList = [
		'AT4',
		'B64',
		'A94',
		'B49',
		'B53',
		'E06',
		'C57',
		'C08',
		'AW3',
		'A80',
		'A47',
		'B06',
		'A05',
		'A37',
		'A34',
		'A31',
		'A15',
		'A96',
		'A22',
		'A60',
		'B40',
		'AD1',
		'B12',
		'AP5',
		'AR5',
		'B35',
		'B85',
		'B74',
		'AU9',
		'B52',
		'B51',
		'C69',
		'D13',
		'D31',
		'D32',
		'D48',
		'ZE4',
		'E10',
		'D49',
		'AA5',
		'CDE',
		'D12',
		'AW7',
		'ZE5',
		'E18',
		'E34',
		'E31',
		'E41',
		'ZD6',
		'C49',
		'C46',
		'C74',
		'C38',
		'E39',
		'D28'
	]
	return process.env.NODE_ENV !== 'production'
		? true
		: deptCdList.some(x => x === deptCd)
}
/**
 * 이름
 * @param {*} name
 */
export const getName = name => {
	if (name) {
		return name
	} else {
		return ''
	}
}

/**
 * 폰번호 포맷팅 010-111{1}-1111
 * @param {*} phoneNo
 */
export const getPhone = phoneNo => {
	if (phoneNo) {
		let match = phoneNo.match(/^(\d{3})(\d{3,4})(\d{4})$/)
		if (match) {
			return `${match[1]}-${match[2]}-${match[3]}`
		}
	} else {
		return ''
	}
}

/**
 * 주문유형정보
 * @param {*} orderType 주문유형 코드
 * @description 알파벳 S + 주문유형 코드로 요청 (S0001)
 */
export const getSaleData = orderType => {
	return UiData.getSaleData(`S${orderType}`)
}

/**
 * 매트리스 제품군 분류
 * @description
 */
export const getMattressInfo = (prd, ZSDS1034) => {
	const result = {
		mattressYn: false,
		typeCd: '', // M : 매트리스, MO: 원바디 매트리스,  MF: 파운데이션, ETC: 기타
		prdCd: '', // 상품코드
		prdName: '', // 상품명
		prodName: '', // 모델명
		orderType: '', // 상품코드
		packageSeq: '', //
		selectMattressText: '', // 출력 명칭
		message: '',
		selectMattress: '',
		month: ''
	}

	// eslint-disable-next-line no-console
	// console.log(`카테고리: ${ZSDS1034.PRODH}, 상품코드: ${ZSDS1034.GOODS_CD}`)
	const cate1 = ZSDS1034.PRODH.substring(0, 3).toString()
	const cate2 = ZSDS1034.PRODH.substring(3, 6).toString()
	const cate3 = ZSDS1034.PRODH.substring(6, 9).toString()
	const prdCd = ZSDS1034.GOODS_CD.replace('00000000000', '').toString()

	if (cate1 !== '008') {
		// 매트리스 제품굼이 아님
		result.mattressYn = false
		result.message = '매트리스 제품군 아님'
		result.typeCd = 'ETC'
	} else {
		if (cate2 === '050') {
			if (['001', '002', '003', '004', '005', '011'].includes(cate3)) {
				/*
				------------------------------------------------------
				프레임               구분
				------------------------------------------------------
				008050001	프레임
				008050002	킹 사이즈 프레임
				008050003	퀸 사이즈 프레임
				008050004	슈퍼싱글 사이즈 프레임
				008050005	싱글 사이즈 프레임
				008050011	라지킹 사이즈 프레임
				------------------------------------------------------
				 */
				result.mattressYn = true
				result.message = '프레임 제품'
				result.typeCd = 'MO'
			} else {
				/*
				------------------------------------------------------
				파운데이션              구분
				------------------------------------------------------
				008050006            킹 사이즈 파운데이션
				008050007            퀸 사이즈 파운데이션
				008050008            슈퍼싱글 사이즈 파운데이션
				008050009            싱글 사이즈 파운데이션
				008050012            라지킹 사이즈 파운데이션
				 */
				result.mattressYn = true
				result.message = '파운데이션'
				result.typeCd = 'MF'
			}
		} else {
			// 매트리스
			if (['4100523', '4100524'].includes(prdCd)) {
				// 매트리스 원바디
				result.mattressYn = true
				result.message = '원바디 제품'
				result.typeCd = 'MO'
			} else {
				// 매트리스 일반
				result.mattressYn = true
				result.message = '매트리스 일반 제품'
				result.typeCd = 'M'
			}
		}
		result.month = ZSDS1034.USE_DUTY_MONTH
		result.prdCd = prdCd
		result.prdName = prd.leader_model_nm
		result.prodName = ZSDS1034.GOODS_NM
		result.orderType = ZSDS1034.ORD_TYPE
		result.selectMattressText = null
		//`${ZSDS1034.GOODS_NM}, ${ZSDS1034.USE_DUTY_MONTH}개월`
		result.selectMattress = null
		result.packageSeq = null
	}
	return result
}

/**
 * 매트리스 제품군 카운트
 * @description
 */
export const getMattressCount = prdList => {
	let result = {
		oneBodyMatterssCnt: 0, // 원바디 매트리스
		foundationCnt: 0, // 파운데이션
		normalMatterssCnt: 0 // 매트리스
	}

	prdList.forEach(v => {
		let mattressInfo = v.options.mattressInfo
		if (v.options.mattressInfo && v.options.mattressInfo.mattressYn) {
			if (mattressInfo.typeCd === 'MO') {
				// 원바디 매트리스
				result.oneBodyMatterssCnt += v.payOptions.quantity
			} else if (mattressInfo.typeCd === 'MF') {
				// 파운데이션
				if (['Z001', 'Z002'].includes(v.options.sellType.code)) {
					result.foundationCnt += v.payOptions.quantity
				}
			} else {
				// 매트리스
				if (['Z001', 'Z002'].includes(v.options.sellType.code)) {
					result.normalMatterssCnt += v.payOptions.quantity
				}
			}
		}
	})
	return result
}

/**
 * 구매 유형에 대한 출력 텍스트 리턴
 * @param orderType
 */
export const getBuyType = orderType => {
	let result = ''
	switch (orderType) {
		case 'Z001':
			result = '렌탈'
			break
		case 'Z002':
			result = '재렌탈'
			break
		case 'Z003':
			result = '일시불'
			break
		case 'Z004':
			result = '자체할부'
			break
		case 'Z005':
			result = '일시불보상'
			break
		default:
			break
	}
	return result
}

/**
 * 결합 할인 적용 가능 여부
 */
export const getCombiCheck = orderType => {
	return ['Z001', 'Z002'].includes(orderType)
}

/**
 * 동시구매 할인 적용 가능 여부
 */
export const getPackCheck = orderType => {
	return ['Z001', 'Z002', 'Z003'].includes(orderType)
}

/**

 * 동시구매할인 적용 불가 상품 여부
 * 휴대용 공기청정기 상품코드(GOODS_CODE)로 분류
 * PRDHA, PRODH로 분류가 불가
 * 상품이 추가되면 GOODS_CODE 뒤 7자리 추가 필요
 */
export const getPackPrdhaCheck = goodsCode => {
	let result = null

	if (goodsCode && goodsCode !== '') {
		const prdCd = goodsCode.replace('00000000000', '').toString()
		const goodsCodeList = ['0113040', '0113038', '0113001']
		result = goodsCodeList.includes(prdCd)
	} else {
		result = false
	}
	return result
}

/**
 * 결합할인 적용 불가 상품 여부
 * 제습기, 휴대용 공기청정기 상품코드(GOODS_CODE)로 분류
 * PRDHA, PRODH로 분류가 불가
 * 상품이 추가되면 GOODS_CODE 뒤 7자리 추가 필요
 */
export const getCombiPrdhaCheck = goodsCode => {
	let result = null

	if (goodsCode && goodsCode !== '') {
		const prdCd = goodsCode.replace('00000000000', '').toString()
		const goodsCodeList = [
			'0113260',
			'0111031',
			'0113040',
			'0113038',
			'0113001'
		]
		result = goodsCodeList.includes(prdCd)
	} else {
		result = false
	}
	return result
}

/**
 * [설치정보] 설치환경정보 입력 노출 여부
 * @description 분류코드 PRODH의 코드를 사용하며, 해당 코드가 있으면 노출에서 제외
 *  전기레인지, PRDHA => 005060

 *  제습기, PRDHA => 005047
 *  가습기, PRDHA => 005019
 *  도기, PRDHA => 011083,011082
 */
export const getEnvInfoActive = prdha => {
	return !['005060', '005047', '005019', '011083', '011082'].includes(prdha)
}

/**
 * @description Step2~Step5 단계 이동 시, 해당 웹주문번호 기준으로 생성된 SAP주문번호 유무 관련 메시지
 *  108 서명입력  106 서명완료   109 주문확정 203 주문취소
 *  각 statusCode 마다 메시지가 달라질수 있다.
 *  기존:1 203[주문문취]:2   106/108/109 : 3
 */
export const getMessageByStatusCode = statusCode => {
	let message = undefined
	switch (statusCode) {
		// 서명완료
		case '106':
		case '108':
		case '109':
		case '111':
			message =
				'이미 주문이 생성되었습니다. 주문 현황에서 진행상태 확인 후, 다시 진행하시기 바랍니다.'
			break
		// 주문취소
		case '203':
			message = '해당 주문은 삭제된 상태로 확인되어, 주문현황으로 이동합니다.'
			break
		default:
			break
	}
	return message
}

/**
 * @description 주문요약>제품군 분류 그룹핑
 * ec.js의 getPrdCode 참고, PRODH9 값 분류에 따라 동일한 코드일 경우
 * 같은 제품군으로 분류 및 카운팅 한다
 * @param {*} sumList
 * @param {*} orderNoStr
 * @returns groupbySumList
 */
export const groupbyOrderInfo = (sumList, orderNoStr) => {
	let ordList = []
	sumList.forEach(v => {
		if (orderNoStr.includes(v.orderNo)) {
			ordList.push(v)
		}
	})

	// PRODH9 3~9자리 코드와 제품분류 값으로 grouping
	const m = new Map()
	ordList.forEach(({ div, sale }) => {
		const hash = JSON.stringify([
			['code', getPrdCode(div)],
			['sale', sale]
		])
		// key값(code, sale) 중복횟수로 cnt 세팅
		m.set(hash, (m.get(hash) || 0) + 1)
	})

	// 키값 쌍의 배열을 객체로 변환
	const groupbySumList = [...m].map(([x, cnt]) => ({
		...Object.fromEntries(JSON.parse(x)),
		cnt
	}))

	return groupbySumList
}
