/**
 * 제품 상세 페이지에서 안마의자 제품군 다중이용 시설 데이터
 * @description 모바일 주문 프로세스 화면기획안_02_정보입력1_v1.46, Slide: ID SB-MO-PD-0124 분료표 기준
 * InstallType 대분류, D{code} 상세
 */
export default {
	// 설치 장소 분류
	InstallType: [
		{
			code: '',
			text: '다중이용시설 아님',
			isShow: true,
			depth: 1,
			codeGroup: '04'
		},
		{ code: '2', text: '가정', isShow: false, depth: 1, codeGroup: '04' },
		{ code: '3', text: '사무실', isShow: true, depth: 1, codeGroup: '04' },
		{ code: '4', text: '의료기관', isShow: true, depth: 1, codeGroup: '04' },
		{ code: '5', text: '업소', isShow: true, depth: 1, codeGroup: '04' },
		{ code: '6', text: '학교(면제)', isShow: false, depth: 1, codeGroup: '04' },
		{
			code: '7',
			text: '공공시설(관공서등)',
			isShow: true,
			depth: 1,
			codeGroup: '04'
		},
		{ code: '9', text: '군부대', isShow: true, depth: 1, codeGroup: '04' },
		{
			code: 'A',
			text: '학교(면제불가)',
			isShow: true,
			depth: 1,
			codeGroup: '04'
		}
	],
	D2: [
		{
			code: '2011',
			text: '아파트',
			isShow: false,
			install: true,
			codeGroup: '0402',
			depth: 2
		},
		{
			code: '2012',
			text: '주택',
			isShow: false,
			install: true,
			codeGroup: '0402',
			depth: 2
		},
		{
			code: '2013',
			text: '빌라,연립,원룸',
			isShow: false,
			install: true,
			codeGroup: '0402',
			depth: 2
		},
		{
			code: '2014',
			text: '기타(주거형 오피스텔 등 개별세대)',
			isShow: false,
			install: true,
			codeGroup: '0402',
			depth: 2
		}
	],
	D3: [
		{
			code: '3011',
			text: '기업사무실',
			isShow: true,
			install: true,
			codeGroup: '0403',
			depth: 2
		},
		{
			code: '3012',
			text: '개인사무실',
			isShow: true,
			install: true,
			codeGroup: '0403',
			depth: 2
		},
		{
			code: '3013',
			text: '지점,대리점,연구소',
			isShow: true,
			install: true,
			codeGroup: '0403',
			depth: 2
		},
		{
			code: '3014',
			text: '공장',
			isShow: true,
			install: true,
			codeGroup: '0403',
			depth: 2
		},
		{
			code: '3015',
			text: '기타(법인사업자 사무공간 등)',
			isShow: true,
			install: true,
			codeGroup: '0403',
			depth: 2
		}
	],
	D4: [
		{
			code: '4011',
			text: '대학병원,대형병원',
			isShow: false,
			install: true,
			codeGroup: '0404',
			depth: 2
		},
		{
			code: '4012',
			text: '개인병원',
			isShow: true,
			install: true,
			codeGroup: '0404',
			depth: 2
		},
		{
			code: '4013',
			text: '기타(보건소,장례식장 등 의료관련시설)',
			isShow: true,
			install: true,
			codeGroup: '0404',
			depth: 2
		},
		{
			code: '4014',
			text: '산후조리원',
			isShow: true,
			install: true,
			codeGroup: '0404',
			depth: 2
		}
	],
	D5: [
		{
			code: '5006',
			text: '피부,미용샵,안마',
			isShow: true,
			install: true,
			codeGroup: '0405',
			depth: 2
		},
		{
			code: '5007',
			text: '스포츠센터',
			isShow: true,
			install: true,
			codeGroup: '0405',
			depth: 2
		},
		{
			code: '5011',
			text: '숙박시설_호텔,모텔,콘도,연수원,기숙사',
			isShow: true,
			install: true,
			codeGroup: '0405',
			depth: 2
		},
		{
			code: '5012',
			text: '요식시설_음식점,호프,주점 등',
			isShow: true,
			install: true,
			codeGroup: '0405',
			depth: 2
		},
		{
			code: '5013',
			text: '유흥시설_노래,PC,비디오방,나이트 등',
			isShow: true,
			install: true,
			codeGroup: '0405',
			depth: 2
		},
		{
			code: '5014',
			text: '목욕시설_찜질방,목욕탕 등',
			isShow: true,
			install: false,
			codeGroup: '0405',
			depth: 2
		},
		{
			code: '5016',
			text: '백화점,대형마트',
			isShow: true,
			install: true,
			codeGroup: '0405',
			depth: 2
		},
		{
			code: '5017',
			text: '레저,골프장',
			isShow: true,
			install: true,
			codeGroup: '0405',
			depth: 2
		},
		{
			code: '5018',
			text: '휴게소',
			isShow: true,
			install: true,
			codeGroup: '0405',
			depth: 2
		},
		{
			code: '5019',
			text: '문화공연장,실내영화관',
			isShow: true,
			install: true,
			codeGroup: '0405',
			depth: 2
		},
		{
			code: '5020',
			text: '기타(영업 행위가 있는 소형업소 등)',
			isShow: true,
			install: false,
			codeGroup: '0405',
			depth: 2
		},
		{
			code: '5021',
			text: '소형마트',
			isShow: true,
			install: true,
			codeGroup: '0405',
			depth: 2
		},
		{
			code: '5022',
			text: '키즈카페, 놀이방',
			isShow: true,
			install: false,
			codeGroup: '0405',
			depth: 2
		}
	],
	D6: [
		{
			code: '6001',
			text: '대학교',
			isShow: false,
			install: true,
			codeGroup: '0406',
			depth: 2
		},
		{
			code: '6002',
			text: '고등학교',
			isShow: false,
			install: true,
			codeGroup: '0406',
			depth: 2
		},
		{
			code: '6003',
			text: '중학교',
			isShow: false,
			install: true,
			codeGroup: '0406',
			depth: 2
		},
		{
			code: '6004',
			text: '초등학교',
			isShow: false,
			install: true,
			codeGroup: '0406',
			depth: 2
		},
		{
			code: '6005',
			text: '기타',
			isShow: false,
			install: true,
			codeGroup: '0406',
			depth: 2
		}
	],
	D7: [
		{
			code: '7001',
			text: '관공서',
			isShow: false,
			install: true,
			codeGroup: '0407',
			depth: 2
		},
		{
			code: '7002',
			text: '구/시립 도서관, 문화회관 등',
			isShow: false,
			install: true,
			codeGroup: '0407',
			depth: 2
		},
		{
			code: '7003',
			text: '박물관,미술관,종교시설 등',
			isShow: true,
			install: true,
			codeGroup: '0407',
			depth: 2
		},
		{
			code: '7004',
			text: '아동지원센터',
			isShow: true,
			install: false,
			codeGroup: '0407',
			depth: 2
		},
		{
			code: '7005',
			text: '아동지원센터(법인)',
			isShow: false,
			install: false,
			codeGroup: '0407',
			depth: 2
		}
	],
	D9: [
		{
			code: '9001',
			text: '부대',
			isShow: false,
			install: true,
			codeGroup: '0409',
			depth: 2
		},
		{
			code: '9002',
			text: '관사',
			isShow: true,
			install: true,
			codeGroup: '0409',
			depth: 2
		}
	],
	DA: [
		{
			code: 'A001',
			text: '대학교',
			isShow: false,
			install: true,
			codeGroup: '040A',
			depth: 2
		},
		{
			code: 'A002',
			text: '고등학교',
			isShow: false,
			install: true,
			codeGroup: '040A',
			depth: 2
		},
		{
			code: 'A003',
			text: '중학교',
			isShow: false,
			install: true,
			codeGroup: '040A',
			depth: 2
		},
		{
			code: 'A004',
			text: '초등학교',
			isShow: false,
			install: true,
			codeGroup: '040A',
			depth: 2
		},
		{
			code: 'A005',
			text: '기타',
			isShow: false,
			install: true,
			codeGroup: '040A',
			depth: 2
		},
		{
			code: 'A006',
			text: '학원,독서실',
			isShow: true,
			install: true,
			codeGroup: '040A',
			depth: 2
		},
		{
			code: 'A007',
			text: '유치원,어린이집(법인)',
			isShow: false,
			install: false,
			codeGroup: '040A',
			depth: 2
		},
		{
			code: 'A008',
			text: '유치원,어린이집(일반)',
			isShow: false,
			install: false,
			codeGroup: '040A',
			depth: 2
		}
	]
}
