export default {
	rules: {
		required: '필수 입력값입니다',
		maxLength: '최대 {0}자 까지 입력해 주세요',
		minLength: '최소 {0}자 이상 입력해 주세요',
		minSafetyLength: '고유번호 {0}자리를 입력해주세요.',
		email: '이메일 형식으로 입력해 주세요',
		phoneNumber: "'-'를 제외한 휴대전화번호 형식으로 입력해 주세요",
		tellNumber: "'-'를 제외한 전화번호 형식으로 입력해 주세요",
		phoneLength: "'-'를 제외한 11자리를 입력해 주세요",
		numeric: '숫자만 입력 가능합니다',
		alphaNumeric: '영문, 숫자로 입력해 주세요',
		sameAs: '{0}와 같은 값을 입력해 주세요',
		ipv4Address: 'IP(v4) 형태로 입력해 주세요',
		ipv6Address: 'IP(v6) 형태로 입력해 주세요',
		strongPassword:
			'영문, 숫자 및 특수문자를 포함하여 {0} ~ {1}자 까지 입력해 주세요',
		upperCaseAlphaNumeric: '{0} 영문 대문자 및 숫자를 입력해 주세요.'
	},
	word: {
		account: '계정',
		name: '이름',
		email: '이메일',
		password: '비밀번호',
		passwordConf: '비밀번호 확인',
		submit: '등록',
		alert: '알림',
		confirm: '확인',
		cancel: '취소',
		close: '닫기',
		selectAll: '전체 선택',
		select: '선택',
		change: '변경',
		login: '로그인',
		today: '오늘',
		map: '지도',
		modal: '모달',
		sample: '예시',
		error: '에러',
		retry: '다시 시도'
	},
	error: {
		'404': {
			title: '페이지 없음',
			text: '페이지를 찾을 수 없습니다',
			buttonText: '메인화면으로 이동'
		},
		'500': {
			title: '데이터 연결이 끊어졌습니다.',
			text: 'Wi-Fi / 3G / LTE / 5G 연결 후<br/>다시 시도해주세요.',
			buttonText: '다시 시도'
		},
		all: {
			title: '오류',
			text: '오류가 발생했습니다',
			buttonText: '메인화면으로 이동'
		},
		check: {
			title: '서비스 개선 안내',
			text:
				'고객님의 더 나은 주문 서비스 제공을 위해 <br />' +
				'시스템 개선 작업이 진행됩니다.<br />' +
				'작업 시간동안 이용 불가에 대해<br />' +
				'양해의 말씀을 드립니다.',
			time: '시간'
		},
		guide: {
			wrongAccess: '잘못된 접근입니다.',
			personInCharge: '담당자에게 문의해주세요.'
		}
	}
}
