<template>
	<v-overlay z-index="999" :value="loading" color="#000">
		<div>
			<svg class="c-loader" viewBox="0 0 150 150">
				<circle
					class="c-loader__circle c-loader__circle--delta"
					cx="75"
					cy="75"
					r="54"
				/>
				<circle
					class="c-loader__circle c-loader__circle--alpha"
					cx="75"
					cy="75"
					r="54"
				/>
				<circle
					class="c-loader__circle c-loader__circle--beta"
					cx="75"
					cy="75"
					r="54"
				/>
				<circle
					class="c-loader__circle c-loader__circle--gamma"
					cx="75"
					cy="75"
					r="54"
				/>
			</svg>
		</div>
	</v-overlay>
</template>

<script>
/**
 * Progress Bar
 * /src/store/common.store.js
 * @description 공통으로 사용하는 프로그래스바이다.
 *              loading = true 일때 동작한다.
 */
import { mapState } from 'vuex'

export default {
	name: 'MSpinner',
	props: {
		color: {
			type: String,
			default: 'primary'
		},
		size: {
			type: Number,
			default: 80
		}
	},
	computed: mapState({
		loading: state => state.common.loading
	})
}
</script>
<style lang="scss" scoped>
body {
	background: rgb(0 0 0 / 0.6);
}
.c-loader {
	width: 40px;
	height: 40px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -20px 0 0 -20px;
	animation: rotate 1500ms cubic-bezier(0.46, 0.03, 0.61, 1.05) infinite;
}

.c-loader__circle {
	animation: trails 3000ms cubic-bezier(0.87, 0.18, 0.47, 0.98) infinite;
	fill: none;
	stroke-dasharray: 500;
	stroke-dashoffset: 500;
	stroke-width: 15;
	stroke-linecap: round;
}

.c-loader__circle--alpha {
	stroke: rgb(191 234 245 / 0.9);
}

.c-loader__circle--beta {
	stroke: rgb(4 157 217 / 0.9);
}

.c-loader__circle--delta {
	stroke: rgb(255 255 255 / 1);
}

svg circle:nth-child(1) {
	animation-delay: 0ms;
}

svg circle:nth-child(2) {
	animation-delay: 1600ms;
}

svg circle:nth-child(3) {
	animation-delay: 1700ms;
}

svg circle:nth-child(4) {
	animation-delay: 1000ms;
}

@keyframes trails {
	0% {
		stroke-dasharray: 500;
		stroke-dashoffset: 500;
	}
	100% {
		stroke-dasharray: 500;
		stroke-dashoffset: -500;
	}
}

@keyframes rotate {
	0% {
		transform: rotate(-90deg);
	}
	100% {
		transform: rotate(270deg);
	}
}
</style>
