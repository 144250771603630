<template>
	<v-dialog fullscreen v-if="dialog" v-model="dialog">
		<!-- trigger -->
		<template v-slot:activator="{ on, attrs }">
			<v-btn class="btn-trigger" v-on="on" v-bind="attrs"> </v-btn>
		</template>
		<!-- //trigger -->

		<!-- container -->
		<div class="u-dialog-wrap">
			<!-- header -->
			<div class="u-dialog-header">
				<h2 class="heading">전자세금계산서 발행 정보</h2>
				<v-btn icon plain depressed class="btn-close" @click="close">
					<v-icon dark>mdi-window-close</v-icon>
				</v-btn>
			</div>

			<div class="u-dialog-container full">
				<div class="u-cust-dialog">
					<h3 class="cust-message">
						전자세금계산서 발행에 필요한 정보를 입력해 주세요. 다른 담당자
						통해서 입력해야 한다면, 하단 ‘추후 등록’ 버튼을 눌러 주시면 됩니다.
						<br />
						<br />대표자/담당자는 본인인증 정보로 기본 입력되어 있습니다. 다를
						경우 수정해 주세요.
					</h3>

					<v-form class="cust-form" ref="form" @submit.prevent="save">
						<div class="wrap_input">
							<h3 class="label">대표자명</h3>
							<v-text-field
								dense
								outlined
								inputmode="text"
								v-model="ownerName"
								:rules="rules.ownerName"
								placeholder="대표자명을 입력해 주세요."
							/>
						</div>

						<div class="wrap_input">
							<h3 class="label">담당자명</h3>
							<v-text-field
								dense
								outlined
								inputmode="text"
								v-model="adminName"
								:rules="rules.adminName"
								placeholder="담당자명을 입력해 주세요."
							/>
						</div>

						<div class="wrap_input">
							<h3 class="label">담당자 휴대폰번호</h3>
							<v-text-field
								dense
								outlined
								inputmode="numeric"
								maxlength="11"
								v-mask="['###########']"
								v-model="adminPhone"
								:rules="rules.adminPhone"
								placeholder="휴대폰번호를 입력해 주세요."
							/>
							<v-checkbox
								hide-details
								:ripple="false"
								class="expand"
								v-model="checkboxPhone"
								@change="disabledCheck('phone', checkboxPhone)"
								:label="'담당자 전화번호도 동일 합니다.'"
							/>
						</div>

						<div class="wrap_input" v-if="!checkboxPhone">
							<h3 class="label">담당자 전화번호</h3>
							<v-text-field
								dense
								outlined
								inputmode="numeric"
								v-model="adminTel"
								placeholder="전화번호를 입력해 주세요."
							/>
						</div>

						<div class="wrap_input">
							<h3 class="label">담당자 이메일 주소</h3>
							<v-text-field
								dense
								outlined
								inputmode="email"
								v-model="adminEmail"
								:rules="rules.adminEmail"
								placeholder="이메일 주소를 입력해 주세요."
							/>
						</div>

						<div class="wrap_input">
							<h3 class="label">세금계산서 수령처</h3>
							<v-btn
								outlined
								depressed
								class="btn_select_type ico_search"
								:disabled="checkboxAddress"
								@click="addrSearch()"
							>
								<em>{{ addrFull ? addrFull : `주소입력` }}</em>
							</v-btn>
							<v-checkbox
								hide-details
								:ripple="false"
								class="expand"
								v-model="checkboxAddress"
								@change="disabledCheck('address', checkboxAddress)"
								:label="'입력 예정인 설치 주소와 동일 합니다.'"
							/>
						</div>

						<div class="wrap_input">
							<h3 class="label">세금계산서 발행일자</h3>
							<PopButtonGridSheet
								title="발행일자 선택"
								v-model="sendDateDay"
								:items="sendDateDayItems"
								@item-selected="handleItemMakeMonthSelected"
							>
								<template>
									{{ sendDateDayDisplayText }}
									<span class="btn_value">
										<b class="primary--text"></b>
									</span>
								</template>
							</PopButtonGridSheet>
						</div>

						<div class="wrap_input">
							<h3 class="label">연계사업자</h3>
							<PopButtonBottomSheet
								title="연계사업자 선택"
								v-model="linkedBusiness"
								:items="linkedBusinessItems"
								@item-selected="handleLinkedBusinessSelected"
							>
								<template>
									{{ linkedBusinessDisplayText }}
									<span class="btn_value">
										<b class="primary--text"></b>
									</span>
								</template>
							</PopButtonBottomSheet>
						</div>
					</v-form>
				</div>

				<div class="bottom_box">
					<v-btn
						color="primary"
						class="col_1_small"
						@click="save()"
						:disabled="isDisableBtn"
					>
						저장
					</v-btn>
					<v-btn
						outlined
						depressed
						height="20"
						class="btn_underline my-6"
						@click="inputLater()"
					>
						추후 등록
					</v-btn>
				</div>
			</div>
		</div>
		<!-- //container -->
		<InstAddrSearch ref="addrSearch" />
	</v-dialog>
</template>

<script>
import PopButtonBottomSheet from '@/components/modules/noti/PopButtonBottomSheet'
import PopButtonGridSheet from '@/components/modules/noti/PopButtonGridSheet'
import InstAddrSearch from '@/views/inst/InstAddrSearch'
import { bizPhoneCheck } from '@/utils/rules'

export default {
	name: 'PopTaxInfoDialog',
	props: {
		name: [String, Number],
		mob: [String, Number],
		tel: [String, Number],
		email: [String, Number],
		info: [String, Object]
	},
	components: {
		PopButtonBottomSheet,
		PopButtonGridSheet,
		InstAddrSearch
	},
	data() {
		return {
			//팝업
			dialog: false,
			disabled: false,
			//체크박스 담당자 휴대폰번호
			checkboxPhone: false,
			//체크박스 세금계산서 수령처
			checkboxAddress: true,
			//바텀시트 세금계산서 발행일자
			sendDateDayDisplayText: '발행일자 선택',
			sendDateDayItems: [],
			sendDateDay: '',
			//바텀시트 연계사업자
			linkedBusinessDisplayText: '',
			linkedBusinessItems: [
				{ code: '067', text: 'TAXLINK' },
				{ code: '001', text: '스마트빌' },
				{ code: '015', text: '나라빌' },
				{ code: '039', text: '나라장터' },
				{ code: '040', text: '더존(BILL36524)' }
			],
			linkedBusinessItem: {},
			linkedBusiness: '',
			registration: '',
			ownerName: '',
			adminName: '',
			adminPhone: '',
			adminEmail: '',
			addrList: [],
			addrFull: '',
			addr1: '',
			addr2: '',
			zipCode: '',
			rules: {
				ownerName: [v => !!v || '대표자명을 입력해 주세요.'],
				adminName: [v => !!v || '담당자명을 입력해 주세요.'],
				adminPhone: [v => bizPhoneCheck(v, 11)],
				adminEmail: [v => !!v || '이메일 주소를 입력해 주세요.']
			}
		}
	},
	computed: {
		isDisableBtn() {
			let is = true
			is =
				this.ownerName === '' ||
				this.adminName === '' ||
				this.adminPhone === '' ||
				this.adminEmail === '' ||
				bizPhoneCheck(this.adminPhone, 11) !== true ||
				(!this.checkboxAddress && this.addrFull === '') ||
				this.sendDateDay === ''

			return is
		}
	},

	methods: {
		open() {
			this.ownerName = this.info.ownerName || this.name
			this.adminName = this.info.adminName || this.name
			this.adminPhone = this.info.adminPhone || this.mob
			this.adminTel = this.info.adminTel || ''
			this.adminEmail = this.info.adminEmail || ''
			this.addrFull = this.info.addrFull || ''
			this.addr1 = this.info.addr1 || ''
			this.addr2 = this.info.addr2 || ''
			this.zipCode = this.info.zipCode || ''
			this.checkboxPhone = this.info.checkboxPhone
			this.checkboxAddress = this.addrFull !== '' ? false : true

			this.sendDateDayItems = []
			for (let day = 1; day <= 31; day++) {
				this.sendDateDayItems.push({ code: day, text: day + '일' })
			}

			if (this.info.dayCode) {
				this.sendDateDay = this.info.dayCode
				this.sendDateDayDisplayText = this.sendDateDayItems[
					this.info.dayCode - 1
				].text
			} else {
				this.sendDateDay = ''
				this.sendDateDayDisplayText = '발행일자 선택'
			}

			if (this.info.linkedBusiness) {
				this.linkedBusiness = this.info.linkedBusiness
				this.linkedBusinessItem = this.info.linkedBusinessItem
				this.linkedBusinessDisplayText = this.linkedBusinessItem.text
			} else {
				this.linkedBusinessItem = this.linkedBusinessItems[0]
				this.linkedBusinessDisplayText = this.linkedBusinessItem.text
				this.linkedBusiness = this.linkedBusinessItem.code
			}
			this.dialog = true
		},
		//바텀시트 세금계산서 발행일자
		handleItemMakeMonthSelected(item) {
			this.sendDateDayDisplayText = item.text
			this.sendDateDay = item.code
		},
		//바텀시트 연계사업자
		handleLinkedBusinessSelected(item) {
			this.linkedBusinessItem = item
			this.linkedBusinessDisplayText = item.text
			this.linkedBusiness = item.code
		},
		disabledCheck(flag, v) {
			if (flag === 'phone') {
				this.checkboxPhone = v
			} else if (flag === 'address') {
				this.checkboxAddress = v
				this.addrFull = ''
			}
		},
		async addrSearch() {
			const addr = await this.$refs.addrSearch.open()
			this.addrFull = `${addr.roadAddress} ${addr.detailAddress}`
			this.addr1 = addr.roadAddress
			this.addr2 = addr.detailAddress
			this.zipCode = addr.zonecode
		},
		save() {
			if (!this.checkboxPhone && String(this.adminTel).length === 0) {
				this.adminTel = this.adminPhone
			}
			const inputInfo = {
				ownerName: this.ownerName,
				adminName: this.adminName,
				adminPhone: this.adminPhone,
				adminTel: this.checkboxPhone ? this.adminPhone : this.adminTel,
				adminEmail: this.adminEmail,
				addrFull: this.addrFull,
				addr1: this.addr1,
				addr2: this.addr2,
				zipCode: this.zipCode,
				dayCode: this.sendDateDay,
				linkedBusiness: this.linkedBusiness,
				checkboxPhone: this.checkboxPhone,
				checkboxAddress: this.checkboxAddress,
				notIsInputLater: true,
				linkedBusinessItem: this.linkedBusinessItem,
				registration: 'Y'
			}
			this.$emit('inputInfo', inputInfo)
			this.close()
		},
		inputLater() {
			const inputInfo = {
				ownerName: this.name,
				adminName: this.name,
				adminPhone: this.mob,
				adminTel: '',
				adminEmail: '',
				addrFull: '',
				addr1: '',
				addr2: '',
				zipCode: '',
				dayCode: '',
				linkedBusiness: '',
				checkboxPhone: false,
				checkboxAddress: true,
				notIsInputLater: false,
				linkedBusinessItem: {},
				registration: 'N'
			}
			this.$emit('inputInfo', inputInfo)
			this.close()
		},
		close() {
			this.dialog = false
		}
	}
}
</script>
